import { supabase } from "@/providers";

export const fetchCredits = async () => {
  const { data, error } = await supabase
    .from("user_credits")
    .select(`*`)
    .maybeSingle();

  if (error) {
    const unifiedErrorMessage = `Error fetching credits. Reason: ${error.message}`;
    console.error(unifiedErrorMessage);
    throw new Error(unifiedErrorMessage);
  }

  return data;
};
