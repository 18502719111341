import React, { ReactElement } from "react";

type SectionProps = {
  children: ReactElement | ReactElement[];
  className?: string;
  customStyle?: React.CSSProperties;
  customClassName?: string;
};
export const Section: React.FC<SectionProps> = ({
  children,
  className = "",
  customStyle = {},
  customClassName = "",
}) => {
  return (
    <div
      style={{ marginBottom: 100, ...customStyle }}
      className={className + " " + customClassName}
    >
      {children}
    </div>
  );
};
