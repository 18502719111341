import {
  Button,
  Card,
  Chip,
  Select,
  SelectItem,
  Slider,
  Textarea,
} from "@nextui-org/react";
import { ChatCompletionMessageParam } from "openai/resources";
import { useEffect, useState } from "react";
import {
  LuCog,
  LuFileInput,
  LuPencilLine,
  LuTable,
  LuText,
} from "react-icons/lu";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useUserStore } from "@/entities/sp-user";
import { GeneratedCard } from "@/features/deck";
import { useGenerationStore } from "@/features/deck-generation/index.ts";
import { supabase } from "@/providers";
import { useCreditsQuery } from "@/shared/hooks/queries/useCreditsQuery";

import { getPromptModelResponse } from "../api/getPromptModelResponse";
import { useArticleTitleNormalizedListQuery } from "../hooks";
import { useArticleContentByIdQuery } from "../hooks/useArticleContentByIdQuery";

type ModelFlashcardListResponse = { flashcards: GeneratedCard[] };
export const GENERATION_CONFIG_MODEL = "gpt-3.5-turbo";

export const GenerationPanel: React.FC = () => {
  const [selectedView, setSelectedView] = useState<
    "attach" | "write" | "article" | "csv"
  >("article");

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSource, setSelectedSource] = useState<string | null>(null);
  const [howManyCards, setHowManyCards] = useState(5);

  const [isSystemPromptVisible, setIsSystemPromptVisible] =
    useState<boolean>(false);

  const userId = useUserStore((state) => state.session?.user.id);

  const {
    userPrompt,
    systemPrompt,
    setUserPrompt,
    setIsLoading: setLoading,
    setIsSaved,
    updateGeneratedCardList,
    isLoading: loading,
    userPromptOverride,
    setUserPromptOverride,
    generatedCardList,
  } = useGenerationStore();

  const { articleTitleList } = useArticleTitleNormalizedListQuery();
  const { articleContent } = useArticleContentByIdQuery(selectedSource);
  const {
    data: credits,
    isPending: isCreditsPending,
    refetch,
  } = useCreditsQuery();

  useEffect(() => {
    if (articleContent) setUserPrompt(articleContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleContent]);

  const GENERATION_CONFIG_PROMPTS: ChatCompletionMessageParam[] = [
    {
      role: "system",
      content: systemPrompt
        .replace("5", howManyCards.toString())
        .replace(
          "important and distinct points from the text.",
          "important and distinct points from the text." + userPromptOverride,
        ),
    },
    { role: "user", content: userPrompt.toString() },
  ];

  const handleCardGeneration = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !isCreditsPending &&
      credits &&
      credits?.monthly_credit_limit - credits?.used_credits === 0
    )
      return;

    setLoading(true);

    try {
      toast.loading("Generating flash cards...");
      const responseContentFromModel =
        await getPromptModelResponse<ModelFlashcardListResponse>({
          model: GENERATION_CONFIG_MODEL,
          messages: GENERATION_CONFIG_PROMPTS,
          response_format: { type: "json_object" },
        });
      if (responseContentFromModel) {
        updateGeneratedCardList(responseContentFromModel?.flashcards);
        toast.dismiss();
        toast.success("Successfully generated flash cards.");
      }
    } catch (e) {
      console.error(e);
      toast.error(`Something went wrong, Please try again.`);
    }

    await supabase
      .from("user_credits")
      .update({ used_credits: credits?.used_credits + 1 })
      .eq("user_id", userId);
    await refetch();
    console.log(credits, userId);
    setLoading(false);
    setIsSaved(false);
  };

  const handleCsvCardGeneration = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    setLoading(true);

    try {
      toast.loading("Generating flash cards...");
      const additionalDataHeaders = userPrompt
        .split("\n")[0]
        .split("\t")
        .slice(2);
      const responseContentFromModel = userPrompt
        .split("\n")
        .slice(1)
        .map((line) => {
          const [question, answer, ...rest] = line.split("\t");
          return {
            question,
            answer,
            additional_data: JSON.stringify(
              Object.fromEntries(
                additionalDataHeaders.map((header, index) => [
                  header,
                  rest[index],
                ]),
              ),
            ),
          };
        });
      if (responseContentFromModel) {
        updateGeneratedCardList(responseContentFromModel);
        toast.dismiss();
        toast.success("Successfully generated flash cards.");
      }
    } catch (e) {
      console.error(e);
      toast.error(`Something went wrong, Please try again.`);
    }

    setLoading(false);
    setIsSaved(false);
  };

  const toggleIsSystemPromptVisible = () => {
    setIsSystemPromptVisible((prev) => !prev);
  };

  useEffect(() => {
    searchParams.get("source");
    if (searchParams.get("source")) {
      setSelectedSource(searchParams.get("source"));
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={panelStyle}>
      <GenerationMethodViewToggler setSelectedView={setSelectedView} />

      {selectedView === "attach" && (
        <Card className="p-20">
          <div className=" flex gap-2 text-2xl">
            <LuFileInput /> Drop your file here
          </div>
        </Card>
      )}

      {selectedView === "article" && (
        <>
          <h2>Selected Article</h2>
          <Select
            aria-label="Select article"
            placeholder="Select your article to generate cards from"
            labelPlacement="outside"
            fullWidth
            onChange={async (e) => {
              setSelectedSource(e.target.value);
              await setSearchParams(`source=${e.target.value}`);
            }}
            renderValue={(value) => {
              return (
                <span aria-label={value[0].textValue} className="text-lg">
                  {value[0].textValue}
                </span>
              );
            }}
          >
            {(articleTitleList ? articleTitleList : []).map((item, index) => (
              <SelectItem
                aria-label={item.ai_title}
                key={item.id}
                value={item.id}
                textValue={item.ai_title ?? `Article ${index}`}
              >
                {item.ai_title}
              </SelectItem>
            ))}
          </Select>
          {userPrompt && (
            <>
              <h2>Article's Content</h2>
              <Textarea
                minRows={10}
                maxRows={25}
                style={textAreaStyle}
                value={userPrompt}
                placeholder="Input text to generate flash cards."
                onChange={(e) => setUserPrompt(e.target.value)}
              />

              <Button variant="flat" onClick={toggleIsSystemPromptVisible}>
                <LuCog />
                {isSystemPromptVisible
                  ? "Hide Custom Generation"
                  : "Customize Generation"}
              </Button>

              {isSystemPromptVisible && (
                <>
                  <Slider
                    label="How many cards to generate?"
                    size="md"
                    step={1}
                    maxValue={20}
                    minValue={1}
                    value={howManyCards}
                    onChange={(value) => setHowManyCards(value as number)}
                    getValue={(howManyCards) => `${howManyCards} cards`}
                  />
                  Sarasa AI Generation Instructions
                  {/* <Textarea
                    disabled={true}
                    value={systemPrompt.replace("5", howManyCards.toString())}
                  /> */}
                  <Card className="p-5" style={{ lineHeight: 2 }}>
                    <p>
                      Extract at least <Chip>{howManyCards}</Chip> important
                      points or key concepts from the provided text. For each
                      point include
                      <Chip>question</Chip> and <Chip>answer</Chip>. Ensure{" "}
                      <Chip>clarity</Chip> and <Chip>conciseness</Chip> in both
                      questions and answers. Include relevant details to make
                      the flashcards informative.
                    </p>
                  </Card>
                  Custom instructions
                  <Textarea
                    minRows={5}
                    maxRows={25}
                    value={userPromptOverride ?? ""}
                    placeholder="Input your custom instructions to include into Sarasa instructions."
                    onChange={(e) => setUserPromptOverride(e.target.value)}
                  />
                </>
              )}

              <form onSubmit={handleCardGeneration} style={formStyle}>
                <Button
                  isDisabled={
                    !isCreditsPending &&
                    credits &&
                    credits?.monthly_credit_limit - credits?.used_credits === 0
                  }
                  color="primary"
                  size="lg"
                  disabled={loading || userPrompt.length === 0}
                  type="submit"
                >
                  {loading
                    ? "Generating..."
                    : generatedCardList
                      ? "Generate cards"
                      : "Generate additional cards"}
                </Button>
              </form>
            </>
          )}
        </>
      )}

      {selectedView === "write" && (
        <>
          <h2>Type or Paste</h2>
          {
            <>
              <Textarea
                minRows={10}
                maxRows={25}
                style={textAreaStyle}
                value={userPrompt}
                placeholder="Input text to generate flash cards."
                onChange={(e) => setUserPrompt(e.target.value)}
              />

              <Button variant="flat" onClick={toggleIsSystemPromptVisible}>
                <LuCog />
                {isSystemPromptVisible
                  ? "Hide Custom Generation"
                  : "Customize Generation"}
              </Button>

              {isSystemPromptVisible && (
                <>
                  <Slider
                    label="How many cards to generate?"
                    size="md"
                    step={1}
                    maxValue={20}
                    minValue={1}
                    value={howManyCards}
                    onChange={(value) => setHowManyCards(value as number)}
                    getValue={(howManyCards) => `${howManyCards} cards`}
                  />
                  Sarasa AI Generation Instructions
                  <Card className="p-5" style={{ lineHeight: 2 }}>
                    <p>
                      Extract at least <Chip>{howManyCards}</Chip> important
                      points or key concepts from the provided text. For each
                      point include
                      <Chip>question</Chip> and <Chip>answer</Chip>. Ensure{" "}
                      <Chip>clarity</Chip> and <Chip>conciseness</Chip> in both
                      questions and answers. Include relevant details to make
                      the flashcards informative.
                    </p>
                  </Card>
                  Custom instructions
                  <Textarea
                    minRows={5}
                    maxRows={25}
                    value={userPromptOverride ?? ""}
                    placeholder="Input your custom instructions to include into Sarasa instructions."
                    onChange={(e) => setUserPromptOverride(e.target.value)}
                  />
                </>
              )}

              <form onSubmit={handleCardGeneration} style={formStyle}>
                <Button
                  color="primary"
                  size="lg"
                  disabled={loading || userPrompt.length === 0}
                  type="submit"
                >
                  {loading
                    ? "Generating..."
                    : generatedCardList
                      ? "Generate cards"
                      : "Generate additional cards"}
                </Button>
              </form>
            </>
          }
        </>
      )}

      {selectedView === "csv" && (
        <>
          <h2>Paste CSV</h2>
          {
            <>
              <Textarea
                minRows={10}
                maxRows={25}
                style={textAreaStyle}
                value={userPrompt}
                placeholder="Paste CSV flash cards."
                onChange={(e) => setUserPrompt(e.target.value)}
              />

              <form onSubmit={handleCsvCardGeneration} style={formStyle}>
                <Button
                  color="primary"
                  size="lg"
                  disabled={loading}
                  type="submit"
                >
                  {loading
                    ? "Generating..."
                    : generatedCardList
                      ? "Generate cards"
                      : "Generate additional cards"}
                </Button>
              </form>
            </>
          }
        </>
      )}
    </div>
  );
};

const formStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: 20,
};
const panelStyle: React.CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: 20,
  borderRadius: 20,
};

const textAreaStyle: React.CSSProperties = {
  borderRadius: 10,
  padding: 6,
  fontFamily: "sans-serif",
};

const GenerationMethodViewToggler: React.FC<{
  setSelectedView: React.Dispatch<
    React.SetStateAction<"attach" | "write" | "article" | "csv">
  >;
}> = ({ setSelectedView }) => {
  return (
    <div className="flex gap-2 flex-col md:flex-row">
      {/* <Button isDisabled onClick={() => setSelectedView("attach")}>
        <LuUpload /> Attach Text File
      </Button> */}
      <Button onClick={() => setSelectedView("write")}>
        <LuPencilLine /> Type or Paste
      </Button>
      <Button onClick={() => setSelectedView("article")}>
        <LuText /> Use an Article
      </Button>
      <Button onClick={() => setSelectedView("csv")}>
        <LuTable /> Csv
      </Button>
      {/* <Button isDisabled>
        <LuMusic /> Voice input
      </Button>
      <Button isDisabled>
        <LuClipboard /> Queue
      </Button> */}
    </div>
  );
};
