import { User } from "@supabase/supabase-js";

import { GeneratedCard } from "@/features/deck";
import { Collection, Note, Source } from "@/shared/types";

export const mapSourceAndCollectionRefToNote = ({
  generatedCardList,
  user,
  sourceId,
  collectionId,
}: {
  generatedCardList: GeneratedCard[];
  user: User;
  sourceId: Source["id"];
  collectionId: Collection["id"] | null;
}): Partial<Note>[] => {
  const mappedNote = generatedCardList.map((item: GeneratedCard) => ({
    created_by: user?.id,
    question: item.question,
    answer: item.answer,
    source_id: sourceId ?? null,
    collection_id: collectionId,
    additional_data: JSON.stringify(item.additional_data) ?? null,
  }));
  return mappedNote;
};
