import { supabase } from "@/providers";

import { Snippet } from "../types";

export const fetchSnippetListByQuestionIdList = async (
  questionIdList: Snippet["id"][],
): Promise<Snippet[]> => {
  const { data, error } = await supabase
    .from("snippets")
    .select(`*, cards:card_id(*), logs:card_id(review_logs(*))`)
    .in("id", questionIdList);

  if (error) {
    const unifiedErrorMessage = `Error fetching snippet by Id. Reason: ${error.message}`;
    console.error(unifiedErrorMessage);
    throw new Error(unifiedErrorMessage);
  }

  return data ?? [];
};
