import { useQuery } from "@tanstack/react-query";

import { fetchSnippetListByQuestionIdList } from "@/shared/api/fetchSnippetListByQuestionIdList";
import { QUERY_KEY } from "@/shared/constants";
import { Snippet } from "@/shared/types/coreDbTypes";

export const useSnippetListByQuestionIdListQuery = (
  questionIdList: Snippet["id"][],
) => {
  const {
    data: selectedSnippets,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useQuery({
    queryKey: [QUERY_KEY.selectedSnippetsPerQuestionsIndexList, questionIdList],
    queryFn: async () => await fetchSnippetListByQuestionIdList(questionIdList),
  });

  return {
    selectedSnippets,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
