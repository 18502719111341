import { supabase } from "@/providers";

import { Note } from "../types";

export const fetchNoteList = async (): Promise<Note[]> => {
  const { data: notes, error: notesError } = await supabase
    .from("notes")
    .select(
      `
              *,
              collections (title),
              sources (ai_title),
              cards:card_id(*),
              logs:card_id(review_logs(*))
            `,
    )
    .order("created_at", { ascending: false });

  if (notesError) {
    console.error("There has been problem loading notes. Reason: ", notesError);
  }

  return notes ?? [];
};
