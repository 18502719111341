import {
  getKeyValue,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/table";

import { ApiResponse } from "../GenerateCardsPage";

const columns = [
  {
    key: "question",
    label: "Question",
  },
  {
    key: "answer",
    label: "Answer",
  },
];

export const RenderAsCSV = ({ apiResponse }: { apiResponse: ApiResponse }) => {
  return (
    <div style={containerStyle}>
      {apiResponse && apiResponse.length > 0 && (
        <Table aria-label="CSV table">
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn key={column.key}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody items={apiResponse}>
            {(item) => (
              <TableRow key={item.question}>
                {(columnKey) => (
                  <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {/* <table style={{ width: "100%" }}>
        <thead>
          <tr style={trStyle}>
            <td>
              <strong>#</strong>
            </td>
            <td>
              <strong>Question</strong>
            </td>
            <td>
              <strong>Answer</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          {apiResponse.length > 0 ? (
            apiResponse.map((item) => (
              <tr>
                <td style={{ paddingBottom: 20 }} valign="top">
                  {item?.questionUuid}
                </td>
                <td style={{ paddingBottom: 20 }} valign="top">
                  {item?.question}
                </td>
                <td style={{ paddingBottom: 20 }} valign="top">
                  {item?.answer}
                </td>
              </tr>
            ))
          ) : (
            <>No cards yet...</>
          )}
        </tbody>
      </table> */}
    </div>
  );
};

const containerStyle: React.CSSProperties = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 10,
};
// const trStyle: React.CSSProperties = {
//   justifyContent: "space-between",
//   width: "100%",
// };
