import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateUserProfile } from "../../api";
import { Profile } from "../../types";

const MUTATION_KEY = "PROFILE_UPDATE";

export const useUserProfileUpdateMutation = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isError, isSuccess } = useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: ({ profile }: { profile: Partial<Profile> }) =>
      updateUserProfile(profile),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userProfile"] });
    },
  });
  return { mutate, isPending, isError, isSuccess };
};
