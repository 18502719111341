import { supabase } from "@/providers";
import { Source } from "@/shared/types/coreDbTypes";

export const getArticleList = async () => {
  const { data, error } = await supabase
    .from("sources")
    .select("*")
    .order("created_at", { ascending: false });

  if (error) {
    const unifiedErrorMessage =
      "Couldn't load sources. Reason: " + error.message;
    console.error(unifiedErrorMessage);
    throw new Error(unifiedErrorMessage);
  }
  return { data: data as Source[] | null, error };
};
