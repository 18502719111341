import { Button } from "@nextui-org/react";
import confetti from "canvas-confetti";
import { useEffect } from "react";

import { Container } from "@/shared/components/index.ts";

import { LearnPageStep } from "../LearnPage";

type CompletedPageProps = {
  setView: (view: LearnPageStep) => void;
};
export const CompletedPage: React.FC<CompletedPageProps> = ({ setView }) => {
  useEffect(() => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  }, []);

  return (
    <Container>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="flex flex-col gap-6" style={{ width: "100%" }}>
          <h1>You're done! 🎉</h1>
          <div>You have no more cards to review for now.</div>
          <Button
            onClick={() => {
              setView("schedule");
            }}
          >
            Return
          </Button>
        </div>
      </div>
    </Container>
  );
};
