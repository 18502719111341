import "./loadingWheel.css";

import { Button, Spinner } from "@nextui-org/react";

import { useDarkMode } from "../../../hooks/useDarkMode";

export const LoadingWheel = () => {
  return (
    <div
      style={{
        zIndex: 1000,
        position: "fixed",
        left: "calc(50% - 100px)",
        top: "calc(50% - 150px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 20,
        borderRadius: 10,
      }}
    >
      <div className="wheel" />
      <div>Working</div>
      <Button>Cancel</Button>
    </div>
  );
};

export const LoadingScreen = () => {
  const [isDarkMode] = useDarkMode();
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: !isDarkMode
          ? "rgba(255, 255, 255, 0.9)"
          : "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
        backdropFilter: "blur(10px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner />
      {/* <LoadingWheel /> */}
    </div>
  );
};

export const LoadingSpinner = () => {
  return (
    <div className="w-full flex justify-center">
      <Spinner />
    </div>
  );
};
