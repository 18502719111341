import { useQuery } from "@tanstack/react-query";

import { supabase } from "@/providers";
import { QUERY_KEY } from "@/shared/constants";

const getArticleContentById = async (articleId: string) => {
  const { data, error } = await supabase
    .from("sources")
    .select("content")
    .eq("id", articleId);

  if (error) {
    throw new Error(`Error fetching collections: ${error.message}`);
  }
  return { data: data ?? [] };
};

export const useArticleContentByIdQuery = (articleId: string | null) => {
  const { data, error } = useQuery({
    queryKey: [QUERY_KEY.articles, "content", articleId],
    queryFn: () => getArticleContentById(articleId!),
    enabled: articleId !== null,
  });
  return {
    articleContent:
      data &&
      data.data &&
      data.data.length &&
      JSON.parse(data.data[0]?.content)?.content,
    error,
  };

  //   setUserPrompt(
  //     data && data.length && JSON.parse(data[0]?.content)?.content,
  //   );
};
