import { Card, FSRS, Grade } from "ts-fsrs";

export const handleScheduleNextReview = async ({
  card,
  f,
  grade,
}: {
  card: Card;
  f: FSRS;
  grade: Grade;
}) => {
  const scheduler = f.repeat(card, new Date());
  return {
    newScheduledCard: scheduler[grade].card,
    newReviewLog: scheduler[grade].log,
  };
};
