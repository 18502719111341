import { useQuery } from "@tanstack/react-query";

import { fetchNoteById } from "../../api/fetchNoteById.tsx";
import { QUERY_KEY } from "../../constants";

export const useNoteByIdQuery = ({ id }: { id: number }) => {
  const {
    data: note,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useQuery({
    queryKey: [QUERY_KEY.notes, id],
    queryFn: () => fetchNoteById(id),
  });

  return {
    note,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
