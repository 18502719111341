export const cardStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: 5,
  padding: 20,
  border: "1px solid #f9f9f9",
  borderRadius: 10,
  backgroundColor: "#f9f9f9",
};

export const cardListStyle: React.CSSProperties = {
  padding: 20,
  flex: 5,
  display: "flex",
  flexDirection: "column",
  gap: 20,
  width: "100%",
};

export const constants = {
  COLORS: {
    primary: "blue",
  },
};
