import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createArticle } from "../../api";
import { QUERY_KEY } from "../../constants";
import { Source } from "../../types";

export const useArticleCreationMutation = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, isError, isSuccess, data } = useMutation({
    mutationKey: ["CREATE_ARTICLE"],
    mutationFn: (article: Partial<Source>) => createArticle(article),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.articles] });
    },
  });
  return { mutate, isPending, isError, isSuccess, data };
};
