import { supabase } from "@/providers";

export const getSignedUrl = async (userId: string, fileName: string) => {
  const path = `${userId}/${fileName}`;

  const { data, error } = await supabase.storage
    .from("card_images")
    .createSignedUrl(path, 60);

  if (error) {
    const unifiedErrorMessage = `Error signing url. Reason: ${error.message}`;
    console.error(unifiedErrorMessage);
    throw new Error(unifiedErrorMessage);
  }

  return data?.signedUrl ?? "";
};
