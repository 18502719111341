import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";

// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { CreateCardPage } from "@/pages/GenerateCardsPage/CreateCardPage";

export default function CreateCardModal() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <Button
        onPress={onOpen}
        style={{ height: "inherit", padding: "10px 30px" }}
      >
        + New Card
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <CreateCardPage />
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
