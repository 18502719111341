export const SUPPORTED_MODEL = {
  ["gpt-3.5-turbo"]: "gpt-3.5-turbo",
};
export type SarasaModel = keyof typeof SUPPORTED_MODEL;

export const guardModel = (model: SarasaModel) => {
  if (!isValidModel(model)) {
    throw new Error(`Model ${model} is not supported`);
  }
};

function isValidModel(model: string): model is SarasaModel {
  return Object.keys(SUPPORTED_MODEL).includes(model);
}
