import "react-toastify/dist/ReactToastify.css";

import { Button, Chip, NextUIProvider } from "@nextui-org/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useContext, useEffect } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import TagManager from "react-gtm-module";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { SupabaseProvider } from "@/providers";
import { ThemeProvider } from "@/providers/ThemeProvider";

import { AppRouter } from "./router";

export const App: React.FC = () => {
  const [cookies] = useCookies(["analytics"]);
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-5MPTW8L9",
    };
    if (cookies.analytics === true) {
      TagManager.initialize(tagManagerArgs);
    }
  }, [cookies]);

  const queryClient = new QueryClient();

  return (
    <ThemeProvider>
      <pre style={{ display: "fixed", fontSize: 8 }}></pre>
      <CookiesProvider>
        <ToastContainer />
        <QueryClientProvider client={queryClient}>
          <SupabaseProvider>
            <NextUIProvider>
              <CookieBanner />
              <MaintainanceBanner />

              <RouterProvider router={AppRouter} />
            </NextUIProvider>
          </SupabaseProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
};

// https://github.com/openai/openai-cookbook/blob/main/examples/How_to_call_functions_with_chat_models.ipynb

// https://dl.acm.org/doi/10.1145/3534678.3539081?cid=99660547150

// https://www.reddit.com/r/Anki/comments/10ajq3t/what_are_the_main_differences_between_sm2_and_fsrs/
// https://victoria.dev/blog/how-to-send-long-text-input-to-chatgpt-using-the-openai-api/
// https://victoria.dev/blog/optimizing-text-for-chatgpt-nlp-and-text-pre-processing-techniques/

import { LuX } from "react-icons/lu";

import { DarkModeContext } from "@/providers/ThemeProvider.tsx";
import { useMaintainanceBanner } from "@/shared/hooks/queries/useMaintainanceBanner";

const MaintainanceBanner: React.FC = () => {
  const { isBannerDismissed, dismissBanner } = useBannerDismissedCookie();
  const { isDarkMode } = useContext(DarkModeContext);
  const ban = useMaintainanceBanner();

  const backgroundColor = isDarkMode ? "bg-slate-500" : "bg-slate-100";
  const textColor = isDarkMode ? "text-white" : "text-black";
  if (
    isBannerDismissed ||
    ban.isPending ||
    !ban.data ||
    !ban.data.is_active ||
    new Date() < new Date(ban.data.start_date) ||
    new Date() > new Date(ban.data.end_date)
  )
    return null;
  return (
    <div
      className={`flex justify-between w-full ${backgroundColor} ${textColor} p-3 text-center items-center`}
    >
      {ban.data.badge_text ? <Chip>{ban.data.badge_text}</Chip> : null}
      <span className="ml-2">{ban.data.message}</span>
      {ban.data.is_dismissible ? (
        <Button isIconOnly size="sm" onClick={dismissBanner}>
          <LuX />
        </Button>
      ) : null}
    </div>
  );
};

import { useState } from "react";

import { CookieBanner } from "@/pages/LandingPage/CookieBanner";

const COOKIE_NAME = "bannerDismissed";
const COOKIE_EXPIRY_DAYS = 7; // Expire cookie after 7 days

// Helper to set a cookie
const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

// Helper to get a cookie
const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    if (cookie.startsWith(nameEQ)) {
      return cookie.substring(nameEQ.length);
    }
  }
  return null;
};

const useBannerDismissedCookie = () => {
  const [isBannerDismissed, setIsBannerDismissed] = useState(false);

  useEffect(() => {
    const dismissed = getCookie(COOKIE_NAME);
    setIsBannerDismissed(dismissed === "true");
  }, []);

  const dismissBanner = () => {
    setIsBannerDismissed(true);
    setCookie(COOKIE_NAME, "true", COOKIE_EXPIRY_DAYS);
  };

  return { isBannerDismissed, dismissBanner };
};
