import { Button, Link } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { VerticalDotsIcon } from "@/shared/components";
import { useSourceByIdQuery } from "@/shared/hooks";

import { useAdjustedSource } from "./hooks/useAdjustedSource";

export const ArticlePage = () => {
  const { id } = useParams();
  const [collectionId, setCollectionId] = useState<string | null>(null);

  const { sourceById } = useSourceByIdQuery(id ?? null);

  useEffect(() => {
    if (sourceById) {
      setCollectionId(
        (sourceById && sourceById?.collection_id?.toString()) ?? null,
      );
    }
  }, [sourceById]);

  const { collections } = useAdjustedSource({ collectionId });

  if (sourceById) {
    const { ai_title, ai_summary, created_at, content } = sourceById;

    return (
      <>
        <div className="flex flex-col gap-3 max-w-[600px] leading-8">
          <h2>Article Page</h2>
          <div className="flex justify-between">
            <h1>{ai_title}</h1>
            <Button variant="light">
              <VerticalDotsIcon />
            </Button>
          </div>
          <p>{created_at}</p>
          <p>{ai_summary}</p>
          {/* // TODO JV: Check if this makes sense */}
          <p>{JSON.parse(content as unknown as string).content.toString()}</p>
          {collections !== null && (
            <div className="flex flex-col gap-3">
              <p>Also in the collection</p>
              {collections?.data?.map((article) => {
                return (
                  <>
                    <Link key={article.id} href={`${article.id}`}>
                      - {article.ai_title}
                    </Link>
                    <p>{JSON.parse(article.content).content.toString()}</p>
                  </>
                );
              })}
            </div>
          )}
        </div>
      </>
    );
  }
};
