import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Card as FSRSCard } from "ts-fsrs";

import { updateCard } from "../../api";
import { QUERY_KEY } from "../../constants";

const MUTATION_KEY = "UPDATE_CARD_STATS";
export const useCardStatsUpdateMutation = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isError, isSuccess, data } = useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: ({ card, id }: { card: FSRSCard; id: number }) =>
      updateCard(card, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.notes] });
    },
  });

  return { mutate, data, isPending, isError, isSuccess };
};
