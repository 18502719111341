import {
  Accordion,
  AccordionItem,
  Chip,
  Link,
  Tooltip,
} from "@nextui-org/react";
import { useState } from "react";
import { LuHelpCircle } from "react-icons/lu";

import { GeneratedCardList } from "@/features/deck";
import { Container } from "@/shared/components/index.ts";

import { GenerationPanel } from "./components/GenerationPanel";
import { ResultManipulationPanel } from "./components/ResultManipulationPanel";
import { SaveCardsToCollection } from "./SaveCardsToCollection";

export type ApiResponse = GeneratedCardList;
export const GenerateCardsPage: React.FC = () => {
  return (
    <Container style={{ display: "flex" }}>
      <div className="flex flex-col gap-6" style={{ width: "100%" }}>
        <h1 className="flex justify-between">Generate</h1>
        <HowItWorks />

        <div className="flex flex-col gap-5">
          <Accordion
            selectionMode="multiple"
            defaultExpandedKeys={"all"}
            variant="splitted"
          >
            <AccordionItem
              classNames={
                {
                  // heading: "bg-primary px-4 rounded-xl stroke-2 stroke-primary",
                }
              }
              key="1"
              aria-label="Step 1"
              title={
                <div className="flex gap-2">
                  <Chip size="lg" color="primary">
                    Step 1
                  </Chip>
                  <span className="text-2xl">Provide material for cards</span>
                </div>
              }
            >
              <div className="my-5">
                <GenerationPanel />
              </div>
            </AccordionItem>
            <AccordionItem
              classNames={
                {
                  // heading: "bg-gray-500 px-4 rounded-xl",
                }
              }
              key="2"
              aria-label="Step 2"
              title={
                <div className="flex gap-2">
                  <Chip size="lg" color="primary">
                    Step 2
                  </Chip>
                  <span className="text-2xl">Review and customise cards</span>
                </div>
              }
            >
              <div className="my-5">
                <ResultManipulationPanel />
              </div>
            </AccordionItem>

            <AccordionItem
              classNames={
                {
                  // heading: "bg-gray-500 px-4 rounded-xl",
                }
              }
              key="3"
              aria-label="Step 3"
              title={
                <div className="flex gap-2">
                  <Chip size="lg" color="primary">
                    Step 3
                  </Chip>
                  <span className="text-2xl">Save cards to collection</span>
                </div>
              }
            >
              <div className="my-5">
                <SaveCardsToCollection />
              </div>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </Container>
  );
};

const HowItWorks: React.FC = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  return (
    <Tooltip
      color="foreground"
      isOpen={isTooltipOpen}
      content={
        <ToolTipContent
          setIsTooltipOpen={setIsTooltipOpen}
          isTooltipOpen={isTooltipOpen}
        />
      }
    >
      <Link
        size="sm"
        color="primary"
        className="gap-1 max-w-[120px]"
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        onPress={() => setIsTooltipOpen(!isTooltipOpen)}
      >
        <LuHelpCircle /> How it works?
      </Link>
    </Tooltip>
  );
};

type ToolTipContentProps = {
  setIsTooltipOpen: (isOpen: boolean) => void;
  isTooltipOpen: boolean;
};
const ToolTipContent: React.FC<ToolTipContentProps> = ({
  setIsTooltipOpen,
  isTooltipOpen,
}) => (
  <span onClick={() => setIsTooltipOpen(!isTooltipOpen)} className="p-5">
    You have two options how to provide initial text.
    <br />
    <br />
    You can either <code>select your article to generate cards from</code>{" "}
    through the dropdown or you can enter your text inside the textarea.
    <br />
    <br />
    After you are done with entering text, click on <code>Generate</code>.
    <br />
    <br />
    Select cards you want to permanently add to your deck or repeat the action
    by clicking on Generate again
  </span>
);
