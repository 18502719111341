import React from "react";

import { Footer } from "./Footer";
import { HelperPlugin } from "./HelperPlugin";
import { AppNavbar } from "./layout/AppNavbar/AppNavbar";

type AppLayoutProps = {
  children: React.ReactNode;
};
export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowX: "hidden",
        width: "calc(100vw - 15px)",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          backdropFilter: "blur(10px)",
        }}
      >
        <AppNavbar />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "calc(100vh - 130px)",
        }}
      >
        {children}
      </div>
      <Footer />
      <HelperPlugin />
    </div>
  );
};
