import { Subscription } from "@lemonsqueezy/lemonsqueezy.js";
import { useEffect, useState } from "react";

import { getUserSub } from "../services/lemonsqueezy";

export const useUserSubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState<
    Subscription["data"][] | null
  >(null);

  useEffect(() => {
    async function getUserSubscriptions() {
      const subs = await getUserSub();
      if (subs?.data?.data) {
        setSubscriptions(subs?.data?.data);
      }
    }

    getUserSubscriptions();
  }, []);

  return subscriptions;
};
