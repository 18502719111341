import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateArticle } from "../../api";
import { QUERY_KEY } from "../../constants";
import { Source } from "../../types";

const MUTATION_KEY = "UPDATE_ARTICLE";
export const useArticleUpdateMutation = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isError, isSuccess } = useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: ({
      article,
      articleToBeUpdatedId,
    }: {
      article: Partial<Source>;
      articleToBeUpdatedId: string;
    }) => updateArticle(article, articleToBeUpdatedId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.articles] });
    },
  });
  return { mutate, isPending, isError, isSuccess };
};
