import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createCollection } from "../../api";
import { QUERY_KEY } from "../../constants";
import { Collection } from "../../types";

const MUTATION_KEY = "COLLECTION_INSERT";

export const useCollectionCreationMutation = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, isError, isSuccess } = useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: (collection: Partial<Collection>) =>
      createCollection(collection),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.collections] });
    },
  });
  return { mutate, isPending, isError, isSuccess };
};
