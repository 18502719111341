import { Dispatch } from "react";

import { CollectionCard } from "@/shared/components";
import { Collection, Note, Snippet } from "@/shared/types";
import { getDueCards } from "@/shared/utils/getDueCards";

import { SessionStatesCounts } from "./components/CardItem";
import { LearnPageStep, SelectedKnowledgeFragment } from "./LearnPage";

type ScheduleWidgetProps = {
  cards: (Note | Snippet)[];
  collections: Collection[];
  setSelected: Dispatch<React.SetStateAction<SelectedKnowledgeFragment[]>>;
  setView: (view: LearnPageStep) => void;
};
export const ScheduleWidget: React.FC<ScheduleWidgetProps> = ({
  cards,
  collections,
  setSelected,
  setView,
}) => {
  const collectionIdList = collections?.map((item) => item.id);

  const cardsByCollectionList = collectionIdList?.map((collectionId) => ({
    name: collections?.filter((item) => item.id === collectionId)[0].title,
    collection: collections?.filter((item) => item.id === collectionId)[0],
    cards: cards
      ?.filter((item) => item.collection_id === collectionId)
      .map((e) => e),
  }));

  const notesWithoutCollection = cards?.filter(
    (item) => item.collection_id === null,
  );

  const otherCards = {
    name: "Uncategorized",
    cards: notesWithoutCollection?.map((e) => e),
    collection: null,
  };

  const collectionAndOtherCardsList = [
    ...(cardsByCollectionList ?? []),
    otherCards,
  ];

  return (
    <div className="flex flex-col gap-6" style={{ width: "100%" }}>
      <h1>Review</h1>
      <div
        style={{
          display: "grid",
          gap: 20,
          gridTemplateRows: "auto",
        }}
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10"
      >
        {collectionAndOtherCardsList?.map((group) => {
          const cards = group?.cards ?? [];
          const createdCards = cards.filter((c) => c.card_id === null);
          const newCards = [
            ...createdCards,
            ...cards.filter((c) => c.cards?.state === 0),
          ];
          const learningCards = cards.filter((c) => c.cards?.state === 1);
          const reviewCards = cards.filter((c) => c.cards?.state === 2);
          const relearningCards = cards.filter((c) => c.cards?.state === 3);

          const dueNewCards = getDueCards(newCards);
          const dueLearningCards = getDueCards(learningCards);
          const dueReviewCards = getDueCards(reviewCards);
          const dueRelearningCards = getDueCards(relearningCards);

          const countOfDueCards =
            (dueReviewCards &&
              dueLearningCards &&
              dueRelearningCards &&
              dueReviewCards?.length +
                dueNewCards?.length +
                dueLearningCards?.length +
                dueRelearningCards?.length) ||
            0;

          return (
            <CollectionCard
              key={group.name}
              title={group?.name ?? "Loading"}
              description={<div>{group?.collection?.description}</div>}
              cards={group?.cards}
              count={group?.cards?.length ?? 0}
              statuses={
                <SessionStatesCounts
                  permanent={true}
                  newCardsLeftInSession={dueNewCards}
                  learningCardsInSession={dueLearningCards}
                  reviewCardsInSession={dueReviewCards}
                  relearningCardsInSession={dueRelearningCards}
                />
              }
              due={countOfDueCards}
              setSelected={setSelected}
              setView={setView}
            />
          );
        })}
      </div>
    </div>
  );
};
