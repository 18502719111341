import { supabase } from "@/providers";
import { Collection } from "@/shared/types/coreDbTypes";

export const getCollectionList = async () => {
  const { data, error } = await supabase
    .from("collections")
    .select()
    .order("created_at", { ascending: false });

  if (error) {
    const unifiedErrorMessage =
      "Couldn't load collections. Reason: " + error.message;
    console.error(unifiedErrorMessage);
    throw new Error(unifiedErrorMessage);
  }

  return { data: data as Collection[] | null, error };
};

export const getCollectionById = async (id: number) => {
  const { data, error } = await supabase
    .from("collections")
    .select()
    .eq("id", id)
    .maybeSingle();

  console.log(data);

  if (error) {
    const unifiedErrorMessage =
      "Couldn't load collections. Reason: " + error.message;
    console.error(unifiedErrorMessage);
    throw new Error(unifiedErrorMessage);
  }

  return { data: data as Collection | null, error };
};

// Inspiration:
//     SELECT
//     collections.id,
//     collections.title,
//     collections.created_at,
//     users.email AS created_by_email
//     FROM
//     collections
//      INNER JOIN
//     auth.users ON collections.created_by = users.id;
