import { supabase } from "@/providers";

export const uploadImage = async ({
  table,
  filePath,
  avatarFile,
}: {
  table: string;
  filePath: string;
  avatarFile: File;
}) => {
  const { data, error } = await supabase.storage
    .from(table)
    .upload(filePath, avatarFile, {
      cacheControl: "3600",
      upsert: false,
    });
  return { data, error };
};
