import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { GeneratedCard, GeneratedCardList } from "@/features/deck";

export const SYSTEM_PROMPT = `
### Flashcard Generation Prompt
Create flashcards for a spaced repetition app by extracting key concepts from the provided text and formulating them into question-answer pairs.

### Instructions
1. Identify 5 important and distinct points from the text.
2. For each, create a flashcard with:
   - A clear question.
   - A concise, detailed answer.
3. Use this JSON format:
   {
     "flashcards": [
       { "question": "Example question?", "answer": "Example answer." },
       { "question": "Example question 2?", "answer": "Example answer 2." }
     ]
   }
4. Only return the JSON object.

### Guidelines
- Ensure educational, clear, and concise flashcards, tailored for a general audience.
- Use valid JSON format, with each flashcard covering a unique concept.

### Text to Process
`;

interface State {
  systemPrompt: string;
  userPromptOverride: string;
  userPrompt: string;
  generatedCardList: GeneratedCardList;
  isLoading: boolean;
  isSaved: boolean;
}

interface Actions {
  setSystemPrompt: (systemPrompt: string) => void;
  setUserPrompt: (userPrompt: string) => void;
  setUserPromptOverride: (userPrompt: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsSaved: (isSaved: boolean) => void;
  removeGeneratedCardByIndex: (index: number) => void;
  updateGeneratedCardList: (newPayload: GeneratedCardList) => void;
  updateGeneratedCardByIndex: (
    index: number,
    newPayload: GeneratedCard,
  ) => void;
  setInitialState: () => void;
}

interface DeckGeneratorStore extends State, Actions {}

const initialState: State = {
  systemPrompt: SYSTEM_PROMPT,
  userPrompt: "",
  userPromptOverride: "",
  generatedCardList: [],
  isLoading: false,
  isSaved: false,
};

export const useGenerationStore = create<DeckGeneratorStore>()(
  devtools(
    immer((set) => ({
      ...initialState,
      setInitialState: () =>
        set(() => ({
          systemPrompt: SYSTEM_PROMPT,
          userPrompt: "",
          userPromptOverride: "",
          generatedCardList: [],
          isLoading: false,
          isSaved: false,
        })),
      setSystemPrompt: (systemPrompt: string) =>
        set(() => ({ systemPrompt: systemPrompt })),
      setUserPrompt: (userPrompt: string) =>
        set(() => ({ userPrompt: userPrompt })),
      setUserPromptOverride: (userPrompt: string) =>
        set(() => ({ userPromptOverride: userPrompt })),
      updateGeneratedCardList: (newPayload: GeneratedCardList) =>
        set((state) => ({
          generatedCardList: [...state.generatedCardList, ...newPayload],
        })),
      setIsLoading: (isLoading: boolean) =>
        set(() => ({ isLoading: isLoading })),
      setIsSaved: (isSaved: boolean) => set(() => ({ isSaved: isSaved })),
      removeGeneratedCardByIndex: (index: number) => {
        set((state) => {
          state.generatedCardList.splice(index, 1);
        });
      },
      updateGeneratedCardByIndex: (index: number, newPayload: GeneratedCard) =>
        set((state) => ({
          generatedCardList: [
            ...state.generatedCardList.slice(0, index),
            newPayload,
            ...state.generatedCardList.slice(index + 1),
          ],
        })),
    })),
  ),
);
