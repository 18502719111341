import { Button, Image, Select, SelectItem, Textarea } from "@nextui-org/react";
import React, { useEffect } from "react";
import { LuFilePlus } from "react-icons/lu";
import { toast } from "react-toastify";

import { useCollections } from "@/entities/collections";
import { useUserStore } from "@/entities/sp-user";
import CreateCollectionModal from "@/features/CreateCollectionModal";
import { supabase } from "@/providers";
import { uploadImage } from "@/shared/api";
import { useSnippetCreationMutation } from "@/shared/hooks/mutations/useSnippetCreationMutation";

export const CreateSnippetWidget: React.FC = () => {
  const session = useUserStore((state) => state.session);

  const {
    mutate: mutateSnippetCreation,
    isSuccess,
    isError,
  } = useSnippetCreationMutation();

  const [selectedCollection, setSelectedCollection] = React.useState<
    number | undefined
  >(undefined);
  const [imageNameRef, setImageNameRef] = React.useState<string>("");
  const [contentValue, setContentValue] = React.useState<string>("");
  const [imageUrl, setImageUrl] = React.useState<string>("");

  const { collections } = useCollections();

  const handleSendDataToSupabase = async () => {
    if (!session) {
      throw new Error("Session not found");
    }

    const snippet = {
      content: contentValue,
      image_name_ref: imageNameRef,
      collection_id: selectedCollection,
    };

    await mutateSnippetCreation(snippet);
  };

  useEffect(() => {
    if (isSuccess) toast.success("Snippet has been created.");
  }, [isSuccess]);

  useEffect(() => {
    if (isError) toast.error("Action has failed.");
  }, [isError]);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const userId = session?.user.id;

    if (!event.target.files) return;
    const avatarFile = event.target.files[0];
    if (!avatarFile) return;

    const fileNameRef = `${avatarFile.name + Date.now()}`;
    const filePath = `${userId}/${fileNameRef}`;

    const { data, error } = await uploadImage({
      table: "card_images",
      filePath,
      avatarFile,
    });

    if (error) throw new Error(`Error uploading file: ${error.message}`);
    if (!data) throw new Error("No data returned from Supabase");

    setImageNameRef(fileNameRef);

    const { data: signedUrl } = await supabase.storage
      .from("card_images")
      .createSignedUrl(filePath, 60);

    setImageUrl(signedUrl?.signedUrl || "");

    toast.info("Image uploaded");
  };

  return (
    <div className="flex flex-col gap-6" style={{ width: "100%" }}>
      <Textarea
        label="Content"
        height={1000}
        minRows={3}
        style={textAreaStyle}
        value={contentValue}
        placeholder="Type your content here..."
        onChange={(e) => setContentValue(e.target.value)}
      />
      <label htmlFor="upload-picture">
        <Button
          variant="ghost"
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("upload-picture")?.click();
          }}
        >
          <LuFilePlus /> Upload picture
        </Button>
      </label>
      <input
        type="file"
        id="upload-picture"
        accept="image/*"
        onChange={handleImageUpload}
        style={{ display: "none" }}
      />

      {imageUrl && (
        <Image
          src={imageUrl}
          shadow="sm"
          alt="Uploaded image"
          width={500}
          height={500}
        />
      )}

      {collections && (
        <div className="flex gap-2">
          <Select
            fullWidth
            label="Select a collection or leave blank"
            onChange={(e) => setSelectedCollection(+e.target.value)}
          >
            {collections.map((item) => (
              <SelectItem key={item.id} value={item.id}>
                {item.title}
              </SelectItem>
            ))}
          </Select>
          <CreateCollectionModal />
        </div>
      )}
      <Button
        disabled={contentValue.length === 0}
        onClick={handleSendDataToSupabase}
      >
        Create
      </Button>
    </div>
  );
};

const textAreaStyle: React.CSSProperties = {
  borderRadius: 10,
  padding: 6,
  fontFamily: "sans-serif",
};
