import {
  Button,
  Card,
  Chip,
  cn,
  Divider,
  getKeyValue,
  Image,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Tooltip,
} from "@nextui-org/react";
import React, { useState } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { Grade, State } from "ts-fsrs";

import { useUserStore } from "@/entities/sp-user";
import { useImageUrl } from "@/features/image";
import { SarasaCodeSyntaxBlock } from "@/shared/components";
import { Note, ReviewLog, Snippet } from "@/shared/types";
import { formatDate } from "@/shared/utils/formatDate";
import { formatToInteger } from "@/shared/utils/formatToInteger";
import { isNote } from "@/shared/utils/typeGuards";

import { useLearningSessionTiming } from "../hooks/useLearningSessionTiming";
import { KnowledgeFragment } from "../LearnPage";
import { CardHistory } from "./CardHistory";
import { RevealAnswerOrRatingButtonsActionsBar } from "./RevealAnswerOrRatingButtonsActionsBar";

type CardItemProps = {
  allSessionItems: KnowledgeFragment[];
  cardsLeftInSession: KnowledgeFragment[];
  item: KnowledgeFragment;
  revlogs: ReviewLog[];
  handleReview: ({ grade }: { grade: Grade }) => void;
  isSubmitting: boolean;
};
export const CardItem: React.FC<CardItemProps> = ({
  item,
  handleReview,
  cardsLeftInSession,
  allSessionItems,
  revlogs,
  isSubmitting,
}) => {
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const { learningSessionEndTime } = useLearningSessionTiming();

  const session = useUserStore((state) => state.session);

  const { signedUrl } = useImageUrl({
    file: item?.image_name_ref ?? null,
    userId: session?.user.id ?? null,
  });

  const getCardsInSession = (cards: KnowledgeFragment[], state: State) => {
    return cards
      .filter((item) => item?.cards?.state === state)
      .filter((item) => new Date(item.cards.due) < learningSessionEndTime);
  };

  const newCardsLeftInSession = getCardsInSession(
    cardsLeftInSession,
    State.New,
  );
  const learningCardsInSession = getCardsInSession(
    cardsLeftInSession,
    State.Learning,
  );
  const reviewCardsInSession = getCardsInSession(
    cardsLeftInSession,
    State.Review,
  );
  const relearningCardsInSession = getCardsInSession(
    cardsLeftInSession,
    State.Relearning,
  );

  if (isNote(item))
    return (
      <>
        <div key={item.question} className="flex flex-col gap-8">
          <Card
            classNames={{
              body: "max-w-full",
              base: cn(
                "inline-flex w-full bg-content1",
                "hover:bg-content2 items-center justify-start",
                "rounded-lg gap-2 p-8 border-2 border-transparent",
              ),
            }}
          >
            {signedUrl && <Image src={signedUrl} alt="card" width={300} />}
            <p>{`${item.question}`}</p>

            <p>
              <i>{item.mnemonics ?? ""}</i>
            </p>
            {isFlipped ? (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Divider style={{ width: "100%", marginBottom: 20 }} />
                {item.answer}
              </div>
            ) : (
              ""
            )}
          </Card>

          <div className="fixed left-0 right-0 bottom-1 sm:relative flex flex-col gap-8 w-full">
            <div className="flex justify-center">
              <SessionStatesCounts
                newCardsLeftInSession={newCardsLeftInSession}
                learningCardsInSession={learningCardsInSession}
                reviewCardsInSession={reviewCardsInSession}
                relearningCardsInSession={relearningCardsInSession}
              />
            </div>

            <RevealAnswerOrRatingButtonsActionsBar
              isFlipped={isFlipped}
              handleReview={handleReview}
              setIsFlipped={setIsFlipped}
              isSubmitting={isSubmitting}
            />
          </div>
        </div>

        <CardStatistics
          statistics={<CardHistory card={item.cards} item={item} />}
          allSessionItems={allSessionItems}
          cardsLeftInSession={cardsLeftInSession}
          revlogs={revlogs}
        />
      </>
    );
};

type CardStatisticsProps = {
  statistics: React.ReactElement;
  allSessionItems: KnowledgeFragment[];
  cardsLeftInSession: KnowledgeFragment[] | [];
  revlogs: ReviewLog[];
};
const CardStatistics: React.FC<CardStatisticsProps> = ({
  statistics,
  allSessionItems,
  cardsLeftInSession,
  revlogs,
}) => {
  const [shouldDisplay, setShouldDisplay] = useState(false);
  if (!shouldDisplay) {
    return (
      <Button
        style={{ width: 20, opacity: 0.25 }}
        variant="light"
        onClick={() => setShouldDisplay(true)}
      >
        <LuEye />
      </Button>
    );
  }
  if (shouldDisplay)
    return (
      <div className="flex w-full flex-col">
        <Tabs aria-label="Options">
          <Tab key="statistics" title="Statistics for the card">
            {statistics}
          </Tab>
          <Tab key="revlog" title="Review log">
            <Table isStriped aria-label="Review logs">
              <TableHeader
                columns={[
                  // { label: "id", key: "id" },
                  { label: "Reviewed at", key: "created_at" },
                  // { label: "created_by", key: "created_by" },
                  { label: "Due at", key: "due" },
                  { label: "Difficulty", key: "difficulty" },
                  { label: "Lapses", key: "lapses" },
                  { label: "Stability", key: "stability" },
                  // { label: "note_id", key: "note_id" },
                  { label: "Reps", key: "reps" },
                  { label: "Elapsed days", key: "elapsed_days" },
                  { label: "Scheduled days", key: "scheduled_days" },
                  { label: "State", key: "state" },
                  // { label: "last_review", key: "last_review" },
                  // { label: "deleted", key: "deleted" },
                  { label: "Grade", key: "grade" },
                  // { label: "card_id", key: "card_id" },
                  { label: "Last elapsed days", key: "last_elapsed_days" },
                  { label: "Rating", key: "rating" },
                  // { label: "review", key: "review" },
                ]}
              >
                {(column) => (
                  <TableColumn key={column.key}>{column.label}</TableColumn>
                )}
              </TableHeader>
              <TableBody items={revlogs}>
                {(item) => (
                  <TableRow key={item.id}>
                    {(columnKey) => {
                      if (columnKey === "created_at" || columnKey === "due")
                        return (
                          <TableCell>
                            {formatDate(getKeyValue(item, columnKey))}
                          </TableCell>
                        );
                      if (
                        columnKey === "difficulty" ||
                        columnKey === "stability" ||
                        columnKey === "elapsed_days"
                      )
                        return (
                          <TableCell>
                            {formatToInteger(getKeyValue(item, columnKey))}
                          </TableCell>
                        );
                      return (
                        <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                      );
                    }}
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {/* <SarasaCodeSyntaxBlock>
              {JSON.stringify(revlogs, null, 2)}
            </SarasaCodeSyntaxBlock> */}
          </Tab>
          <Tab key="allsessionitems" title="Initially all notes in the session">
            <SarasaCodeSyntaxBlock>
              {JSON.stringify(
                allSessionItems.map((item, index) => {
                  if (isNote(item)) return index + 1 + " " + item.question;
                }),
                null,
                2,
              )}
            </SarasaCodeSyntaxBlock>
          </Tab>
          <Tab key="cardsleftinsession" title="Notes left in session">
            <SarasaCodeSyntaxBlock>
              {JSON.stringify(
                cardsLeftInSession.map((item, index) => {
                  if (isNote(item)) return index + 1 + " " + item.question;
                }),
                null,
                2,
              )}
            </SarasaCodeSyntaxBlock>
          </Tab>
          <Tab
            key={"hideTabs"}
            title={
              <a>
                <Button variant="light" onClick={() => setShouldDisplay(false)}>
                  <LuEyeOff />
                </Button>
              </a>
            }
          />
        </Tabs>
      </div>
    );
};

type SessionStatesCountsProps = {
  newCardsLeftInSession?: (Note | Snippet)[];
  learningCardsInSession?: (Note | Snippet)[];
  reviewCardsInSession?: (Note | Snippet)[];
  relearningCardsInSession?: (Note | Snippet)[];
  permanent?: boolean;
};
export const SessionStatesCounts: React.FC<SessionStatesCountsProps> = ({
  newCardsLeftInSession,
  learningCardsInSession,
  reviewCardsInSession,
  relearningCardsInSession,
  // permanent = false,
}) => {
  return (
    <div className="flex gap-1" style={{ cursor: "default" }}>
      <Tooltip content="New">
        {Number(newCardsLeftInSession?.length) ? (
          <Chip size="sm" color="primary">
            {Number(newCardsLeftInSession?.length)}
          </Chip>
        ) : (
          <Chip size="sm" color="default">
            {Number(0)}
          </Chip>
        )}
      </Tooltip>

      <Tooltip content="Learning">
        {Number(learningCardsInSession?.length) ? (
          <Chip size="sm" color="secondary">
            {Number(learningCardsInSession?.length)}
          </Chip>
        ) : (
          <Chip size="sm" color="default">
            {Number(0)}
          </Chip>
        )}
      </Tooltip>

      <Tooltip content="Reviewing">
        {Number(reviewCardsInSession?.length) +
          Number(relearningCardsInSession?.length) !==
        0 ? (
          <Chip size="sm" color="success">
            {Number(reviewCardsInSession?.length) +
              Number(relearningCardsInSession?.length)}
          </Chip>
        ) : (
          <Chip size="sm" color="default">
            {Number(0)}
          </Chip>
        )}
      </Tooltip>
    </div>
  );
};
