import { ChatCompletionMessageParam } from "openai/resources";

import { supabase } from "@/providers";
import {
  guardModel,
  SarasaModel,
  SUPPORTED_MODEL,
} from "@/shared/config/sarasaModels";

type getPromptModelResponseType = {
  model: SarasaModel;
  messages: ChatCompletionMessageParam[];
  response_format?: { type: "json_object" };
};
export const getPromptModelResponse = async <T>({
  model,
  messages,
  response_format,
}: getPromptModelResponseType): Promise<T | null> => {
  guardModel(model);
  try {
    if (model === SUPPORTED_MODEL["gpt-3.5-turbo"]) {
      const { data } = await supabase.functions.invoke("promptopenai", {
        body: { model, messages, response_format },
      });
      const { choices } = data;

      return choices && choices.length > 0
        ? JSON.parse(choices[0].message?.content)
        : null;
    } else {
      throw new Error(`Invalid model ${model}.`);
    }
  } catch (error) {
    console.error(error);
    throw new Error("Unexpected error getting prompt response.");
  }
};
