import { Button, Input, Select, SelectItem, Textarea } from "@nextui-org/react";
import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { useCollections } from "@/entities/collections";
import { useUserStore } from "@/entities/sp-user";
import CreateCollectionModal from "@/features/CreateCollectionModal";
import { Container } from "@/shared/components/index.ts";
import { useArticleCreationMutation } from "@/shared/hooks";

export const CreateArticlePage: React.FC = () => {
  const {
    mutate: mutateArticleCreation,
    isError,
    isSuccess,
  } = useArticleCreationMutation();

  const session = useUserStore((state) => state.session);
  const { collections } = useCollections();

  const [selectedCollection, setSelectedCollection] = React.useState<
    number | undefined
  >(undefined);
  const [title, setTitle] = React.useState<string>("");
  const [externalUrl, setExternalUrl] = React.useState<string>("");
  const [content, setContent] = React.useState<string>("");

  const handleSendDataToSupabase = async () => {
    if (!session) {
      throw new Error("Session not found");
    }

    const newArticle = {
      content: JSON.stringify({ content: content }),
      collection_id: selectedCollection,
      ai_title: title ?? content.split(" ").slice(0, 5).join(" "),
      ai_summary: title ?? content.split(" ").slice(0, 5).join(" "),
      external_url: externalUrl ?? undefined,
    };

    await mutateArticleCreation(newArticle);
  };

  useEffect(() => {
    if (isError) {
      toast.error("Error saving to deck");
      throw new Error(`Creating article has failed.`);
    }
    if (isSuccess) {
      toast.success("Created article.");
    }
  }, [isError, isSuccess]);

  return (
    <Container>
      <div className="flex flex-col gap-6" style={{ width: "100%" }}>
        <h1>Create your own article</h1>
        <Input label="Title" onChange={(e) => setTitle(e.target.value)} />
        <Textarea
          label="Content"
          minRows={10}
          maxRows={1000}
          style={textAreaStyle}
          value={content}
          placeholder="Write your article here..."
          onChange={(e) => setContent(e.target.value)}
        />
        <Input
          label="External URL (Optional)"
          onChange={(e) => setExternalUrl(e.target.value)}
        />
        {collections && (
          <div className="flex gap-2">
            <Select
              fullWidth
              label="Select a collection or leave blank"
              // className="max-w-xs"
              onChange={(e) => setSelectedCollection(+e.target.value)}
            >
              {collections.map((item) => (
                <SelectItem key={item.id} value={item.id}>
                  {item.title}
                </SelectItem>
              ))}
            </Select>
            <CreateCollectionModal />
          </div>
        )}
        <Button
          disabled={content.length === 0}
          onClick={handleSendDataToSupabase}
        >
          Create
          {/* {loading ? "Generating..." : "Generate"} */}
        </Button>
      </div>
    </Container>
  );
};

const textAreaStyle: React.CSSProperties = {
  borderRadius: 10,
  padding: 6,
  fontFamily: "sans-serif",
};
