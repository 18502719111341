import { Card } from "@nextui-org/react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useReviewLogs } from "@/entities/reviewLog/hooks/useReviewLogs";
import { ProgressCalendarMap } from "@/shared/components/ProgressCalendarMap";
import { cardListStyle } from "@/shared/styles";

export const BrowseProgressByDay = () => {
  const { reviewLogs } = useReviewLogs();

  const countLogsByDate = (logs: typeof reviewLogs) => {
    const dateCountMap = new Map();

    logs.forEach((log) => {
      const date = log.created_at.split("T")[0]; // Extract the date part
      dateCountMap.set(date, (dateCountMap.get(date) || 0) + 1); // Increment or initialize count
    });

    // Get today's date
    const today = new Date();

    // Calculate the start date (3 months ago)
    const startDate = new Date(today.getFullYear(), today.getMonth() - 3, 1);

    // Function to format date to 'YYYY-MM-DD'
    const formatDate = (date: Date) => date.toISOString().split("T")[0];

    // Generate all dates from startDate to today
    const allDates = [];
    const currentDate = startDate;

    while (currentDate <= today) {
      allDates.push(formatDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    // Map the allDates to the final output, filling missing dates with 0 reviews
    return allDates.map((date) => ({
      group_date: date,
      reviews: dateCountMap.get(date) || 0,
    }));
  };

  // Example usage
  const logCountByDate = countLogsByDate(reviewLogs ?? []);

  return (
    <>
      <div style={cardListStyle}>
        <h1>Overall Progress</h1>
        <Card className="flex justify-center p-10 mb-10 overflow-x-auto">
          <ProgressCalendarMap logs={reviewLogs} />
        </Card>

        <h1>Reviews History</h1>
        <Card className="flex justify-center p-10 overflow-x-auto">
          {/* <pre>{JSON.stringify(normalizedLogs ?? [], null, 2)}</pre> */}
          <AreaChart
            width={1000}
            height={300}
            data={logCountByDate}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Area type="monotone" dataKey="reviews" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="group_date" />
            <YAxis />
            <Tooltip />
          </AreaChart>
        </Card>
      </div>
    </>
  );
};
