import {
  Button,
  Card as NCard,
  CardFooter,
  CardHeader,
  Divider,
  Image as NImage,
  Tooltip,
} from "@nextui-org/react";
import { ReactElement } from "react";

// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { LearnPageStep, SelectedKnowledgeFragment } from "@/pages/LearnPage";

import { Note, Snippet } from "../../types";
import { getDueCards } from "../../utils/getDueCards";
import { isNote } from "../../utils/typeGuards";

type CollectionCardProps = {
  title: string;
  description?: string | ReactElement;
  count: number;
  setSelected: React.Dispatch<
    React.SetStateAction<SelectedKnowledgeFragment[]>
  >;
  setView: (view: LearnPageStep) => void;
  cards: (Note | Snippet)[];
  due: number | null;
  statuses: React.ReactElement;
};
export const CollectionCard: React.FC<CollectionCardProps> = ({
  title = "No title provided.",
  description = "No description provided.",
  count = 0,
  setSelected,
  setView,
  cards,
  due,
  statuses,
}) => {
  return (
    <NCard>
      <CardHeader className="flex gap-3">
        <Tooltip
          content={description}
          size="lg"
          classNames={{ base: "max-w-96" }}
        >
          <NImage
            style={{ minWidth: 40 }}
            alt="nextui logo"
            height={40}
            radius="sm"
            src={`https://ui-avatars.com/api/?background=038cfc&color=fff&name=${title}`}
            width={40}
          />
        </Tooltip>

        <div
          className="flex justify-between w-max "
          style={{ width: "100%", alignItems: "center" }}
        >
          <span>
            <p className="text-md">
              {title.length > 25 ? title.slice(0, 22) + "..." : title}
            </p>
            <p className="text-small text-default-500">Total {count} cards</p>
          </span>
          {statuses}
        </div>
      </CardHeader>
      <Divider />

      <CardFooter>
        {due ? (
          <Button
            variant="flat"
            color="primary"
            style={{ width: "100%" }}
            onClick={() => {
              const dueCards = getDueCards(cards);
              setSelected(
                dueCards.map((card: Note | Snippet) => {
                  if (isNote(card)) {
                    return {
                      id: card.id,
                      type: "note",
                    };
                  } else {
                    return {
                      id: card.id,
                      type: "snippet",
                    };
                  }
                }),
              );
              setView("study");
            }}
          >
            Start
          </Button>
        ) : (
          <Button variant="light" disabled fullWidth>
            No scheduled reviews
          </Button>
        )}
      </CardFooter>
    </NCard>
  );
};
