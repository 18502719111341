import { Button, Select, SelectItem, Textarea } from "@nextui-org/react";
import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { useCollections } from "@/entities/collections";
import { useUserStore } from "@/entities/sp-user";
import { Container } from "@/shared/components";
import { useArticleUpdateMutation, useSourceByIdQuery } from "@/shared/hooks";

type EditArticleWidgetProps = {
  id: string;
};
export const EditArticleWidget: React.FC<EditArticleWidgetProps> = ({ id }) => {
  const session = useUserStore((state) => state.session);

  const {
    mutate: mutateArticleUpdate,
    isSuccess,
    isError,
  } = useArticleUpdateMutation();

  const { sourceById: sourceToEdit } = useSourceByIdQuery(String(id));
  const { collections: collectionList } = useCollections();

  const [aiTitle, setAiTitle] = React.useState<string>("");
  const [aiSummary, setAiSummary] = React.useState<string>("");
  const [content, setContent] = React.useState<string>("");
  const [collection, setCollection] = React.useState<number | undefined>(
    undefined,
  );

  const handleSendDataToSupabase = async () => {
    if (!session) {
      throw new Error("Session not found");
    } else if (!sourceToEdit) {
      throw new Error("No data to update");
    }

    const updatedArticle = {
      ai_summary: aiSummary,
      ai_title: aiTitle,
      content: JSON.stringify({ content: content }),
      collection_id: collection,
    };

    mutateArticleUpdate({
      article: updatedArticle,
      articleToBeUpdatedId: sourceToEdit.id,
    });

    toast.success("Card has been updated successfully.");
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Article updated.");
    }
    if (isError) {
      toast.error("Action has failed.");
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    setAiTitle(sourceToEdit?.ai_title ?? "");
    setAiSummary(sourceToEdit?.ai_summary ?? "");
    setContent(
      sourceToEdit?.content ? JSON.parse(sourceToEdit?.content).content : "",
    );
    setCollection(sourceToEdit?.collection_id ?? undefined);
  }, [sourceToEdit]);

  if (sourceToEdit)
    return (
      <Container>
        <div className="flex flex-col gap-6" style={{ width: "100%" }}>
          <h1>Edit article</h1>
          <Textarea
            height={1000}
            minRows={1}
            style={textAreaStyle}
            value={aiTitle}
            label="Title"
            placeholder="Type your title here..."
            onChange={(e) => setAiTitle(e.target.value)}
          />
          <Textarea
            label="Summary"
            height={1000}
            minRows={3}
            style={textAreaStyle}
            value={aiSummary}
            placeholder="Type your summary here..."
            onChange={(e) => setAiSummary(e.target.value)}
          />

          <Textarea
            height={1000}
            minRows={6}
            style={textAreaStyle}
            value={content}
            label="Content"
            placeholder="Type your content here..."
            onChange={(e) => setContent(e.target.value)}
          />
          {collectionList && (
            <div className="flex gap-2">
              <Select
                fullWidth
                label="Select a collection or leave blank"
                placeholder={
                  collectionList
                    ? collectionList.filter((col) => col.id === collection)[0]
                        ?.title ?? undefined
                    : undefined
                }
                onChange={(e) => setCollection(+e.target.value)}
              >
                {collectionList.map((item) => (
                  <SelectItem key={item.id} value={item.id}>
                    {item.title}
                  </SelectItem>
                ))}
              </Select>
            </div>
          )}
          <Button
            disabled={aiSummary.length === 0 || content.length === 0}
            onClick={handleSendDataToSupabase}
          >
            Update
          </Button>
        </div>
      </Container>
    );
};

const textAreaStyle: React.CSSProperties = {
  borderRadius: 10,
  padding: 6,
  fontFamily: "sans-serif",
};
