import { ApiResponse } from "..";

export const RenderAsJSON = ({ apiResponse }: { apiResponse: ApiResponse }) => {
  return (
    <div style={sectionStyle}>
      <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
    </div>
  );
};
const sectionStyle: React.CSSProperties = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 10,
};
