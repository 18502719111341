import { useEffect, useState } from "react";
import { createEmptyCard } from "ts-fsrs";

import { KnowledgeFragment } from "../LearnPage";

export const useSessionCards = (selectedNotes: KnowledgeFragment[]) => {
  const [isSessionCardsInitial, setIsSessionCardsInitial] =
    useState<boolean>(true);

  // This is the index of the current card being reviewed based on a ad-hoc generated array of cards
  // TODO JV: Refactor this to be a proper card index by using the card ID
  const [currentCardIndex, setCurrentCardIndex] = useState<number>(0);
  const [leftSessionCards, setLeftSessionCards] = useState<KnowledgeFragment[]>(
    [],
  );

  useEffect(() => {
    if (!selectedNotes) return;

    const notesWithCardStats = selectedNotes.map((item) => ({
      ...item,
      cards: item.cards ?? createEmptyCard(),
    }));

    setLeftSessionCards(notesWithCardStats);
    if (isSessionCardsInitial) {
      setIsSessionCardsInitial(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNotes]);

  return {
    leftSessionCards,
    currentCardIndex,
    setLeftSessionCards,
    setCurrentCardIndex,
    isSessionCardsInitial,
  };
};
