// https://news.ycombinator.com/item?id=39002138
// https://en.wikipedia.org/wiki/Forgetting_curve
// https://github.com/open-spaced-repetition/fsrs-benchmark

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useCollections } from "@/entities/collections/index.ts";
import { useNoteListQuery } from "@/entities/notes";
import { Container, LoadingScreen } from "@/shared/components/index.ts";
import { useSnippetsListQuery } from "@/shared/hooks/queries/useSnippetsListQuery";
import { Note, Snippet } from "@/shared/types";

import { LearningSession } from "./components/LearningSession";
import { ScheduleWidget } from "./ScheduleWidget";
export type LearnPageStep = "schedule" | "study";

export type KnowledgeFragment = Note | Snippet;
type SelectedKnowledgeFragmentId = Note["id"] | Snippet["id"];
type SelectedKnowledgeFragmentType = "note" | "snippet";
export type SelectedKnowledgeFragment = {
  id: SelectedKnowledgeFragmentId;
  type: SelectedKnowledgeFragmentType;
};

export const LearnPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get("view");

  const [selected, setSelected] = useState<SelectedKnowledgeFragment[]>([]);

  const { collections, isPending: isCollectionsPending } = useCollections();
  const { notes, isPending: isCardsPending } = useNoteListQuery();
  const { snippets, isPending: isSnippetsPending } = useSnippetsListQuery();

  const cards: (Note | Snippet)[] = [...(snippets || []), ...(notes || [])];

  const setView = (view: LearnPageStep) => {
    setSearchParams({ view: view });
  };

  useEffect(() => {
    if (!view) setSearchParams({ view: "schedule" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isCollectionsPending || isCardsPending || isSnippetsPending) {
    return <LoadingScreen />;
  }

  return (
    <Container>
      {view === "schedule" && collections && cards && (
        <ScheduleWidget
          setSelected={setSelected}
          collections={collections}
          cards={cards}
          setView={setView}
        />
      )}
      {view === "study" && (
        <LearningSession
          knowledgeTypedReferenceList={selected}
          setView={setView}
        />
      )}
    </Container>
  );
};
