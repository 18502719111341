import Syntax from "react-syntax-highlighter/dist/esm/default-highlight";

export const SarasaCodeSyntaxBlock = ({
  children,
}: {
  children: string | string[];
}) => {
  return (
    <Syntax
      customStyle={{
        padding: 20,
        borderRadius: 10,
        backgroundColor: "lightgray",
      }}
    >
      {children}
    </Syntax>
  );
};
