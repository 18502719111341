import { supabase } from "@/providers";

import { Snippet } from "../types";

export const createSnippet = async (snippet: Partial<Snippet>) => {
  const { data, error } = await supabase
    .from("snippets")
    .insert(snippet)
    .select();
  if (error) {
    throw error;
  }
  return { data, error };
};
