import { supabase } from "@/providers";

export const fetchReviewLogList = async () => {
  const { data, error } = await supabase
    .from("review_logs")
    .select(`*`)
    .order("created_at", { ascending: false });

  if (error) {
    console.error("Error fetching review logs", error);
  }

  return { data, error };
};
