import { supabase } from "@/providers";

import { Source } from "../types";

export const updateArticle = async (
  article: Partial<Source>,
  articleToBeUpdatedId: string,
) => {
  const { data, error } = await supabase
    .from("sources")
    .update(article)
    .eq("id", articleToBeUpdatedId)
    .select()
    .maybeSingle();
  return { data, error };
};
