import { Button, Divider, Input } from "@nextui-org/react";
import { RefObject } from "react";
import { useNavigate } from "react-router-dom";

import { PasswordFieldWithVisibilityToggler } from "./PasswordFieldWithVisibilityToggler";
import { SocialProvidersRow } from "./SocialProvidersRow";

export function RegisterForm({
  inputEmailFieldRef,
  inputPasswordFieldRef,
  inputRepeatPasswordFieldRef,
  handleRegister,
  isSubmitting,
}: {
  inputEmailFieldRef: RefObject<HTMLInputElement>;
  inputPasswordFieldRef: RefObject<HTMLInputElement>;
  inputRepeatPasswordFieldRef: RefObject<HTMLInputElement>;
  handleRegister: () => void;
  isSubmitting: boolean;
}) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
    >
      <Input type="text" placeholder="Your Email" ref={inputEmailFieldRef} />

      <PasswordFieldWithVisibilityToggler
        fieldRef={inputPasswordFieldRef}
        placeholder="Your Password"
      />
      <PasswordFieldWithVisibilityToggler
        fieldRef={inputRepeatPasswordFieldRef}
        placeholder="Repeat Your Password"
      />

      <Button
        color="primary"
        onClick={handleRegister}
        isLoading={isSubmitting}
        isDisabled={isSubmitting}
      >
        Register
      </Button>
      <Divider />
      <SocialProvidersRow />

      <p style={{ textAlign: "center" }}>Do you want to login instead?</p>
      <Button
        variant="light"
        color="primary"
        onClick={() => navigate("/login")}
        style={{}}
      >
        Login
      </Button>
    </div>
  );
}
