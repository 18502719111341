// https://medium.com/@adewaleadegoke0210/how-to-integrate-lemon-squeezy-with-a-mern-stack-application-04e82de88f38

import { getAuthenticatedUser, User } from "@lemonsqueezy/lemonsqueezy.js";
import { Button, Card, Divider } from "@nextui-org/react";
import { useEffect, useState } from "react";

import { Container, LoadingScreen } from "@/shared/components/index.ts";
import { useUserSubscriptions } from "@/shared/hooks/index.ts";
import {
  initializeLemonSqueezy,
  Plan,
  syncPlans,
} from "@/shared/services/lemonsqueezy";

import { usePlansQuery } from "./hooks/index.ts";

const useUserBilling = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [variants, setVariants] = useState<Plan[]>([]);
  const [data, setData] = useState<User | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const subscription = useUserSubscriptions();

  const bootstrapBilling = async () => {
    setIsLoading(true);
    initializeLemonSqueezy();

    const { data, error } = await getAuthenticatedUser();
    if (error) {
      setError(error);
    } else {
      setData(data);
    }

    const variants = await syncPlans();
    setVariants(variants);
    setIsLoading(false);
  };

  useEffect(() => {
    bootstrapBilling();
  }, []);

  return { isLoading, variants, data, error, subscription };
};

export const PricingPage = () => {
  const subscription = useUserSubscriptions();
  const { isLoading, error } = useUserBilling();

  const { variants } = usePlansQuery({
    subscription: subscription ?? [],
  });

  const hobbyPlanVariantId = "460860";
  // const premiumPlanVariantId = "461590";

  const rootUrl = "sarasaapp.lemonsqueezy.com";
  const hobbyProductId = "e96e84d1-dee4-4568-b9fd-8c78603f53a3";
  const premiumProductId = "6cd766ed-7e16-4bf9-b42d-748b839d603c";

  const handleBuy = (planId?: string) => {
    const productUrl = planId
      ? `https://${rootUrl}/buy/${planId === hobbyPlanVariantId ? hobbyProductId : premiumProductId}?enabled=${planId}`
      : `https://${rootUrl}/buy/${premiumProductId}`;
    window.location.href = productUrl;
  };
  const isFree = subscription && !subscription.length;

  return (
    <Container>
      <h1>Pricing</h1>
      <Card className="text-xs p-5 my-5">
        To do:
        <ul>
          {[
            "[ ] Implement a way to listen to webhooks to sync subscription data",
          ].map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      </Card>
      {isLoading && <LoadingScreen />}
      {error && <p>Error: {error.message}</p>}
      {!isLoading && !error && variants && (
        <>
          <div className="flex flex-col gap-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
              <PricingCard
                isActive={Boolean(isFree)}
                title="Free plan"
                description="For casual users who want to learn at their own pace."
                buttonText={isFree ? "Current plan" : "Downgrade"}
                disabled={Boolean(isFree)}
                onClick={handleBuy}
                price={"Free"}
              />

              {variants.map((variant) => {
                const isActive =
                  subscription &&
                  subscription.length &&
                  subscription[0].attributes.variant_id.toString() ===
                    variant.variantId.toString();

                return (
                  <PricingCard
                    isActive={Boolean(isActive)}
                    key={variant.id}
                    title={variant.name}
                    description={
                      variant.name === "Free plan"
                        ? "For users who want to try out the platform."
                        : variant.name === "Premium plan"
                          ? "For premium users who want to learn everything."
                          : "For casual users who want to learn at their own pace."
                    }
                    buttonText={isActive ? "Manage" : "Upgrade"}
                    onClick={() => {
                      handleBuy(variant.id.toString());
                    }}
                    price={variant.price}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
      {/* <Card className="p-5  text-xs bg-green-100">
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </Card>
      <Card className="p-5 my-5 bg-red-100 text-xs">
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Card> */}
    </Container>
  );
};

type PricingCardProps = {
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
  disabled?: boolean;
  price: string;
  isActive?: boolean;
};
const PricingCard: React.FC<PricingCardProps> = ({
  title,
  description,
  buttonText,
  onClick,
  disabled,
  price,
  isActive,
}) => {
  return (
    <Card
      style={{ width: "100%", padding: "20px" }}
      className="flex flex-col gap-5"
    >
      <h1>{title}</h1>
      <p>{description}</p>
      <Divider />
      {price !== "Free" ? (
        <p>
          <strong className="text-lg">
            {(parseInt(price) / 100).toFixed(0)}Kč
          </strong>{" "}
          / per month
        </p>
      ) : (
        "Free"
      )}
      <Button
        disabled={disabled}
        onClick={onClick}
        size="lg"
        color={isActive ? "primary" : "default"}
      >
        {buttonText}
      </Button>
    </Card>
  );
};
