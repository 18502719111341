import "react-tooltip/dist/react-tooltip.css";

import { Tooltip } from "react-tooltip";

import { ReviewLog } from "../types";

type EnrichedLogs = {
  date: Date;
  day: number;
  month: number;
  year: number;
  dayOfWeek: number;
};
// TODO JV: Changes logs type, check if works
export const ProgressCalendarMap: React.FC<{ logs: ReviewLog[] }> = ({
  logs,
}) => {
  const enrichedLogs =
    logs.map((item) => ({
      date: new Date(item.created_at),
      day: new Date(item.created_at).getDate(),
      month: new Date(item.created_at).getMonth(),
      year: new Date(item.created_at).getFullYear(),
      dayOfWeek: new Date(item.created_at).getDay(),
    })) ?? [];
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
      {/* <h1 style={{ margin: 0 }}>Knowledge Progress</h1> */}
      <YearCalendar logs={enrichedLogs} />
      <Tooltip id="calendar-tooltip" />
    </div>
  );
};

const YearCalendar: React.FC<{ logs: EnrichedLogs[] }> = ({ logs }) => {
  return (
    <div style={{ display: "flex", gap: 5 }}>
      {weeks.map((_item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
          }}
        >
          {[0, 1, 2, 3, 4, 5, 6].map((item) => {
            const day =
              enrichedDaysArray[
                index === 0 ? item + 1 : index * 7 + item + 1
              ] ?? null;
            return <DaySquare day={day} logs={logs} />;
          })}
        </div>
      ))}
    </div>
  );
};

type Day = {
  date: Date;
  day: number;
  month: number;
  year: number;
  dayOfWeek: number;
};

const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
const lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);

const daysArray = Array.from(
  {
    length:
      (lastDayOfYear.getTime() - firstDayOfYear.getTime()) /
        (1000 * 60 * 60 * 24) +
      2,
  },
  (_, index) =>
    new Date(firstDayOfYear.getTime() + (index - 1) * (1000 * 60 * 60 * 24)),
);
const enrichedDaysArray = daysArray.map((d) => ({
  date: d,
  day: d.getDate(),
  month: d.getMonth(),
  year: d.getFullYear(),
  dayOfWeek: d.getDay(),
}));

if (enrichedDaysArray[0].dayOfWeek !== 0) {
  const firstDayOfWeek = enrichedDaysArray[0].dayOfWeek;
  const daysToFill = Array.from({ length: firstDayOfWeek }).map((_, index) => ({
    date: new Date(
      enrichedDaysArray[0].year,
      enrichedDaysArray[0].month,
      enrichedDaysArray[0].day - firstDayOfWeek + index,
    ),
    day: enrichedDaysArray[0].day - firstDayOfWeek + index,
    month: enrichedDaysArray[0].month,
    year: enrichedDaysArray[0].year,
    dayOfWeek: index,
  }));
  enrichedDaysArray.unshift(...daysToFill);
}
const weeks: undefined[] = Array.from({ length: 53 });

type DaySquareProps = {
  day: Day | null;
  logs: EnrichedLogs[];
};
const DaySquare: React.FC<DaySquareProps> = ({ day, logs }) => {
  const label = day && day?.date.toString().substring(0, 11);

  if (!day) return <div style={{ width: 10, aspectRatio: 1 }} />;

  const logsForDay = logs.filter(
    (item) => item.day === day.day && item.month === day.month,
  );
  const opacity = 0.1 + 0.2 * logsForDay.length;
  return (
    <div key={day.date.toString()}>
      <div
        data-tooltip-id="calendar-tooltip"
        data-tooltip-content={
          label +
          (logsForDay.length ? " (" + logsForDay.length + " reviews)" : "")
        }
        style={{
          width: 15,
          aspectRatio: 1,
          border: `1px solid rgba(0,0,0,${opacity})`,
          backgroundColor: `rgba(175,175,175,${opacity})`,
          borderRadius: 3,
        }}
      />
    </div>
  );
};
