import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateCollection } from "../../api";
import { QUERY_KEY } from "../../constants";
import { Collection } from "../../types";

const MUTATION_KEY = "COLLECTION_UPDATE";
export const useCollectionUpdateMutation = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, isError, isSuccess } = useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: ({
      collection,
      id,
    }: {
      collection: Partial<Collection>;
      id: number;
    }) => updateCollection(collection, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.collections] });
    },
  });
  return { mutate, isPending, isError, isSuccess };
};
