import { AppMenuItem } from "./appMenuItems";

type NavigationItemProps = {
  navigate: (path: string) => void;
  item: AppMenuItem;
};
export const NavigationItem: React.FC<NavigationItemProps> = ({
  navigate,
  item,
}) => {
  return (
    <div
      key={item.path}
      style={{
        whiteSpace: "nowrap",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: 4,
      }}
      onClick={() => {
        navigate(item.path);
      }}
    >
      <span>{item.icon}</span> {item.label}
    </div>
  );
};
