import { useQuery } from "@tanstack/react-query";

import { QUERY_KEY } from "@/shared/constants";

import { getSignedUrl } from "../api/getSignedUrl";

export const useImageUrl = ({
  file,
  userId,
}: {
  file: string | null;
  userId: string | null;
}) => {
  const { data: signedUrl } = useQuery({
    enabled: file !== null && userId !== null,
    queryKey: [QUERY_KEY.image, file],
    queryFn: async () => await getSignedUrl(userId!, file!),
  });
  return { signedUrl };
};
