import { Button, Link } from "@nextui-org/react";

export const landingPageMenuItems = [
  // {
  //   label: "Features",
  //   path: "/",
  //   icon: "",
  // },

  // {
  //   label: "Use cases",
  //   path: "/usecases",
  //   icon: "",
  // },
  // {
  //   label: "Pricing",
  //   path: "/pricing",
  //   icon: "",
  // },
  // {
  //   label: "Contact",
  //   path: "/contact",
  //   icon: "",
  // },
  {
    label: "Login",
    path: "/login",
    icon: "",
  },
  {
    label: (
      <Link href="/wait-list">
        <Button color="primary">Try Sarasa Out</Button>
      </Link>
    ),
    path: "/wait-list",
    icon: "",
  },
];
