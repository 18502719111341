import { useQuery } from "@tanstack/react-query";

import { getUserProfileById } from "../api";

export const useUserProfileByIdQuery = (userId: string | null) => {
  const { data, error } = useQuery({
    queryKey: ["userProfile", userId],
    queryFn: () => getUserProfileById(userId!),
    enabled: userId !== null,
  });

  return {
    data: data?.data,
    error,
  };
};
