import { supabase } from "@/providers";

import { Profile } from "../types";

export const updateUserProfile = async (profile: Partial<Profile>) => {
  // TODO JV: Check if has correct authorization
  const { data, error } = await supabase
    .from("profiles")
    .upsert(profile)
    .select();
  return { data, error };
};
