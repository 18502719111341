import { useQuery } from "@tanstack/react-query";

import { supabase } from "@/providers";

import { QUERY_KEY } from "../../constants";
import { Source } from "../../types";

export const useSourceByIdQuery = (id: string | null) => {
  const { data, error } = useQuery({
    queryKey: [QUERY_KEY.articles, id],
    queryFn: async () => {
      const result = await supabase
        .from("sources")
        .select("*")
        .eq("id", id)
        .single();
      return result;
    },
    enabled: id !== null,
  });
  return { sourceById: (data?.data as Source) ?? null, error };
};
