// src/config/lemonsqueezy.ts

import {
  getProduct,
  lemonSqueezySetup,
  listPrices,
  listProducts,
  listSubscriptions,
  Variant,
} from "@lemonsqueezy/lemonsqueezy.js";

/**
 * Ensures that required environment variables are set and sets up the Lemon
 * Squeezy JS SDK. Throws an error if any environment variables are missing or
 * if there's an error setting up the SDK.
 */
export function initializeLemonSqueezy() {
  const requiredEnvKeys = [
    "VITE_LEMONSQUEEZY_API_KEY",
    "VITE_LEMONSQUEEZY_STORE_ID",
    // "LEMONSQUEEZY_WEBHOOK_SECRET",
  ];

  const missingKeys = requiredEnvKeys.filter(
    (envKey) => !import.meta.env[envKey],
  );

  if (missingKeys.length > 0) {
    throw new Error(
      `Missing required LEMONSQUEEZY env variables: ${missingKeys.join(
        ", ",
      )}. Please, set them in your .env file.`,
    );
  }

  lemonSqueezySetup({
    apiKey: import.meta.env.VITE_LEMONSQUEEZY_API_KEY,
    onError: (error) => {
      throw new Error(`Lemon Squeezy API error: ${error.message}`);
    },
  });
}

export type Plan = {
  id: number;
  productId: number;
  productName: string;
  variantId: number;
  name: string;
  description: string;
  price: string;
  isUsageBased: boolean;
  interval?: "day" | "week" | "month" | "year" | null;
  intervalCount?: number | null;
  trialInterval?: "day" | "week" | "month" | "year" | null;
  trialIntervalCount?: number | null;
  sort: number;
};

export async function syncPlans() {
  initializeLemonSqueezy();

  const productVariants: Plan[] = [];
  async function addVariant(variant: Plan) {
    productVariants.push(variant);
  }

  const products = await listProducts({
    filter: { storeId: import.meta.env.VITE_LEMONSQUEEZY_STORE_ID },
    include: ["variants"],
  });

  const allVariants = products.data?.included as Variant["data"][] | undefined;

  if (allVariants) {
    for (const v of allVariants) {
      const variant = v.attributes;

      // Skip draft variants or if there's more than one variant, skip the default
      // variant. See https://docs.lemonsqueezy.com/api/variants
      if (
        variant.status === "draft" ||
        (allVariants.length !== 1 && variant.status === "pending")
      ) {
        // `return` exits the function entirely, not just the current iteration.
        // so use `continue` instead.
        continue;
      }

      // Fetch the Product name.
      const productName =
        (await getProduct(variant.product_id)).data?.data.attributes.name ?? "";

      // Fetch the Price object.
      const variantPriceObject = await listPrices({
        filter: {
          variantId: v.id,
        },
      });

      const currentPriceObj = variantPriceObject.data?.data.at(0);
      const isUsageBased =
        currentPriceObj?.attributes.usage_aggregation !== null;
      const interval = currentPriceObj?.attributes.renewal_interval_unit;
      const intervalCount =
        currentPriceObj?.attributes.renewal_interval_quantity;
      const trialInterval = currentPriceObj?.attributes.trial_interval_unit;
      const trialIntervalCount =
        currentPriceObj?.attributes.trial_interval_quantity;

      const price = isUsageBased
        ? currentPriceObj?.attributes.unit_price_decimal
        : currentPriceObj.attributes.unit_price;

      const priceString = price !== null ? price?.toString() ?? "" : "";

      const isSubscription =
        currentPriceObj?.attributes.category === "subscription";

      // If not a subscription, skip it.
      if (!isSubscription) {
        continue;
      }

      await addVariant({
        id: parseInt(v.id) as unknown as number,
        name: variant.name,
        description: variant.description,
        price: priceString,
        interval,
        intervalCount,
        isUsageBased,
        productId: variant.product_id,
        productName,
        variantId: parseInt(v.id) as unknown as number,
        trialInterval,
        trialIntervalCount,
        sort: variant.sort,
      });
    }
  }

  return productVariants as Plan[];
}

export const getUserSub = async () => {
  initializeLemonSqueezy();
  const subs = await listSubscriptions();
  return subs;
};
