import features from "../config/features.json";

type SarasaEnvironment = "dev" | "beta" | "alpha" | "stable";

export const useFeatureFlags = ({
  environment,
}: {
  environment: SarasaEnvironment;
}) => {
  if (!environment) return null;

  if (!["dev", "beta", "alpha", "stable"].includes(environment)) {
    throw new Error("Invalid environment");
  }

  return { features: features[environment] };
};
