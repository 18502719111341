import { Button, Card, Link } from "@nextui-org/react";

import {
  Container,
  HorizontalSplitSection,
} from "@/shared/components/index.ts";

import { Section } from "./components/index.ts";

export const SweetSpotContent: React.FC = () => {
  return (
    <Container>
      <HorizontalSplitSection>
        <Section
          className={"md:p-20"}
          customStyle={{
            flex: 1,
            textAlign: "center",
          }}
        >
          <div className="flex flex-col gap-3">
            <strong className="p-0 text-blue-700 text-xl">
              Don’t let your notes gather dust
            </strong>
            <h1
              className={"text-5xl md:text-7xl"}
              style={{
                marginBottom: 0,
                lineHeight: 1.25,
                fontWeight: 600,
              }}
            >
              Turn them into{" "}
              <span className="text-blue-700">
                actionable lasting knowledge
              </span>
              .
            </h1>
          </div>
          <Link href="wait-list" className="mt-5 flex flex-col">
            <Button
              color="primary"
              size="lg"
              style={{ marginTop: 20 }}
              className="px-14 py-10 text-xl "
            >
              Try Sarasa Out
            </Button>
          </Link>
        </Section>
      </HorizontalSplitSection>

      {/* <Section>
        <h1
          style={{ marginBottom: 20 }}
          className={"text-2xl md:text-6xl pb-5"}
        >
          Not For You If
        </h1>
        <div className="flex flex-col md:flex-row sm:justify-start lg:justify-center w-full sm:overflow-x-auto py-10 gap-5 lg:gap-20 p-5">
          <Card className="p-5 flex flex-col gap-5 lg:p-10 w-1/3">
            <h1>🛫</h1>
            <p className="text-2xl">You're looking for a quick fix</p>
          </Card>
          <Card className="p-5 flex flex-col gap-5 lg:p-10 w-1/3">
            <h1>🪄</h1>
            <p className="text-2xl">You want passive learning</p>
          </Card>
          <Card className="p-5 flex flex-col gap-5 lg:p-10 w-1/3">
            <h1>📔</h1>
            <p className="text-2xl">
              You need structured courses (try Coursera instead)
            </p>
          </Card>
        </div>
      </Section>
      <Section>
        <h1
          style={{ marginBottom: 20 }}
          className={"text-2xl md:text-6xl pb-5"}
        >
          Great For
        </h1>
        <div className="flex flex-col md:flex-row sm:justify-start lg:justify-center w-full sm:overflow-x-auto py-10 gap-5 lg:gap-20 p-5">
          <Card className="p-5 flex flex-col gap-5 lg:p-10 w-1/3">
            <h1>📱</h1>
            <p className="text-2xl">
              Following up after courses, books or meetings
            </p>
          </Card>
          <Card className="p-5 flex flex-col gap-5 lg:p-10 w-1/3">
            <h1>👍</h1>
            <p className="text-2xl">Maintaining professional knowledge</p>
          </Card>
          <Card className="p-5 flex flex-col gap-5 lg:p-10 w-1/3">
            <h1>🧠</h1>
            <p className="text-2xl">Gradual, long-term learning</p>
          </Card>
        </div>
      </Section> */}

      <hr style={{ opacity: 0.3 }} />
      <HorizontalSplitSection>
        <Section
          className={"pt-20"}
          customStyle={{
            flex: 1,
            textAlign: "center",
          }}
        >
          <div className="flex flex-col gap-3 align-center">
            <h1
              className={"text-xl md:text-2xl"}
              style={{
                marginBottom: 0,
                lineHeight: 1.25,
                fontWeight: 600,
              }}
            >
              <span className="text-blue-700">How often</span> do you take notes
              from courses, books, or meetings—
              <br />
              only to <span className="text-blue-700">forget</span> that you
              have even taken down later?
            </h1>
          </div>
        </Section>
      </HorizontalSplitSection>
      {/* <hr style={{ opacity:0.3 }}/> */}
      {/* <HorizontalSplitSection>
        <Section>
          <div className="flex flex-col pt-20 gap-3 align-center">
            <h1
              className={"text-xl md:text-2xl pb-10"}
              style={{
                marginBottom: 0,
                lineHeight: 1.25,
                fontWeight: 600,
              }}
            >
              Help us making notes not gather dust.
              <br />
              Join the waitlist now.
            </h1>
          </div>

          <form
            className="launchlist-form flex gap-2"
            action="https://getlaunchlist.com/s/9sEoll"
            method="POST"
          >
            <Input size="sm" label="Name" name="name" type="text" />
            <Input size="sm" label="Email" name="email" type="email" />
            <Button type="submit" size="lg" color="primary">
              Sign Up
            </Button>
          </form>

          <p className="flex justify-center p-5">
            Limited spots available for early access. Don’t miss out! We value
            your privacy and will never spam you.
          </p>
          <script src="https://getlaunchlist.com/js/widget-diy.js" defer />
        </Section>
      </HorizontalSplitSection>
      <hr style={{ opacity:0.3 }}/> */}

      <hr style={{ opacity: 0.3 }} />
      <Section>
        <div className="flex flex-col gap-3 align-center pt-20">
          <h1
            className={"text-xl md:text-2xl justify-center text-center"}
            style={{
              marginBottom: 0,
              lineHeight: 1.25,
              fontWeight: 600,
            }}
          >
            <span className="text-blue-700 ">
              Forget Forgetting. How it Works.
            </span>
          </h1>
        </div>
        <div className="flex flex-col md:flex-row sm:justify-start lg:justify-center w-full sm:overflow-x-auto py-10 gap-5 lg:gap-20 p-5">
          <Card className="p-5 flex flex-col gap-5 lg:p-10 md:w-1/3">
            <h1
              style={{
                // backgroundColor: "blue",
                display: "flex",
                borderRadius: 50,
                color: "white",
                width: "auto",
                aspectRatio: "1/1",
                filter: "grayscale(100%)",
                padding: 20,
                alignItems: "center",
                justifyContent: "center",
                fontSize: "8rem",
              }}
            >
              📚
              {/* 🔌 */}
            </h1>
            <p className="text-xl">
              <b>1. Connect </b>
              <br />
              Connect your notes from courses, books, or meetings.
            </p>
          </Card>
          <Card className="p-5 flex flex-col gap-5 lg:p-10 md:w-1/3">
            <h1
              style={{
                // backgroundColor: "blue",
                display: "flex",
                borderRadius: 50,
                color: "white",
                width: "auto",
                aspectRatio: "1/1",
                filter: "grayscale(100%)",
                padding: 20,
                alignItems: "center",
                justifyContent: "center",
                fontSize: "8rem",
              }}
            >
              ✨{/* 🧠{" "} */}
            </h1>
            <p className="text-xl">
              <b>2. Automate</b>
              <br />
              Let Sarasa AI extract actionable cards from your notes in seconds.
            </p>
          </Card>
          <Card className="p-5 flex flex-col gap-5 lg:p-10 md:w-1/3">
            <h1
              className="background-primary"
              style={{
                // backgroundColor: "blue",
                fontSize: "8rem",
                display: "flex",
                borderRadius: 50,
                color: "white",
                width: "auto",
                aspectRatio: "1/1",
                filter: "grayscale(100%)",
                padding: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* 📈{" "} */}
              {/* 🧠 */}
              {/* 💪 */}
              🤩
            </h1>
            <p className="text-xl">
              <b>3. Retain</b>
              <br />
              Get exposed to your notes and review them at the right time.
            </p>
          </Card>
        </div>
      </Section>

      <hr style={{ opacity: 0.3 }} />
      <HorizontalSplitSection>
        <Section
          className={"pt-20"}
          customStyle={{
            flex: 1,
            textAlign: "center",
          }}
        >
          <div className="flex flex-col gap-3 align-center">
            <h1
              className={"text-xl md:text-2xl"}
              style={{
                marginBottom: 0,
                lineHeight: 1.25,
                fontWeight: 600,
              }}
            >
              <span className="text-blue-700">
                Sarasa helps you rediscover and retain your notes. With
                AI-generated cards and science-backed learning techniques,
                Sarasa ensures your hard work doesn’t go to waste.
              </span>
            </h1>
          </div>
        </Section>
      </HorizontalSplitSection>

      {/* <Section>
        <h1
          style={{ marginBottom: 20 }}
          className={"text-2xl md:text-6xl pb-5"}
        >
          How It Actually Works:
        </h1>
        <div>
          <div className="text-2xl">
            1. You read, see or learn things on your device
          </div>
          <div className="text-2xl">2. You want to not forget it</div>
          <div className="text-2xl">3. You send the material to sarasa</div>
          <div className="text-2xl">
            4. From material, you generate questions with AI or write them
            manually
          </div>

          <div className="text-2xl">
            5. We prompt you to review them at increasing intervals
          </div>
          <div className="text-2xl">6. You tell us how well you remembered</div>
          <div className="text-2xl">
            7. We adjust the next review time accordingly
          </div>
          <br />
          <div className="text-2xl">
            That's it. Proven spaced repetition. AI magic only if you need it.
          </div>
        </div>
      </Section> */}

      {/* <pre>
        {JSON.stringify(
          [
            "A spaced repetition app for professionals",
            "Based on the same principles as Anki, designed for work",
            "Helps you review consistently, nothing more",
          ],
          null,
          2,
        )}
      </pre>

      <pre>
        {JSON.stringify(
          [
            "Requires 5-10 minutes daily",
            "Takes about a week to build the habit",
            "You still need to do the work",
          ],
          null,
          2,
        )}
      </pre> */}

      {/* <Section>
        <div className="text-left flex flex-col">
          <h2>
            Some links to dive deeper on the subject of learning more
            effectively
          </h2>
          {[
            {
              title: "Make it Stick - The Science of Successful Learning",
              link: "https://www.hup.harvard.edu/file/feeds/PDF/9780674729018_sample.pdf",
            },
            {
              title:
                "Coursera - Learning How to Learn: Powerful mental tools to help you master tough subjects",
              link: "https://www.coursera.org/learn/learning-how-to-learn",
            },
            {
              title:
                "A Stochastic Shortest Path Algorithm for Optimizing Spaced Repetition Scheduling",
              link: "https://dl.acm.org/doi/10.1145/3534678.3539081?cid=99660547150",
            },
            {
              title:
                "Optimizing Spaced Repetition Schedule by Capturing the Dynamics of Memory",
              link: "https://ieeexplore.ieee.org/document/10059206",
            },
            {
              title: "Spaced repetition benchmarks",
              link: "https://github.com/open-spaced-repetition/srs-benchmark",
            },
          ].map(
            ({ title, link }) =>
              title && (
                <Link
                  key={title}
                  isExternal
                  href={link}
                  target="_blank"
                  className="mt-5 flex"
                  rel="noreferrer nofollow noopener"
                >
                  <LinkIcon /> {title}
                </Link>
              ),
          )}
        </div>
      </Section> */}

      {/* <Section>
        <h1
          style={{ marginBottom: 20 }}
          className={"text-2xl md:text-6xl pb-5"}
        >
          Why Consistency is Key
        </h1>

        <p>
          This isn’t a quick fix. It’s a path to long-term success. Studies show
          that spaced repetition and interleaved learning can improve retention
          by up to 50%. Our app keeps you accountable and ensures you’re
          learning the smart way—one step at a time.
        </p>
      </Section> */}
    </Container>
  );
};
