import { PropsWithChildren } from "react";

export const Container: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children, ...props }) => {
  return (
    <div className="container px-10 mt-10" {...props}>
      {children}
    </div>
  );
};
