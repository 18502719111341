import { Tab, Tabs } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Container } from "@/shared/components/index.ts";
import { Collections } from "@/widgets/BrowseCollections";
import { Notes } from "@/widgets/BrowseNotes";
import { BrowseProgressByDay } from "@/widgets/BrowseProgressByDay";
import { Snippets } from "@/widgets/BrowseSnippets";
import { Sources } from "@/widgets/BrowseSources";

import { PlannedReviews } from "./PlannedReviews";

export const BrowsePage: React.FC = () => {
  const [tabState, setTabState] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get("view");

  useEffect(() => {
    if (view) {
      setTabState(view);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <div className="flex flex-col gap-6" style={{ width: "100%" }}>
        <div className="flex w-full flex-col">
          <Tabs
            onSelectionChange={(e) => {
              setSearchParams({ view: e as string });
              setTabState(e as string);
            }}
            selectedKey={tabState}
            aria-label="Options"
            variant="underlined"
            size="lg"
            classNames={{
              base: "p-0",
            }}
          >
            <Tab key="collections" title="Collections">
              <Collections />
            </Tab>
            <Tab key="articles" title="Articles">
              <Sources />
            </Tab>
            <Tab key="cards" title="Cards">
              <Notes />
            </Tab>
            <Tab key="snippets" title="Snippets">
              <Snippets />
            </Tab>
            <Tab key="history" title="Progress">
              <BrowseProgressByDay />
            </Tab>
            <Tab key="planned" title="Planned">
              <PlannedReviews />
            </Tab>
          </Tabs>
        </div>
      </div>
    </Container>
  );
};
