import { Button } from "@nextui-org/react";
import { Grade, Rating } from "ts-fsrs";

type NewType = React.Dispatch<React.SetStateAction<boolean>>;

type RatingButtonsProps = {
  buttons: { label: string; rating: Grade }[];
  handleReview: ({ grade }: { grade: Grade }) => void;
  isSubmitting: boolean;
  setIsFlipped: NewType;
  variant?: string;
};
export const RatingButtons: React.FC<RatingButtonsProps> = ({
  buttons,
  handleReview,
  isSubmitting,
  setIsFlipped,
  variant = "card",
}) => {
  const handleOnClick = async ({ grade }: { grade: Grade }) => {
    await handleReview({
      grade,
    });
    setIsFlipped(false);
  };
  return buttons.map((button) => (
    <Button
      disabled={isSubmitting}
      isLoading={isSubmitting}
      key={button.label}
      style={{
        width: "100%",
      }}
      onClick={() => {
        handleOnClick({ grade: button.rating });
      }}
    >
      {/* <Kbd>{index + 1}</Kbd> */}
      {variant === "card" ? button.label : gradeLabelsByDays[button.rating]}
    </Button>
  ));
};

const gradeLabelsByDays = {
  [Rating.Again]: "Just started",
  [Rating.Easy]: "Getting the hang of it",
  [Rating.Good]: "I'm doing great",
  [Rating.Hard]: "I'm a pro",
};
