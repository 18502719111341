import { Session } from "@supabase/supabase-js";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

interface State {
  session: Session | null;
  isLoading: boolean;
}

interface Actions {
  setSession: (session: Session | null) => void;
  setIsLoading: (isLoading: boolean) => void;
}

interface DeckStore extends State, Actions {}

export const useUserStore = create<DeckStore>()(
  devtools(
    immer((set) => ({
      session: null,
      isLoading: false,
      setSession: async (session: Session | null) => {
        set((state) => {
          state.session = session;
        });
      },
      setIsLoading: async (isLoading: boolean) => {
        set((state) => {
          state.isLoading = isLoading;
        });
      },
      logoutUser: async () => {
        set((state) => {
          state.session = null;
        });
      },
    })),
    {
      name: "SrsaiSupabaseUserStore",
    },
  ),
);
