import { Dispatch, SetStateAction } from "react";

import { FormatOption } from "@/entities/entities";

import { DisplayResultOptionButton } from "./DisplayResultOptionButton";

type DisplayOptionsProps = {
  displayFormaOptions: { label: string }[];
  format: string;
  setFormat: Dispatch<SetStateAction<FormatOption>>;
};
export const DisplayOptions: React.FC<DisplayOptionsProps> = ({
  displayFormaOptions,
  format,
  setFormat,
}) => {
  return (
    <div style={{ display: "flex", gap: 10 }}>
      {displayFormaOptions.map((item) => (
        <DisplayResultOptionButton
          key={item.label}
          label={item.label}
          onClick={() => setFormat(item.label as FormatOption)}
          disabled={format === item.label}
        />
      ))}
    </div>
  );
};
