import { supabase } from "@/providers";

import { Source } from "../types";

export const createArticle = async (article: Partial<Source>) => {
  const { data, error } = await supabase
    .from("sources")
    .insert(article)
    .select()
    .maybeSingle();

  return { data, error };
};
