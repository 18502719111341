import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

import { Container } from "@/shared/components/index.ts";
import { useUserNameInstance } from "@/shared/utils";

import { Avatar } from "./Avatar";

export const WelcomePage: React.FC = () => {
  return (
    <Container>
      <div style={columnStyle}>
        <div style={alignCenterStyle}>
          <Avatar />
          <GreetingsUser />
          <h2>What would you like to do today?</h2>
          <ButtonsRow />
        </div>
      </div>
    </Container>
  );
};

const columnStyle: React.CSSProperties = {
  padding: 20,
  width: "100%",
  justifyContent: "center",
  display: "flex",
};

const alignCenterStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const GreetingsUser: React.FC = () => {
  const name = useUserNameInstance();
  return (
    <div style={{ display: "flex", gap: 20 }}>
      <h1>{`Welcome back${name !== null ? ", " + name : ""}.`}</h1>
    </div>
  );
};

const buttonItems = [
  {
    label: "Work with Notes and Decks",
    path: "/deck/browse",
  },
  {
    label: "Just Braindump",
    path: "/deck/browse",
  },
];

const ButtonsRow: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div style={buttonRowStyle}>
      {buttonItems.map((item) => (
        <div key={item.path}>
          <Button
            onClick={() => {
              navigate(item.path);
            }}
          >
            {item.label}
          </Button>
        </div>
      ))}
    </div>
  );
};
const buttonRowStyle: React.CSSProperties = { display: "flex", gap: 20 };
