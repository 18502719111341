import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  getKeyValue,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useCollections } from "@/entities/collections";
import CreateCollectionModal from "@/features/CreateCollectionModal";
import { LoadingSpinner, VerticalDotsIcon } from "@/shared/components";
import { useCollectionDeletionMutation } from "@/shared/hooks";
import { cardListStyle } from "@/shared/styles";
import { Collection } from "@/shared/types/coreDbTypes";
// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import EditCollectionModal from "@/widgets/EditCollectionModal";

// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import DeleteModal from "../DeleteModal";
import { COLLECTIONS_COLUMNS } from "./entities";

export const Collections: React.FC = () => {
  const {
    collections,
    isPending,
    isLoading,
    error: collectionsError,
  } = useCollections();

  const hasData = collections && collections.length > 0;
  return (
    <div style={cardListStyle}>
      <div className={"flex gap-4 justify-between"}>
        <h1>Collections</h1>
        <CreateCollectionModal />
      </div>
      {!hasData && isPending && isLoading && <LoadingSpinner />}
      {!isLoading && !hasData && collectionsError && "Something went wrong."}
      {!isLoading && hasData && <CollectionsTable collections={collections} />}
      {!isLoading &&
        !hasData &&
        "You have no collections yet. Make your first one."}
    </div>
  );
};

type CollectionsTableProps = {
  collections: Collection[];
};
const CollectionsTable: React.FC<CollectionsTableProps> = ({ collections }) => {
  const {
    mutate: mutateCollectionDeletion,
    isError,
    isSuccess,
  } = useCollectionDeletionMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idToBeEdited, setIdToBeEdited] = useState<null | number>(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<null | number>(null);

  const collectionsOrEmpty: Collection[] =
    collections && collections.length > 0 ? collections : [];
  const columnsOrEmpty = COLLECTIONS_COLUMNS;

  const handleDeletion = async (id: number) => {
    mutateCollectionDeletion(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Successfully deleted collection.");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(`Sorry, this action has failed.`);
    }
  }, [isError]);

  return (
    <>
      {idToBeEdited && (
        <EditCollectionModal
          id={idToBeEdited}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {idToBeDeleted && (
        <DeleteModal
          onConfirm={handleDeletion}
          message={`Do you want to delete the collection ${collections.find((col) => col.id === idToBeDeleted)?.title}? This will make all related cards transferred into "other" category.`}
          id={idToBeDeleted}
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
        />
      )}
      <Table isStriped aria-label="Collections table">
        <TableHeader>
          {columnsOrEmpty.map((column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          ))}
        </TableHeader>
        <TableBody>
          {collectionsOrEmpty.map((collection) => (
            <TableRow key={collection.id}>
              {(columnKey) => {
                if (columnKey === "actions") {
                  const item = (
                    <div className="relative flex justify-end items-center gap-2">
                      <Dropdown>
                        <DropdownTrigger>
                          <Button isIconOnly size="sm" variant="light">
                            <VerticalDotsIcon className="text-default-300" />
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu>
                          <DropdownItem
                            textValue="Edit"
                            onPress={() => {
                              setIdToBeEdited(collection.id);
                              setIsModalOpen((prev) => !prev);
                            }}
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            color="danger"
                            textValue="Delete"
                            onPress={() => {
                              setIdToBeDeleted(collection.id);
                              setIsDeleteModalOpen((prev) => !prev);
                            }}
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  );
                  return <TableCell>{item}</TableCell>;
                }

                return (
                  <TableCell>
                    {columnKey === "created_at"
                      ? getKeyValue(collection, columnKey).substring(0, 10)
                      : getKeyValue(collection, columnKey)}
                  </TableCell>
                );
              }}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
