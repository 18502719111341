import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QUERY_KEY } from "@/shared/constants";

import { deleteSourceById } from "../api/deleteSourceById";

export const useSourceDeletionMutation = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, isError, isSuccess } = useMutation({
    mutationKey: ["DELETE_SOURCE"],
    mutationFn: (id: string) => deleteSourceById(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.articles] });
    },
  });
  return { mutate, isPending, isError, isSuccess };
};
