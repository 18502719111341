import permissions from "../config/permissions.json";
import { useUserSubscriptions } from "./useUserSubscriptions";

type SarasaRole = "user_free_plan" | "user_hobby_plan" | "user_premium_plan";
export const usePermissionsFlags = () => {
  const subscription = useUserSubscriptions();

  const variantName =
    subscription &&
    subscription.length > 0 &&
    (subscription[0].attributes.status === "active" ||
      subscription[0].attributes.status === "on_trial") &&
    subscription[0].attributes.variant_name;

  const role: SarasaRole | null =
    variantName && variantName.toLowerCase() === "hobby plan"
      ? "user_hobby_plan"
      : variantName && variantName.toLowerCase() === "premium plan"
        ? "user_premium_plan"
        : "user_free_plan";

  if (!permissions[role]) {
    throw new Error(`Role ${role} not found in permissions.json`);
  }

  return { permissions: permissions[role] };
};
