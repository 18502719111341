import { useQuery } from "@tanstack/react-query";

import { QUERY_KEY } from "@/shared/constants";
import { ReviewLog } from "@/shared/types/index.ts";

import { fetchReviewLogList } from "../api/index.ts";

export const useReviewLogs = () => {
  const {
    data: reviewLogs,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useQuery({
    queryKey: [QUERY_KEY.reviewLogs],
    queryFn: fetchReviewLogList,
  });

  return {
    reviewLogs: (reviewLogs?.data ?? []) as ReviewLog[],
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
