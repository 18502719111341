import { supabase } from "@/providers";

import { Collection } from "../types";

export const createCollection = async (collection: Partial<Collection>) => {
  const { data, error } = await supabase
    .from("collections")
    .insert(collection)
    .select();

  return { data, error };
};
