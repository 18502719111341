import { PropsWithChildren, useEffect } from "react";

import { useUserStore } from "@/entities/sp-user";

import { supabase } from "./supabaseClient";

export const SupabaseProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const setSession = useUserStore((state) => state.setSession);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (!session) return;
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};
