import { supabase } from "@/providers";

import { Collection } from "../types";

export const updateCollection = async (
  collection: Partial<Collection>,
  id: number,
) => {
  const { data, error } = await supabase
    .from("collections")
    .update(collection)
    .eq("id", id);

  return { data, error };
};
