import { GeneratedCardList } from "@/features/deck";

import { RenderAsCSV, RenderAsJSON, RenderAsMD } from ".";
import { RenderAsCard } from "./RenderAsCard";

type DisplayResultProps = {
  displayPreference: string;
  generatedCardList: GeneratedCardList;
  removeGeneratedCardByIndex: (index: number) => void;
};
export const DisplayResult: React.FC<DisplayResultProps> = ({
  displayPreference,
  generatedCardList,
  removeGeneratedCardByIndex,
}) => {
  switch (displayPreference) {
    case "CARD":
      return (
        <RenderAsCard
          generatedCardList={generatedCardList}
          removeGeneratedCardByIndex={removeGeneratedCardByIndex}
        />
      );
    case "JSON":
      return <RenderAsJSON apiResponse={generatedCardList} />;
    case "CSV":
      return <RenderAsCSV apiResponse={generatedCardList} />;
    case "MD":
      return <RenderAsMD apiResponse={generatedCardList} />;
    default:
      return null;
  }
};
