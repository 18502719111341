import { ReactElement } from "react";

type HorizontalSplitSectionProps = {
  children: ReactElement | ReactElement[];
  customStyle?: React.CSSProperties;
  className?: string;
};
export const HorizontalSplitSection: React.FC<HorizontalSplitSectionProps> = ({
  children,
  customStyle = {},
  className = "",
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 20,
        ...customStyle,
      }}
      className={className}
    >
      {children}
    </div>
  );
};
