export const BROWSE_NOTES_COLUMNS = [
  {
    key: "id",
    label: "Id",
  },

  // {
  //   key: "created_by",
  //   label: "Created by",
  // },
  {
    key: "question",
    label: "Question",
  },
  {
    key: "answer",
    label: "Answer",
  },
  //{
  //key: "category",
  //label: "Category",
  //},
  //{
  //key: "tag",
  //label: "Tag",
  //},
  {
    key: "collections",
    label: "Collection",
  },
  {
    key: "sources",
    label: "Source",
  },
  {
    key: "created_at",
    label: "Created at",
  },

  // { key: "cards", label: "Cards" },
  { key: "cards_state", label: "State" },
  { key: "cards_due", label: "Review in" },
  {
    key: "actions",
    label: "Actions",
  },
];
