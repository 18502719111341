// A/B Testing: Try slightly different headline variations to see which one draws the best click-through rate.
// For example: "Learn Smarter, Not Harder" or "Unlock the Power of Your Knowledge."

import { Card } from "@nextui-org/react";

// import { Button, Card, Input, Tab, Tabs } from "@nextui-org/react";
// import { CookieBanner } from "./CookieBanner";
import { Container } from "@/shared/components";

import { Faq, Section } from "./components";
import { SweetSpotContent } from "./SweetSpotContent";

export const LandingPage: React.FC = () => {
  return (
    <Container>
      <div className="flex w-full flex-col">
        {/* <div className="p-3 m-3 text-center">
        <p>
          Hello. <br />
          Please, see your favourite landing page. 🚀
        </p>
      </div> */}

        {/* <Tabs
        aria-label="Options"
        selectedKey={selected}
        onSelectionChange={(key) => setSelected(key.toString())}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Tab key="overpromising" title="Over promising 💦">
          <OverPromisingContent />
        </Tab>
        <Tab key="sweetspot" title="Sweet spot 🪢">
          <SweetSpotContent />
        </Tab>
        <Tab key="underpromising" title="Under promising 🍗">
          <UnderPromisingContent />
        </Tab>
      </Tabs> */}

        <SweetSpotContent />

        <Card className="p-5 m-5 flex flex-col gap-5">
          <Section
            customStyle={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 0,
            }}
          >
            <Faq />
          </Section>
        </Card>
        {/* <Card className="p-5 m-5 flex flex-col gap-5">
        <div className="flex justify-center">
          <div className="flex flex-col gap-2" style={{ textAlign: "center" }}>
            <div>Over promising or under promising one?</div>
            <div>Which made you more conviced?! Which is better?! </div>
            <div className="flex gap-2 justify-center">
              {["💦", "🍗"].map((icon) => (
                <Button
                  key={icon}
                  size="lg"
                  style={{ display: "flex", flexDirection: "column", gap: 0 }}
                  onClick={() => {
                    confetti({
                      particleCount: 100,
                      spread: 100,
                      // origin: { y: 0.6 },
                      shapes: [
                        confetti.shapeFromText({ text: icon, scalar: true }),
                      ],
                    });
                  }}
                >
                  {icon} <div>+1</div>
                </Button>
              ))}
            </div>
          </div>
        </div>
        <Input label={"Please, help me make Sarasa useful, share feedback."} />
      </Card> */}
      </div>
    </Container>
  );
};
