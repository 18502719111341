import { Card } from "ts-fsrs";

import { supabase } from "@/providers";

export const updateCard = async (
  newCardStats: Card,
  cardToBeUpdateId: number,
) => {
  const { data, error } = await supabase
    .from("cards")
    .update(newCardStats)
    .eq("id", Number(cardToBeUpdateId))
    .select()
    .single();

  return { data, error };
};
