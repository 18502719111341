import { useState } from "react";

import { useCollections } from "@/entities/collections";
import {
  DEFAULT_DISPLAY,
  displayFormaOptions,
  FormatOption,
} from "@/entities/entities";
import { useGenerationStore } from "@/features/deck-generation/index.ts";
import { LoadingScreen } from "@/shared/components/index.ts";

import { DisplayOptions } from "./DisplayOptions";
import { DisplayResult } from "./DisplayResult";

export const ResultManipulationPanel: React.FC = () => {
  const [format, setFormat] = useState<FormatOption>(DEFAULT_DISPLAY);

  const { isLoading, generatedCardList, removeGeneratedCardByIndex } =
    useGenerationStore();

  const { collections, error: collectionsError } = useCollections();

  if (collectionsError) {
    throw new Error(`Error fetching collections: ${collectionsError}`);
  }

  return (
    <div style={panelStyle}>
      <div style={topbarStyle}>
        {generatedCardList && collections ? null : (
          <div className="flex justify-center w-max">
            No cards have been generated yet
          </div>
        )}
      </div>

      <div style={layoutStyle}>
        Display generated cards in format
        <DisplayOptions
          displayFormaOptions={displayFormaOptions}
          format={format}
          setFormat={setFormat}
        />
        {isLoading && <LoadingScreen />}
        {isLoading && "Generating..."}
        <DisplayResult
          displayPreference={format}
          generatedCardList={generatedCardList ?? []}
          removeGeneratedCardByIndex={removeGeneratedCardByIndex}
        />
      </div>
    </div>
  );
};

const panelStyle: React.CSSProperties = {
  width: "100%",
  flex: 1,
};
const layoutStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  gap: 20,
};
const topbarStyle: React.CSSProperties = {
  display: "flex",
  gap: 10,
  justifyContent: "space-between",
};

export type SupabaseReturnedSourceArray = {
  content: string;
  created_at: string;
  created_by: string;
  id: string;
  is_public: boolean;
  public_url: string;
  updated_at: string;
};
