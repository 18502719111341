import { Button } from "@nextui-org/react";
import { LuLightbulb, LuSparkles } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

import { useArticles } from "@/entities/articles";
import { Container } from "@/shared/components";

export const Dashboard = () => {
  const navigate = useNavigate();
  const { articles } = useArticles();

  const filteredArticles = articles?.filter((article) => article.is_queued);

  return (
    <>
      <Container>
        {/* <h1>Quick Start</h1>
        <div className="flex gap-5 my-5 w-full">
          <Button
            isDisabled
            color="primary"
            variant="light"
            size="lg"
            className="sm:w-full md:w-1/2 lg:w-1/3 flex flex-col h-auto p-10 sm:text-sm sm:p-2"
            onClick={() => navigate("/deck/learn")}
          >
            <span>
              <LuTrophy size={50} />
            </span>
            <h2 className="text-2xl sm:text-sm whitespace-break-spaces">
              How to Learn Effectively?
            </h2>
            <p style={{ whiteSpace: "wrap" }}>
              Explore the not-so-sexy science-based best practices to learn
              effectively
            </p>
          </Button>
          <Button
            isDisabled
            color="primary"
            variant="light"
            size="lg"
            className="sm:w-full md:w-1/2 lg:w-1/3 flex flex-col h-auto p-10 sm:text-sm sm:p-2"
            onClick={() => navigate("/deck/learn")}
          >
            <span>
              <LuTrophy size={50} />
            </span>
            <h2
              className="text-2xl sm:text-sm whitespace-break-spaces"
              style={{ whiteSpace: "wrap" }}
            >
              How to Get Maximum out of Sarasa?
            </h2>
            <p style={{ whiteSpace: "wrap" }}>
              Learn how to use Sarasa to get maximum out of it
            </p>
          </Button>
          <Button
            isDisabled
            color="primary"
            variant="light"
            size="lg"
            className="sm:w-full md:w-1/2 lg:w-1/3 flex flex-col h-auto p-10 sm:text-sm sm:p-2"
            onClick={() => navigate("/deck/learn")}
          >
            <span>
              <LuPin size={50} />
            </span>
            <h2
              className="text-2xl sm:text-sm whitespace-break-spaces"
              style={{ whiteSpace: "wrap" }}
            >
              Select Priority for the Week
            </h2>
            <p style={{ whiteSpace: "wrap" }}>
              Pin your highest priority collection to the top of the list
            </p>
          </Button>
        </div> */}
        <h1>Strenghten knowledge</h1>
        <div className="flex flex-col md:flex-row gap-5 my-5  w-full sm:text-sm">
          <Button
            color="success"
            variant="light"
            size="lg"
            className="gap-5 w-full justify-start text-left flex h-auto p-10 sm:text-sm sm:p-2"
            onClick={() => navigate("/deck/learn")}
          >
            <span>
              <LuLightbulb size={50} />
            </span>
            <div className="flex flex-col">
              <h2 className="text-2xl sm:text-sm whitespace-break-spaces">
                <b>Targeted Session</b>
              </h2>
              <p style={{ whiteSpace: "wrap" }}>
                Long Term Memory Retention For a Specific Topic
              </p>
            </div>
          </Button>
          {/* <Button
            isDisabled
            color="success"
            variant="light"
            size="lg"
            className="sm:w-full md:w-1/2 lg:w-1/3 flex flex-col h-auto p-10 sm:text-sm sm:p-2"
            onClick={() => navigate("/deck/learn")}
          >
            <span>
              <LuLightbulb size={50} />
            </span>
            <h2 className="text-2xl sm:text-sm whitespace-break-spaces">
              Interleaved Session
            </h2>
            <p style={{ whiteSpace: "wrap" }}>
              Long Term Memory Retention For a Mix of All Topics
            </p>
          </Button>
          <Button
            isDisabled
            color="success"
            variant="light"
            size="lg"
            className="sm:w-full md:w-1/2 lg:w-1/3 flex flex-col h-auto p-10 sm:text-sm sm:p-2"
            onClick={() => navigate("/deck/learn")}
          >
            <span>
              <LuLightbulb size={50} />
            </span>
            <h2 className="text-2xl sm:text-sm whitespace-break-spaces">
              Drilled Self Testing
            </h2>
            <p style={{ whiteSpace: "wrap" }}>
              Unrated short term memory retention for a specific topic
            </p>
          </Button> */}
        </div>

        {/* <h1>Creation</h1>
        <div className="flex flex-col md:flex-row gap-5 my-5  w-full sm:text-sm">
          <Button
            color="default"
            variant="light"
            size="lg"
            className="sm:w-full md:w-1/2 lg:w-1/3 flex flex-col h-auto p-10 sm:text-sm sm:p-2"
            onClick={() => navigate("/deck/generate")}
          >
            <span>
              <LuSparkles size={50} />
            </span>
            <h2 className="text-2xl sm:text-sm whitespace-break-spaces">
              Generate Cards
            </h2>
            <p style={{ whiteSpace: "wrap" }}>
              Create Cards from Your Materials
            </p>
          </Button>
          <Button
            isDisabled
            color="default"
            variant="light"
            size="lg"
            className="sm:w-full md:w-1/2 lg:w-1/3 flex flex-col h-auto p-10 sm:text-sm sm:p-2"
            onClick={() => navigate("/deck/generate")}
          >
            <span>
              <Badge color="primary" content={filteredArticles?.length}>
                <LuSparkles size={50} />
              </Badge>
            </span>
            <h2 className="text-2xl sm:text-sm whitespace-break-spaces">
              Generate Cards from Queue
            </h2>
            <p style={{ whiteSpace: "wrap" }}>
              Create Cards from Your Queued Articles from the Internet. You have{" "}
              {filteredArticles?.length} article
              {filteredArticles?.length == 1 ? "" : "s"} in your queue.{" "}
              {filteredArticles?.map((art) => (
                <i key={art.id}>"{art.ai_title}" </i>
              ))}
            </p>
          </Button>
        </div> */}
        <h1>Create cards from queue</h1>
        <div className="flex flex-col gap-5 my-5 w-full sm:text-sm">
          {filteredArticles?.map((art) => (
            <Button
              key={art.id}
              // isDisabled
              color="default"
              variant="light"
              size="lg"
              className="gap-5 w-full justify-start text-left flex h-auto p-10 sm:text-sm sm:p-2"
              onClick={() => navigate(`/deck/generate?source=${art.id}`)}
            >
              <span>
                <LuSparkles size={50} />
              </span>
              <div className="flex flex-col text-left">
                <p>
                  <b>{art.ai_title}</b>
                </p>
                <p style={{ whiteSpace: "wrap" }}>
                  {art.created_at.slice(0, 10)} {art.external_url}
                </p>
                <p style={{ whiteSpace: "wrap", color: "gray" }}>
                  {art.ai_summary}
                </p>
              </div>
            </Button>
          ))}
        </div>
      </Container>
    </>
  );
};
