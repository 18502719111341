import { supabase } from "@/providers";

import { Snippet } from "../types";

export const fetchSnippetsList = async (): Promise<Snippet[]> => {
  const { data: snippets, error: snippetsError } = await supabase
    .from("snippets")
    .select(
      `
              *,
              collections (title),
              sources (ai_title),
              cards:card_id(*),
              logs:card_id(review_logs(*))
            `,
    )
    .order("created_at", { ascending: false });

  if (snippetsError) {
    console.error(
      "There has been problem loading snippets. Reason: ",
      snippetsError,
    );
  }

  return snippets ?? [];
};
