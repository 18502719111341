import { useEffect, useState } from "react";

export const useDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(true);

  useEffect(() => {
    const savedMode = localStorage.getItem("darkMode");

    if (savedMode === "true") {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => {
      localStorage.setItem("darkMode", JSON.stringify(!prev));
      return !prev;
    });
  };

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add(
        "dark",
        "text-foreground",
        "bg-background",
      );
    } else {
      document.documentElement.classList.remove(
        "dark",
        "text-foreground",
        "bg-background",
      );
    }
  }, [isDarkMode]);

  return [isDarkMode, toggleDarkMode] as const;
};
