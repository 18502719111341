import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteCardById } from "../../api";
import { QUERY_KEY } from "../../constants";

export const useCardDeletionMutation = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, isError, isSuccess } = useMutation({
    mutationKey: ["DELETE_NOTE"],
    mutationFn: (id: number) => deleteCardById(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.notes] });
    },
  });
  return { mutate, isPending, isError, isSuccess };
};
