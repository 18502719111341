import { Card as FSRSCard } from "ts-fsrs";

import { supabase } from "@/providers";

export const insertNewCard = async (newCardStats: FSRSCard) => {
  const { data, error } = await supabase
    .from("cards")
    .insert(newCardStats)
    .select()
    .single();
  return { data, error };
};
