import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteCollectionById } from "../../api/deleteCollectionById";
import { QUERY_KEY } from "../../constants";

export const useCollectionDeletionMutation = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, isError, isSuccess } = useMutation({
    mutationKey: ["DELETE_COLLECTION"],
    mutationFn: (id: number) => deleteCollectionById(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.collections] });
    },
  });
  return { mutate, isPending, isError, isSuccess };
};
