import { Button, Card } from "@nextui-org/react";
import { Switch } from "@nextui-org/react";
import { useContext, useState } from "react";
import { useCookies } from "react-cookie";

import { DarkModeContext } from "@/providers/ThemeProvider";

export const CookieBanner = () => {
  const { isDarkMode } = useContext(DarkModeContext);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [cookies, setCookie] = useCookies([
    "cookieAnswered",
    "functional",
    "analytics",
    "marketing",
  ]);
  const [functional, setFunctional] = useState(cookies.functional || true);
  const [analytics, setAnalytics] = useState(cookies.analytics || true);
  // const [marketing, setMarketing] = useState(cookies.marketing || false);

  const savePreferences = () => {
    setCookie("cookieAnswered", true, { path: "/" });
    setCookie("functional", functional, { path: "/" });
    setCookie("analytics", analytics, { path: "/" });
    // setCookie("marketing", marketing, { path: "/" });
  };

  const preferences = [
    {
      label: "Strictly necessary",
      checked: true,
      description: "Essential for the site to function. Always On.",
      state: true,
      setState: undefined,
      disabled: true,
    },
    {
      label: "Functional",
      checked: functional,
      description:
        "Used to remember preference selections and provide enhanced features.",
      state: functional,
      setState: setFunctional,
      disabled: false,
    },
    {
      label: "Analytics",
      checked: analytics,
      description: "Used to measure usage and improve your experience.",
      state: analytics,
      setState: setAnalytics,
      disabled: false,
    },
    // {
    //   label: "Marketing",
    //   checked: marketing,
    //   description: "Used for targeted advertising.",
    //   state: marketing,
    //   setState: setMarketing,
    //   disabled: false,
    // },
  ];
  if (cookies.cookieAnswered === undefined)
    return (
      <Card
        style={{
          display: "flex",
          position: "fixed",
          left: 8,
          right: 8,
          bottom: 8,
          padding: 20,
          zIndex: 1000,
          backgroundColor: !isDarkMode
            ? "rgba(255, 255, 255, 0.5)"
            : "rgba(255, 255, 255, 0.1",
          backdropFilter: "blur(10px)",
          fontSize: 14,
        }}
      >
        {showMoreOptions === false ? (
          <div className="flex justify-between items-center">
            <div className="flex flex-col gap-2">
              Sarasa uses cookies to offer you a better experience.
              {/* <br />
              See Cookie Notice for details. */}
            </div>
            <div className="flex gap-2">
              <Button
                size="sm"
                onClick={() => {
                  setShowMoreOptions(true);
                }}
                variant="light"
              >
                More options
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  setCookie("cookieAnswered", false, { path: "/" });
                  setCookie("functional", false, { path: "/" });
                  setCookie("analytics", false, { path: "/" });
                  setCookie("marketing", false, { path: "/" });
                }}
                variant="light"
              >
                Reject all
              </Button>
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  setCookie("cookieAnswered", true, { path: "/" });
                  setCookie("functional", true, { path: "/" });
                  setCookie("analytics", true, { path: "/" });
                  setCookie("marketing", true, { path: "/" });
                }}
              >
                Accept all
              </Button>
            </div>
          </div>
        ) : (
          <div
            className="flex flex-col gap-3 justify-between items-center"
            style={{ fontSize: 12 }}
          >
            <div className="flex justify-between w-full">
              <div className="flex flex-col gap-2 ">
                Sarasa uses cookies to offer you a better experience.
                {/* <br />
                See Cookie Notice for details. */}
              </div>
              <Button
                size="sm"
                onClick={() => {
                  savePreferences();
                  setShowMoreOptions(false);
                }}
                color="primary"
              >
                Done
              </Button>
            </div>
            <div className="flex justify-between gap-3">
              {preferences.map((preference) => (
                <Card
                  key={preference.label}
                  className={`flex flex-row gap-3 justify-between p-3 w-1/${preferences.length} items-start`}
                  style={{
                    backgroundColor: !isDarkMode
                      ? "rgba(255, 255, 255, 0.5)"
                      : "rgba(0, 0, 0, 0.6",
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <div className="flex flex-col gap-2">
                    <strong>{preference.label}</strong>
                    <div>{preference.description}</div>
                  </div>
                  <Switch
                    size="sm"
                    isDisabled={preference.disabled}
                    isSelected={preference.state}
                    onChange={() =>
                      !preference.disabled &&
                      preference.setState &&
                      preference.setState((prev: boolean) => !prev)
                    }
                    aria-label={`${preference.label} preference`}
                  />
                </Card>
              ))}
            </div>
          </div>
        )}
      </Card>
    );

  return null;
};
