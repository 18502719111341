import { useQuery } from "@tanstack/react-query";

import { getSourceByCollectionId } from "@/shared/api";

export const useAdjustedSource = ({
  collectionId,
}: {
  collectionId: string | null;
}) => {
  const { data: collections } = useQuery({
    // TODO JV: What is this doing?
    queryKey: ["sources", collectionId, "adjusted"],
    enabled: collectionId !== null,
    queryFn: async () => getSourceByCollectionId(collectionId ?? ""),
  });

  return { collections };
};
