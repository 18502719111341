import { useQuery } from "@tanstack/react-query";

import { QUERY_KEY } from "@/shared/constants";

import { getArticleTitleNormalizedList } from "../api";

// QUERY_TODO This is not normalized
export const useArticleTitleNormalizedListQuery = () => {
  const { data, error } = useQuery({
    queryKey: [QUERY_KEY.articles, "titleList"],
    queryFn: async () => getArticleTitleNormalizedList(),
  });
  return { articleTitleList: data?.data, error };
};
