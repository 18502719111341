import { useQuery } from "@tanstack/react-query";

import { fetchNoteList } from "../../api";
import { QUERY_KEY } from "../../constants";

export const useNoteListQuery = () => {
  const {
    data: notes,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useQuery({
    queryKey: [QUERY_KEY.notes],
    queryFn: fetchNoteList,
  });

  return {
    notes,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
