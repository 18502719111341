import { useQuery } from "@tanstack/react-query";

import { QUERY_KEY } from "@/shared/constants";

import { getArticleList } from "../api/index.ts";

export const useArticles = () => {
  const {
    data: articles,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useQuery({
    queryKey: [QUERY_KEY.articles],
    queryFn: getArticleList,
  });

  return {
    articles: articles?.data ?? null,
    isPending,
    isLoading,
    isSuccess,
    isError,
    articlesError: error,
  };
};
