import { supabase } from "@/providers";

import { Note } from "../types";

export const updateNote = async (
  note: Partial<Note>,
  noteToBeUpdatedId: number,
) => {
  const { data, error } = await supabase
    .from("notes")
    .update(note)
    .eq("id", noteToBeUpdatedId)
    .select()
    .single();

  return { data, error };
};
