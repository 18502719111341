import { useQuery } from "@tanstack/react-query";

import { supabase } from "@/providers/supabaseClient.ts";

import { QUERY_KEY } from "../../constants";

export const useMaintainanceBanner = () => {
  const { data, isPending } = useQuery({
    queryKey: [QUERY_KEY.maintainanceBanner],
    queryFn: getMaintenanceBanner,
  });

  return {
    data: data?.data,
    isPending,
  };
};

export const getMaintenanceBanner = async () => {
  const { data, error } = await supabase
    .from("maintenance_banner")
    .select("*")
    .order("start_date", { ascending: false })
    .limit(1)
    .maybeSingle();

  if (error) {
    const unifiedErrorMessage =
      "Couldn't load maintenance banner. Reason: " + error.message;
    console.error(unifiedErrorMessage);
    throw new Error(unifiedErrorMessage);
  }

  return { data: data as MaintenanceBanner, error };
};

export type MaintenanceBanner = {
  id: number;
  is_active: boolean;
  message: string;
  badge_text: string | null;
  link_url: string | null;
  start_date: string; // ISO date string format
  end_date: string; // ISO date string format
  target_pages: string[] | null;
  is_dismissible: boolean;
  last_updated: string | null; // ISO date string format
};
