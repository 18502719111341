import { ApiResponse } from "../GenerateCardsPage";

export const RenderAsMD = ({ apiResponse }: { apiResponse: ApiResponse }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        // border: "1px solid red",
      }}
    >
      {apiResponse.length > 0 ? (
        apiResponse.map((item) => (
          <div key={item.question}>
            <strong>Question</strong>
            <br />
            {item?.question}
            <br />
            <strong>Answer</strong>
            <br />
            {item?.answer}
            <br />
          </div>
        ))
      ) : (
        <>No cards yet...</>
      )}
    </div>
  );
};
