export const Footer = () => {
  return (
    <div
      className="hidden sm:flex"
      style={{ paddingBottom: 23, paddingLeft: 60 }}
    >
      sarasa.app © 2024
    </div>
  );
};
