import { Button, Input } from "@nextui-org/react";

import { Container } from "@/shared/components/index.ts";

import { Section } from "./components/index.ts";

export const Waitlist: React.FC = () => {
  return (
    <Container>
      <Section
        className={"md:pt-20"}
        customStyle={{
          flex: 1,
          textAlign: "center",
        }}
      >
        <div className="flex flex-col gap-3 align-center">
          <h1
            className={"text-4xl md:text-6xl"}
            style={{
              marginBottom: "1rem",
              lineHeight: 1.25,
              fontWeight: 600,
            }}
          >
            Thanks for Your Interest in Sarasa!
          </h1>
          <p className="text-xl">
            We’re thrilled that you’re excited to try Sarasa. Due to limited
            capacity, we’re inviting a select group of early adopters to
            experience the app first.
          </p>
          <p className="text-lg text-gray-600">
            Be among the first to experience Sarasa and turn your notes into
            lasting knowledge.
          </p>
        </div>
      </Section>

      <Section>
        <div
          className="launchlist-form flex flex-col gap-5 items-center"
          style={{ marginTop: "2rem" }}
        >
          <form
            className="launchlist-form flex gap-2"
            action="https://getlaunchlist.com/s/9sEoll"
            method="POST"
          >
            <Input size="sm" label="Name" name="name" type="text" />
            <Input size="sm" label="Email" name="email" type="email" />
            <Button type="submit" size="lg" color="primary">
              Sign Up
            </Button>
          </form>
          <p className="text-center text-sm text-gray-500">
            Spots are limited, and early access is on a first-come, first-served
            basis. Don’t worry, we value your privacy and promise to never spam
            you.
          </p>
        </div>
      </Section>
    </Container>
  );
};

// const OldWaitlist: React.FC = () => {
//   const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
//   return (
//     <>
//       <div
//         style={{
//           display: "flex",
//           position: "fixed",
//           left: 16,
//           right: 16,
//           top: 16,
//           padding: 20,
//           zIndex: 1000,
//           backgroundColor: !isDarkMode
//             ? "rgba(255, 255, 255, 0.5)"
//             : "rgba(255, 255, 255, 0.1",
//           backdropFilter: "blur(10px)",
//         }}
//       >
//         <div className="flex justify-between w-max" style={{ width: "100%" }}>
//           <div />
//           <Logo />
//           {isDarkMode ? (
//             <Button
//               isIconOnly
//               variant="light"
//               key="dark"
//               onClick={toggleDarkMode}
//             >
//               <MoonIcon />
//             </Button>
//           ) : (
//             <Button
//               isIconOnly
//               variant="light"
//               key="light"
//               onClick={toggleDarkMode}
//             >
//               <SunIcon />
//             </Button>
//           )}
//         </div>
//       </div>

//       <Container>
//         <HorizontalSplitSection>
//           <Section
//             className={"md:pt-20"}
//             customStyle={{
//               flex: 1,
//               textAlign: "center",
//             }}
//           >
//             <div className="flex flex-col gap-3 align-center">
//               <h1
//                 className={"text-4xl md:text-6xl"}
//                 style={{
//                   marginBottom: 0,
//                   lineHeight: 1.25,
//                   fontWeight: 600,
//                 }}
//               >
//                 {/* Transform Your Notes into Actionable Lasting Knowledge */}
//                 Forget forgetting.
//                 <br />
//                 Turn your <span className="text-blue-700">notes</span> into{" "}
//                 <span className="text-blue-700">
//                   actionable lasting knowledge
//                 </span>
//                 .
//               </h1>
//             </div>
//           </Section>
//         </HorizontalSplitSection>
//         <hr />
//         <HorizontalSplitSection>
//           <Section
//             className={"md:pt-20"}
//             customStyle={{
//               flex: 1,
//               textAlign: "center",
//             }}
//           >
//             <div className="flex flex-col gap-3 align-center">
//               <h1
//                 className={"text-xl md:text-2xl"}
//                 style={{
//                   marginBottom: 0,
//                   lineHeight: 1.25,
//                   fontWeight: 600,
//                 }}
//               >
//                 <span className="text-blue-700">How often</span> do you take
//                 notes from courses, books, or meetings—only to{" "}
//                 <span className="text-blue-700">forget</span> that you have even
//                 taken down later?
//               </h1>
//             </div>
//           </Section>
//         </HorizontalSplitSection>
//         <hr />
//         <HorizontalSplitSection>
//           <Section>
//             <div className="flex flex-col pt-20 gap-3 align-center">
//               <h1
//                 className={"text-xl md:text-2xl pb-10"}
//                 style={{
//                   marginBottom: 0,
//                   lineHeight: 1.25,
//                   fontWeight: 600,
//                 }}
//               >
//                 Help us making notes not gather dust.
//                 <br />
//                 Join the waitlist now.
//               </h1>
//             </div>

//             <form
//               className="launchlist-form flex gap-2"
//               action="https://getlaunchlist.com/s/9sEoll"
//               method="POST"
//             >
//               <Input size="sm" label="Name" name="name" type="text" />
//               <Input size="sm" label="Email" name="email" type="email" />
//               <Button type="submit" size="lg" color="primary">
//                 Sign Up
//               </Button>
//             </form>

//             <p className="flex justify-center p-5">
//               Limited spots available for early access. Don’t miss out! We value
//               your privacy and will never spam you.
//             </p>
//             <script src="https://getlaunchlist.com/js/widget-diy.js" defer />
//           </Section>
//         </HorizontalSplitSection>
//         <hr />

//         <hr />
//         <Section>
//           <div className="flex flex-col gap-3 align-center pt-20">
//             <h1
//               className={"text-xl md:text-2xl"}
//               style={{
//                 marginBottom: 0,
//                 lineHeight: 1.25,
//                 fontWeight: 600,
//               }}
//             >
//               <span className="text-blue-700">How Sarasa works</span>
//             </h1>
//           </div>
//           <div className="flex flex-col md:flex-row sm:justify-start lg:justify-center w-full sm:overflow-x-auto py-10 gap-5 lg:gap-20 p-5">
//             <Card className="p-5 flex flex-col gap-5 lg:p-10 w-1/3">
//               <h1
//                 style={{
//                   // backgroundColor: "blue",
//                   display: "flex",
//                   borderRadius: 50,
//                   color: "white",
//                   width: "auto",
//                   aspectRatio: "1/1",
//                   filter: "grayscale(100%)",
//                   padding: 20,
//                   alignItems: "center",
//                   justifyContent: "center",
//                   fontSize: "8rem",
//                 }}
//               >
//                 📚
//                 {/* 🔌 */}
//               </h1>
//               <p className="text-xl">
//                 <b>1. Connect </b>
//                 <br />
//                 Connect your notes from courses, books, or meetings.
//               </p>
//             </Card>
//             <Card className="p-5 flex flex-col gap-5 lg:p-10 w-1/3">
//               <h1
//                 style={{
//                   // backgroundColor: "blue",
//                   display: "flex",
//                   borderRadius: 50,
//                   color: "white",
//                   width: "auto",
//                   aspectRatio: "1/1",
//                   filter: "grayscale(100%)",
//                   padding: 20,
//                   alignItems: "center",
//                   justifyContent: "center",
//                   fontSize: "8rem",
//                 }}
//               >
//                 ✨{/* 🧠{" "} */}
//               </h1>
//               <p className="text-xl">
//                 <b>2. Automate</b>
//                 <br />
//                 Let Sarasa AI extract actionable cards from your notes in
//                 seconds.
//               </p>
//             </Card>
//             <Card className="p-5 flex flex-col gap-5 lg:p-10 w-1/3">
//               <h1
//                 className="background-primary"
//                 style={{
//                   // backgroundColor: "blue",
//                   fontSize: "8rem",
//                   display: "flex",
//                   borderRadius: 50,
//                   color: "white",
//                   width: "auto",
//                   aspectRatio: "1/1",
//                   filter: "grayscale(100%)",
//                   padding: 20,
//                   alignItems: "center",
//                   justifyContent: "center",
//                 }}
//               >
//                 {/* 📈{" "} */}
//                 {/* 🧠 */}
//                 {/* 💪 */}
//                 🤩
//               </h1>
//               <p className="text-xl">
//                 <b>3. Retain</b>
//                 <br />
//                 Get exposed to your notes and review them at the right time.
//               </p>
//             </Card>
//           </div>
//         </Section>

//         <hr />
//         <HorizontalSplitSection>
//           <Section
//             className={"md:pt-20"}
//             customStyle={{
//               flex: 1,
//               textAlign: "center",
//             }}
//           >
//             <div className="flex flex-col gap-3 align-center">
//               <h1
//                 className={"text-xl md:text-2xl"}
//                 style={{
//                   marginBottom: 0,
//                   lineHeight: 1.25,
//                   fontWeight: 600,
//                 }}
//               >
//                 <span className="text-blue-700">
//                   Sarasa helps you rediscover and retain your notes. With
//                   AI-generated cards and science-backed learning techniques,
//                   Sarasa ensures your hard work doesn’t go to waste.
//                 </span>
//               </h1>
//             </div>
//           </Section>
//         </HorizontalSplitSection>
//       </Container>
//     </>
//   );
// };
