import { useQuery } from "@tanstack/react-query";

import { fetchNoteListByQuestionIdList } from "@/entities/notes";
import { QUERY_KEY } from "@/shared/constants";
import { Note } from "@/shared/types/coreDbTypes";

export const useNoteListByQuestionIdListQuery = (
  questionIdList: Note["id"][],
) => {
  const {
    data: selectedNotes,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useQuery({
    queryKey: [QUERY_KEY.selectedNotesPerQuestionsIndexList, questionIdList],
    queryFn: async () => await fetchNoteListByQuestionIdList(questionIdList),
  });

  return {
    selectedNotes,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
