import { useQuery } from "@tanstack/react-query";

import { QUERY_KEY } from "@/shared/constants";

import { getCollectionById, getCollectionList } from "../api/index";

// TODO JV: Renamte to Collections
export const useCollections = () => {
  const { data, isPending, isLoading, isSuccess, isError, error } = useQuery({
    queryKey: [QUERY_KEY.collections],
    queryFn: getCollectionList,
    refetchOnWindowFocus: true,
  });

  return {
    collections: data?.data ?? null,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};

export const useCollectionByIdQuery = (id: number) => {
  const { data, isPending, isLoading, isSuccess, isError, error } = useQuery({
    queryKey: [QUERY_KEY.collections, id],
    queryFn: () => getCollectionById(id),
    refetchOnWindowFocus: true,
  });

  return {
    collection: data?.data ?? null,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
