import { Accordion, AccordionItem } from "@nextui-org/react";

import { faqBulletList } from "../entities";

export const Faq: React.FC = () => {
  return (
    <>
      <h1 className={"text-2xl md:text-6xl"}>FAQ</h1>
      <Accordion>
        {faqBulletList.map((faqBullet, index) => (
          <AccordionItem
            key={index}
            aria-label={faqBullet.question}
            title={faqBullet.question}
          >
            {faqBullet.answer}
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};
