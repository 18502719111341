import { Container } from "@/shared/components/index.ts";

import { CreateSnippetWidget } from "./CreateSnippetWidget";

export const CreateSnippetPage = () => {
  return (
    <Container>
      <div className="flex flex-col gap-6" style={{ width: "100%" }}>
        <h1>Create your own snippet</h1>
        <CreateSnippetWidget />
      </div>
    </Container>
  );
};
