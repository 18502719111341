import {
  Button,
  Card,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Tooltip,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import {
  // LuCheckCircle,
  LuMinusCircle,
  LuPlusCircle,
  LuRepeat,
  LuSparkles,
  LuTrash,
} from "react-icons/lu";

import { useUserStore } from "@/entities/sp-user";
// import { SetState } from "zustand";
import { GeneratedCard } from "@/features/deck";
import { useGenerationStore } from "@/features/deck-generation";
import { supabase } from "@/providers";
import { SarasaCodeSyntaxBlock } from "@/shared/components";
import { useCreditsQuery } from "@/shared/hooks/queries/useCreditsQuery";

import { getPromptModelResponse } from "../api/getPromptModelResponse";
import { GENERATION_CONFIG_MODEL } from "./GenerationPanel";

type RenderAsCardProps = {
  generatedCardList: GeneratedCard[];
  removeGeneratedCardByIndex: (index: number) => void;
};
export const RenderAsCard: React.FC<RenderAsCardProps> = ({
  generatedCardList,
  removeGeneratedCardByIndex,
}) => {
  return generatedCardList.map((item, index) => (
    <GeneratedCardComponent
      key={index + "_" + item.question + "_" + item.answer}
      item={item}
      index={index}
      removeGeneratedCardByIndex={removeGeneratedCardByIndex}
    />
  ));
};

const GeneratedCardComponent = ({
  item,
  index,
  removeGeneratedCardByIndex,
}: {
  item: GeneratedCard;
  index: number;
  removeGeneratedCardByIndex: (index: number) => void;
}) => {
  const { generatedCardList, updateGeneratedCardByIndex } =
    useGenerationStore();

  // const [, setPritoting] = useState(false);

  const userId = useUserStore((state) => state.session?.user.id);
  const {
    data: credits,
    isPending: isCreditsPending,
    refetch,
  } = useCreditsQuery();
  const left_credits = credits?.monthly_credit_limit - credits?.used_credits;

  const onRegeneration = async (id: number, command?: string) => {
    if (
      !isCreditsPending &&
      credits &&
      credits?.monthly_credit_limit - credits?.used_credits === 0
    )
      return;

    const cardToBeMutated = generatedCardList[id];
    const body: GeneratedCard | null = await getPromptModelResponse({
      model: GENERATION_CONFIG_MODEL,
      messages: [
        {
          role: "system",
          content:
            "You are a personalised AI assistant helping refine user flashcards. You take flashcards in JSON format {question: string, answer: string}, you perform necessary action and you return them in same format.",
        },
        {
          role: "user",
          content: `Get this card: ${JSON.stringify(cardToBeMutated)}, ${command ? command : "rephrase the card"} and the answer and return JSON.`,
        },
      ],
      response_format: { type: "json_object" },
    });

    if (!body) {
      throw new Error("No response body found in regeneration.");
    }

    await supabase
      .from("user_credits")
      .update({ used_credits: credits?.used_credits + 1 })
      .eq("user_id", userId);

    await refetch();

    updateGeneratedCardByIndex(id, body);
  };

  return (
    <Card key={item.question} style={{ width: "100%", padding: 20, gap: 20 }}>
      <div>
        <div>
          {index + 1}. <strong>{item.question}</strong>
        </div>
        <Divider className="my-5" />
        <div>{item.answer}</div>
      </div>
      {item.additional_data ? (
        <SarasaCodeSyntaxBlock>
          {JSON.stringify(JSON.parse(item.additional_data), null, 2)}
        </SarasaCodeSyntaxBlock>
      ) : null}
      <Actions
        index={index}
        onRegeneration={onRegeneration}
        removeGeneratedCardByIndex={removeGeneratedCardByIndex}
        left_credits={left_credits}
        // setPritoting={setPritoting}
      />
    </Card>
  );
};

const Actions = ({
  index,
  onRegeneration,
  removeGeneratedCardByIndex,
  left_credits,
  // setPritoting,
}: {
  index: number;
  onRegeneration: (id: number, command?: string) => void;
  removeGeneratedCardByIndex: (index: number) => void;
  left_credits: number;
  // setPritoting: SetState<boolean>;
}) => {
  return (
    <div className="flex gap-1 text-gray-400">
      <Dropdown>
        <DropdownTrigger>
          {/* <Tooltip content="Repeat"> */}
          <Button
            isDisabled={left_credits === 0}
            isIconOnly
            size="sm"
            variant="light"
            style={{ color: "gray" }}
          >
            <LuRepeat />
          </Button>
          {/* </Tooltip> */}
        </DropdownTrigger>
        <DropdownMenu aria-label="Static Actions">
          <DropdownSection title="Question Actions" showDivider>
            <DropdownItem
              key="edit"
              textValue="edit"
              onPress={() =>
                onRegeneration(
                  index,
                  "rephrase the question only (don't touch the answer)",
                )
              }
            >
              <div className="flex gap-2">
                <LuSparkles /> Rephrase
              </div>
            </DropdownItem>
          </DropdownSection>

          <DropdownSection title="Answer Actions" showDivider>
            <DropdownItem
              key="new"
              textValue="new"
              onPress={() =>
                onRegeneration(
                  index,
                  "make the answer more detailed and longer",
                )
              }
            >
              {" "}
              <div className="flex gap-2">
                <LuPlusCircle /> Include more detail
              </div>
            </DropdownItem>

            <DropdownItem
              key="edit"
              textValue="edit"
              onPress={() => onRegeneration(index, "rephrase the answer only")}
            >
              <div className="flex gap-2">
                <LuSparkles /> Rephrase
              </div>
            </DropdownItem>
            <DropdownItem
              key="copy"
              textValue="copy"
              onPress={() =>
                onRegeneration(
                  index,
                  "make the answer shorter and less detailed",
                )
              }
            >
              <div className="flex gap-2">
                <LuMinusCircle />
                Include less detail
              </div>
            </DropdownItem>
          </DropdownSection>

          <DropdownSection title="Whole card">
            <DropdownItem
              key="new"
              textValue="new"
              onPress={() =>
                onRegeneration(
                  index,
                  "make the both question and answer more detailed",
                )
              }
            >
              <div className="flex gap-2">
                <LuPlusCircle />
                Include more detail
              </div>
            </DropdownItem>

            <DropdownItem
              key="edit"
              textValue="edit"
              onPress={() =>
                onRegeneration(index, "rephrase both question and answer")
              }
            >
              <div className="flex gap-2">
                <LuSparkles /> Rephrase
              </div>
            </DropdownItem>
            <DropdownItem
              key="copy"
              textValue="copy"
              onPress={() =>
                onRegeneration(
                  index,
                  "make both the answer and answer less detailed and shorter",
                )
              }
            >
              <div className="flex gap-2">
                <LuMinusCircle />
                Include less detail
              </div>
            </DropdownItem>

            {/* <DropdownItem key="edit" textValue="edit">
              Add example
            </DropdownItem> */}
          </DropdownSection>
        </DropdownMenu>
      </Dropdown>

      {/* <Tooltip content="Prioritize">
        <Button
        isDisabled={left_credits === 0}
          onPress={() => setPritoting((prev: boolean) => !prev)}
          isIconOnly
          size="sm"
          variant="light"
          style={{ color: "gray" }}
        >
          <LuCheckCircle />
        </Button>
      </Tooltip> */}

      <DeleteButton
        left_credits={left_credits}
        index={index}
        removeGeneratedCardByIndex={removeGeneratedCardByIndex}
      />
    </div>
  );
};

const DeleteButton = ({
  index,
  removeGeneratedCardByIndex,
  left_credits,
}: {
  index: number;
  removeGeneratedCardByIndex: (index: number) => void;
  left_credits: number;
}) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (step === 1) setStep(0);
    }, 3000);

    return () => clearTimeout(timer);
  }, [step]);

  if (step === 0)
    return (
      <Tooltip content="Delete this generated card">
        <Button
          isDisabled={left_credits === 0}
          onClick={() => setStep(1)}
          isIconOnly
          size="sm"
          variant="light"
          color="danger"
          style={{ color: "gray" }}
        >
          <LuTrash />
        </Button>
      </Tooltip>
    );
  if (step === 1)
    return (
      <Tooltip content="Confirm deletion of this generated card">
        <Button
          isDisabled={left_credits === 0}
          onClick={() => removeGeneratedCardByIndex(index)}
          isIconOnly
          size="sm"
          variant="shadow"
          color="danger"
        >
          <LuTrash />
        </Button>
      </Tooltip>
    );
};
