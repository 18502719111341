import { Subscription } from "@lemonsqueezy/lemonsqueezy.js";
import { useQuery } from "@tanstack/react-query";

import { syncPlans } from "@/shared/services/lemonsqueezy";

export const usePlansQuery = ({
  subscription,
}: {
  subscription: Subscription["data"][];
}) => {
  const { data, error } = useQuery({
    queryKey: ["user-billing", subscription],
    queryFn: async () => syncPlans(),
    enabled: Boolean(subscription),
  });

  return { variants: data ?? null, error };
};
