import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { Dispatch, SetStateAction } from "react";

export default function DeleteModal({
  onConfirm,
  id,
  isModalOpen,
  setIsModalOpen,
  message,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm: (id: any) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  id: any;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  message: string;
}) {
  return (
    <>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>

          <ModalBody>
            <p>{message}</p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              variant="light"
              onPress={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onPress={() => {
                onConfirm(id);
                setIsModalOpen(false);
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
