import { useQuery } from "@tanstack/react-query";

import { fetchCredits } from "../../api/fetchCredits.tsx";
import { QUERY_KEY } from "../../constants.ts";

export const useCreditsQuery = () => {
  const { data, isPending, isLoading, isSuccess, isError, error, refetch } =
    useQuery({
      queryKey: [QUERY_KEY.userCredits],
      queryFn: () => fetchCredits(),
    });

  return {
    data,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  };
};
