import { Button, Divider, Input } from "@nextui-org/react";
import { RefObject } from "react";

// import { useNavigate } from "react-router-dom";
import { PasswordFieldWithVisibilityToggler } from "./PasswordFieldWithVisibilityToggler";
import { SocialProvidersRow } from "./SocialProvidersRow";

type AuthFormProps = {
  inputUsername: RefObject<HTMLInputElement>;
  inputPassword: RefObject<HTMLInputElement>;
  handleSendCredentials: () => void;
  isSubmitting: boolean;
};
export const AuthForm: React.FC<AuthFormProps> = ({
  inputUsername,
  inputPassword,
  handleSendCredentials,
  isSubmitting,
}) => {
  // const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
    >
      <Input type="text" placeholder="Email" ref={inputUsername} />
      <PasswordFieldWithVisibilityToggler
        fieldRef={inputPassword}
        placeholder="Password"
      />
      <Button
        isLoading={isSubmitting}
        isDisabled={isSubmitting}
        onClick={handleSendCredentials}
        color="primary"
      >
        Login with Email
      </Button>
      <Divider />
      <div className="text-center">Or login with social providers</div>
      <SocialProvidersRow />
      {/* <p style={{ textAlign: "center" }}>Do you want to register instead?</p>
      <Button
        variant="light"
        color="primary"
        onClick={() => navigate("/register")}
      >
        Register
      </Button> */}
    </div>
  );
};
