import { Button, Input } from "@nextui-org/react";
import { RefObject, useState } from "react";

export const PasswordFieldWithVisibilityToggler = ({
  fieldRef,
  placeholder,
}: {
  fieldRef: RefObject<HTMLInputElement>;
  placeholder: string;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = (inputRef: RefObject<HTMLInputElement>) => {
    if (inputRef.current!.type === "password") {
      inputRef.current!.type = "text";
    } else {
      inputRef.current!.type = "password";
    }
    setIsVisible(!isVisible);
  };

  return (
    <div
      style={{
        display: "flex",
        gap: 0,
      }}
    >
      <Input
        classNames={{
          inputWrapper: ["rounded-r-none"],
        }}
        style={
          {
            // border: "1px solid black",
            // borderRadius: "8px 0 0 8px",
            // borderRight: "none",
          }
        }
        type="password"
        placeholder={placeholder}
        ref={fieldRef}
      />
      <Button
        onClick={() => toggleVisibility(fieldRef)}
        style={{
          // border: "1px solid black",
          borderRadius: "0 8px 8px 0",
          width: "100px",
        }}
      >
        {isVisible ? "Hide" : "Show"}
      </Button>
    </div>
  );
};
