import { supabase } from "@/providers";

export const deleteCollectionById = async (id: number) => {
  const { data, error } = await supabase
    .from("collections")
    .delete()
    .eq("id", id)
    .maybeSingle();

  if (error) {
    throw new Error(error.message); // Throwing error if deletion fails
  }

  return data;
};
