import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
  useDisclosure,
} from "@nextui-org/react";
import { UseDisclosureReturn } from "@nextui-org/use-disclosure/dist/index.d";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { LuInfo } from "react-icons/lu";
import { toast } from "react-toastify";

import { supabase } from "@/providers";

export const HelperPlugin = () => {
  const modalRequestFeature = useDisclosure();
  const modalFeedback = useDisclosure();
  const modalReportProblem = useDisclosure();

  const postUserMessage = async ({
    message,
    type,
  }: {
    message: string;
    type: "feedback" | "problem" | "request";
  }) => {
    const { data, error } = await supabase
      .from("user_message_board")
      .insert({
        message,
        type,
      })
      .limit(1)
      .single();
    if (error) throw Error("Error posting message");
    return data;
  };

  const postMessageMutation = useMutation({
    mutationFn: postUserMessage,
    mutationKey: ["postMessage"],
  });

  const handlePostMessage = async ({
    message,
    type,
  }: {
    message: string;
    type: "feedback" | "problem" | "request";
  }) => {
    try {
      await postMessageMutation.mutate({ message, type });
      return true;
    } catch (error) {
      console.error("Error posting message", error);
      return false;
    }
  };

  useEffect(() => {
    if (postMessageMutation.isSuccess) {
      toast.success("Message posted successfully");
    }
    if (postMessageMutation.isError) {
      toast.error(
        "Error posting message" + JSON.stringify(postMessageMutation.error),
      );
    }
  }, [
    postMessageMutation.isSuccess,
    postMessageMutation.isError,
    postMessageMutation.error,
  ]);

  return (
    <>
      <div
        id="HELP"
        style={{ left: 20 }}
        className="hidden sm:flex fixed sm:bottom-5"
      >
        <Dropdown>
          <DropdownTrigger>
            <button style={roundBtnStyle}>
              <span style={{ fontSize: 16 }}>
                <LuInfo />
              </span>
            </button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Static Actions"
            onAction={(key) => {
              if (key === "feedback") {
                modalFeedback.onOpen();
              }
              if (key === "request") {
                modalRequestFeature.onOpen();
              }
              if (key === "problem") {
                modalReportProblem.onOpen();
              }
            }}
          >
            {/* <DropdownItem key="faq">FAQ</DropdownItem> */}
            <DropdownItem key="feedback">Share feedback</DropdownItem>
            <DropdownItem key="problem">Report problem</DropdownItem>
            <DropdownItem key="request">Request feature</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <FeedbackModal
        modalHandler={modalFeedback}
        handlePostMessage={handlePostMessage}
      />
      <RequestFeatureModal
        modalHandler={modalRequestFeature}
        handlePostMessage={handlePostMessage}
      />
      <ReportProblemModal
        modalHandler={modalReportProblem}
        handlePostMessage={handlePostMessage}
      />
    </>
  );
};

function RequestFeatureModal({
  modalHandler,
  handlePostMessage,
}: {
  modalHandler: UseDisclosureReturn;
  handlePostMessage: ({
    message,
    type,
  }: {
    message: string;
    type: "feedback" | "problem" | "request";
  }) => Promise<boolean>;
}) {
  const { isOpen, onOpenChange } = modalHandler;
  const [message, setMessage] = useState("");

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Request Feature
              </ModalHeader>

              <ModalBody>
                {/* <p>Features requested by the community.</p>
                  <Card className="flex flex-row gap-2 justify-between p-2 ">
                    <span className="flex gap-2">
                      <span className="bg-gray-800 rounded-full p-1 aspect-square flex justify-center items-center">
                        1
                      </span>
                      <span>Hello</span>
                    </span>
                    <span className="flex gap-2 center">
                      <Button isIconOnly>👍</Button>
                      <Button isIconOnly>👎</Button>
                    </span>
                  </Card>
                 */}
                <Textarea
                  placeholder="Describe your feature request here..."
                  className="mt-4"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={() => {
                    handlePostMessage({ message, type: "request" });
                    onClose();
                  }}
                >
                  Request
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

function FeedbackModal({
  modalHandler,
  handlePostMessage,
}: {
  modalHandler: UseDisclosureReturn;
  handlePostMessage: ({
    message,
    type,
  }: {
    message: string;
    type: "feedback" | "problem" | "request";
  }) => Promise<boolean>;
}) {
  const { isOpen, onOpenChange } = modalHandler;
  const [message, setMessage] = useState("");
  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Send Feedback
              </ModalHeader>
              <ModalBody>
                <Textarea
                  placeholder="Describe your feedback here..."
                  className="mt-4"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={() => {
                    handlePostMessage({ message, type: "feedback" });
                    onClose();
                  }}
                >
                  Send
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

function ReportProblemModal({
  modalHandler,
  handlePostMessage,
}: {
  modalHandler: UseDisclosureReturn;
  handlePostMessage: ({
    message,
    type,
  }: {
    message: string;
    type: "feedback" | "problem" | "request";
  }) => Promise<boolean>;
}) {
  const { isOpen, onOpenChange } = modalHandler;
  const [message, setMessage] = useState("");
  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Report a Problem
              </ModalHeader>
              <ModalBody>
                <Textarea
                  placeholder="Describe the problem here..."
                  className="mt-4"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={() => {
                    handlePostMessage({ message, type: "problem" });
                    onClose();
                  }}
                >
                  Report
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

const roundBtnStyle = {
  cursor: "pointer",
  border: "none",
  borderRadius: 100,
  width: 30,
  height: 30,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#f0f0f050",
  backdropFilter: "blur(10px)",
};
