import { Button } from "@nextui-org/react";
import { Grade } from "ts-fsrs";

import { ratingButtonOptionList } from "@/entities/entities";

import { RatingButtons } from "./RatingButtons";

type RevealAnswerOrRatingButtonsActionsBarProps = {
  isFlipped: boolean;
  handleReview: ({ grade }: { grade: Grade }) => void;
  setIsFlipped: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitting: boolean;
  label?: string;
};
export const RevealAnswerOrRatingButtonsActionsBar: React.FC<
  RevealAnswerOrRatingButtonsActionsBarProps
> = ({
  isFlipped,
  handleReview,
  setIsFlipped,
  isSubmitting,
  label = "See Answer",
}) => {
  return (
    <div style={actionBarStyle} className="gap-1 md:gap-4">
      {isFlipped ? (
        <RatingButtons
          buttons={ratingButtonOptionList}
          handleReview={handleReview}
          isSubmitting={isSubmitting}
          setIsFlipped={setIsFlipped}
        />
      ) : (
        <RevealAnswerButton setIsFlipped={setIsFlipped} label={label} />
      )}
    </div>
  );
};

const actionBarStyle: React.CSSProperties = {
  display: "flex",
  width: "100%",
};

type RevealAnswerButtonProps = {
  setIsFlipped: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
};
export const RevealAnswerButton: React.FC<RevealAnswerButtonProps> = ({
  setIsFlipped,
  label,
}) => {
  return (
    <Button
      onClick={() => setIsFlipped((prev) => !prev)}
      style={{ width: "100%" }}
    >
      {label}
    </Button>
  );
};
