import { Button } from "@nextui-org/react";

type DisplayResultOptionButtonProps = {
  label: string;
  onClick: () => void;
  disabled: boolean;
};
export const DisplayResultOptionButton: React.FC<
  DisplayResultOptionButtonProps
> = ({ label, onClick, disabled }) => {
  return (
    <Button onClick={onClick} disabled={disabled}>
      {label}
    </Button>
  );
};
