import {
  Button,
  Chip,
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
} from "@nextui-org/react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { useUserStore } from "@/entities/sp-user";
import { supabase } from "@/providers";
import { DarkModeContext } from "@/providers/ThemeProvider.tsx";

import { MoonIcon, SunIcon } from "../../../components";
import { useFeatureFlags, useUserSubscriptions } from "../../../hooks/index.ts";
import { useMenuItems } from "./appMenuItems";
import { landingPageMenuItems } from "./landingPageMenuItems";
import { Logo } from "./Logo";
import { NavigationItem } from "./NavigationItem";
import { UserNavigation } from "./UserNavigation";

export const AppNavbar: React.FC = () => {
  const features = useFeatureFlags({ environment: "beta" });
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const { appMenuItems } = useMenuItems();

  const session = useUserStore((state) => state.session);
  const userSubscription = useUserSubscriptions();

  const navigate = useNavigate();
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);

  async function signOut() {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error logging out:", error.message);
      return;
    }
  }

  return (
    <Navbar
      isBordered
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      maxWidth="full"
    >
      <NavbarContent>
        <NavbarBrand
          style={{
            display: "flex",
            gap: 0,
          }}
        >
          <Logo navigate={navigate} />
        </NavbarBrand>
      </NavbarContent>
      {/* Desktop menu */}
      <NavbarContent className={"hidden sm:flex"} justify="end">
        {session === null && (
          <>
            <div>
              {isDarkMode ? (
                <Button
                  isIconOnly
                  variant="light"
                  key="dark"
                  onClick={toggleDarkMode}
                >
                  <MoonIcon />
                </Button>
              ) : (
                <Button
                  isIconOnly
                  variant="light"
                  key="light"
                  onClick={toggleDarkMode}
                >
                  <SunIcon />
                </Button>
              )}
            </div>
          </>
        )}

        {session == null &&
          landingPageMenuItems.map((item) => (
            <NavigationItem key={item.path} navigate={navigate} item={item} />
          ))}

        {session !== null && (
          <>
            {appMenuItems.map((item) => (
              <NavigationItem key={item.path} navigate={navigate} item={item} />
            ))}
            {features?.features.subscription.isEnabled && (
              <>
                {userSubscription ? (
                  <Chip size="sm">
                    {userSubscription[0].attributes.variant_name}
                  </Chip>
                ) : (
                  <Link href="/pricing">
                    <Button size="sm" color="primary">
                      Upgrade
                    </Button>
                  </Link>
                )}
              </>
            )}

            <UserNavigation
              logoutUser={signOut}
              navigate={navigate}
              mobile={false}
            />
          </>
        )}
      </NavbarContent>
      <NavbarContent className="sm:hidden" justify="end">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        />
      </NavbarContent>
      <NavbarMenu>
        {session !== null ? (
          <>
            <>
              {appMenuItems.map((item, index) => (
                <NavbarMenuItem key={`${item}-${index}`}>
                  <Link
                    className="w-full"
                    color="foreground"
                    href={item.path}
                    size="md"
                  >
                    <span className="mr-2">{item.icon}</span> {item.label}
                  </Link>
                </NavbarMenuItem>
              ))}
            </>
            <>
              <div className="mt-5">
                {isDarkMode ? (
                  <Link
                    style={{ color: "inherit" }}
                    className="flex gap-2"
                    key="dark"
                    onClick={toggleDarkMode}
                  >
                    <MoonIcon /> Light mode
                  </Link>
                ) : (
                  <Link
                    style={{ color: "inherit" }}
                    className="flex gap-2"
                    key="light"
                    onClick={toggleDarkMode}
                  >
                    <SunIcon /> Dark mode
                  </Link>
                )}
              </div>
            </>
            <UserNavigation
              mobile={true}
              logoutUser={signOut}
              navigate={navigate}
            />
          </>
        ) : (
          <>
            {landingPageMenuItems.map((item, index) => (
              <NavbarMenuItem key={`${item}-${index}`}>
                <Link
                  className="w-full"
                  color="foreground"
                  href={item.path}
                  size="md"
                >
                  <span className="mr-2">{item.icon}</span> {item.label}
                </Link>
              </NavbarMenuItem>
            ))}
          </>
        )}
      </NavbarMenu>
    </Navbar>
  );
};
