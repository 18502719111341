import { Outlet } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import { useRouteError } from "react-router-dom";

import { useUserStore } from "@/entities/sp-user";
import { ArticlePage } from "@/pages/ArticlePage";
import { BrowsePage } from "@/pages/BrowsePage";
import { Dashboard } from "@/pages/Dashboard/Dashboard";
import {
  CreateArticlePage,
  CreateCardPage,
  GenerateCardsPage,
} from "@/pages/GenerateCardsPage";
import { LandingPage } from "@/pages/LandingPage";
import { Waitlist } from "@/pages/LandingPage/Waitlist";
import { LearnPage } from "@/pages/LearnPage";
import { LoginPage } from "@/pages/LoginPage";
import { PricingPage } from "@/pages/PricingPage/PricingPage";
import { PrivacyTerms } from "@/pages/PrivacyTerms/PrivacyTerms";
import { RegisterPage } from "@/pages/RegisterPage";
import { SpLoginPage } from "@/pages/SpLoginPage";
import { UserProfilePage } from "@/pages/UserProfilePage";
import { WelcomePage } from "@/pages/WelcomePage";
import { AppLayout } from "@/shared/components/AppLayout";
import {
  Container,
  LoadingScreen,
  SarasaCodeSyntaxBlock,
} from "@/shared/components/index.ts";
const ErrorMessage = () => {
  const error = useRouteError();
  const renderedError = error as Error;
  return (
    <div className="mt-5 max-w-full">
      <h1 className="mb-5">{renderedError.message}</h1>
      <SarasaCodeSyntaxBlock>
        {JSON.stringify(renderedError.stack)}
      </SarasaCodeSyntaxBlock>
    </div>
  );
};

const AppLayoutRoute = () => {
  const isWaitlistActive = false;
  if (isWaitlistActive) return <Waitlist />;
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

const RouteGuard = () => {
  const session = useUserStore((state) => state.session);
  const isLoading = useUserStore((state) => state.isLoading);
  if (isLoading) {
    return <LoadingScreen />;
  }

  return session ? <Outlet /> : <LoginPage />;
};

const LpOrAppRouteGuard = () => {
  const session = useUserStore((state) => state.session);
  const isLoading = useUserStore((state) => state.isLoading);
  if (isLoading) {
    return <LoadingScreen />;
  }

  return session ? <Dashboard /> : <LandingPage />;
};

export const AppRouter = createBrowserRouter([
  {
    element: <AppLayoutRoute />,
    errorElement: (
      <AppLayout>
        <Container>
          <h1>No way, an error again?!</h1>
          <ErrorMessage />
        </Container>
      </AppLayout>
    ),
    children: [
      {
        path: "/",
        element: <LpOrAppRouteGuard />,
      },
      {
        path: "/wait-list",
        element: <Waitlist />,
      },
      {
        path: "/pricing",
        element: <PricingPage />,
      },
      {
        path: "/login",
        element: <SpLoginPage />,
      },
      {
        path: "/member-register",
        element: <RegisterPage />,
      },
      {
        path: "/privacy-terms",
        element: <PrivacyTerms />,
      },

      {
        element: <RouteGuard />,
        children: [
          {
            path: "/",
            element: <LpOrAppRouteGuard />,
          },
          {
            path: "/welcome",
            element: <WelcomePage />,
          },
          {
            path: "/dashboard",
            element: <Dashboard />,
          },
          {
            path: "/deck/create",
            element: <CreateCardPage />,
          },
          {
            path: "/deck/generate",
            element: <GenerateCardsPage />,
          },
          {
            path: "/deck/create-article",
            element: <CreateArticlePage />,
          },
          {
            path: "/deck/browse",
            element: <BrowsePage />,
          },
          {
            path: "/deck/browse/article/:id",
            element: <ArticlePage />,
          },
          {
            path: "/deck/learn",
            element: <LearnPage />,
          },
          {
            path: "/profile",
            element: <UserProfilePage />,
          },
        ],
      },
    ],
  },
]);
