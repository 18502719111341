import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";
import { useState } from "react";

// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { CreateArticlePage } from "@/pages/GenerateCardsPage/CreateArticlePage";

export default function CreateArticleModal() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  useState<string>("");

  return (
    <>
      <Button
        onPress={onOpen}
        style={{ height: "inherit", padding: "10px 30px" }}
      >
        + New Article
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <CreateArticlePage />
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
