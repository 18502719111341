import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Card, createEmptyCard, Grade, ReviewLog } from "ts-fsrs";

import { useReviewLogs } from "@/entities/reviewLog/index.ts";
import { createNewReviewLog } from "@/shared/api/index.ts";
import { LoadingScreen } from "@/shared/components/index.ts";
import { useCardStatsCreationMutation } from "@/shared/hooks";
import { useCardStatsUpdateMutation } from "@/shared/hooks";

import {
  useAlgorithm,
  useLearningSessionTiming,
  useNoteListByQuestionIdListQuery,
  useSessionCards,
  useSnippetListByQuestionIdListQuery,
} from "../hooks/index.ts";
import {
  KnowledgeFragment,
  LearnPageStep,
  SelectedKnowledgeFragment,
} from "../LearnPage.tsx";
import { handleScheduleNextReview } from "../utils/handleScheduleNextReview.ts";
import { CompletedPage } from "./CompletedPage.tsx";
import { CurrentCardView } from "./CurrentCardView.tsx";

type LearningSessionProps = {
  knowledgeTypedReferenceList: SelectedKnowledgeFragment[];
  setView: (view: LearnPageStep) => void;
};

export const LearningSession: React.FC<LearningSessionProps> = ({
  knowledgeTypedReferenceList,
  setView,
}) => {
  const { f } = useAlgorithm();
  const { learningSessionStartTime, learningSessionEndTime } =
    useLearningSessionTiming();

  const { mutate: mutateCardStatsCreation, data: mutatedCardStats } =
    useCardStatsCreationMutation();
  const { mutate: mutateCardStatsUpdate } = useCardStatsUpdateMutation();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // TODO JV: Refactor questionIdList to only get limited number of NEW notes inside the session as set by the user
  const { selectedNotes, isSuccess, isLoading, isPending } =
    useNoteListByQuestionIdListQuery(
      knowledgeTypedReferenceList
        .filter((item) => item.type === "note")
        .map((item) => item.id),
    );
  const {
    selectedSnippets,
    isSuccess: isSnippetListSuccess,
    isLoading: isSnippetListLoading,
    isPending: isSnippetListPending,
  } = useSnippetListByQuestionIdListQuery(
    knowledgeTypedReferenceList
      .filter((item) => item.type === "snippet")
      .map((item) => item.id),
  );

  const selectedKnowledgeFragmentList = useMemo(
    () => [...(selectedNotes ?? []), ...(selectedSnippets ?? [])],
    [selectedNotes, selectedSnippets],
  );

  // This gets all existing reviewLogs inside the application
  // TODO JV: Refactor this to only get the reviewLogs for the current learning session
  const { reviewLogs } = useReviewLogs();

  const {
    leftSessionCards,
    currentCardIndex,
    setLeftSessionCards,
    setCurrentCardIndex,
    isSessionCardsInitial,
  } = useSessionCards(selectedKnowledgeFragmentList);

  useEffect(() => {
    if (!selectedKnowledgeFragmentList) {
      return;
    } else {
      setLeftSessionCards(selectedKnowledgeFragmentList);
    }
  }, [
    knowledgeTypedReferenceList,
    selectedKnowledgeFragmentList,
    setLeftSessionCards,
  ]);

  const handleSchedule = async ({ grade }: { grade: Grade }) => {
    const currentCard = leftSessionCards[currentCardIndex];
    const cardToSchedule =
      currentCard.cards && currentCard.cards.due !== null
        ? currentCard.cards
        : createEmptyCard(new Date());

    setIsSubmitting(true);

    try {
      const { newScheduledCard, newReviewLog } = await handleScheduleNextReview(
        {
          f,
          card: cardToSchedule,
          grade,
        },
      );

      const newScheduledCardId = await saveScheduledCard(
        newScheduledCard,
        newReviewLog,
        currentCard,
      );

      const updatedLeftSessionCards = updateLeftSessionCards(
        newScheduledCardId,
        newScheduledCard,
      );

      setLeftSessionCards(updatedLeftSessionCards);
      setCurrentCardIndex(
        (prevIndex) => (prevIndex + 1) % updatedLeftSessionCards.length,
      );
    } catch (error) {
      toast.error(`Error in scheduler. Details: ${(error as Error).message}.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const saveScheduledCard = async (
    newScheduledCard: Card,
    newReviewLog: ReviewLog,
    currentCard: KnowledgeFragment,
  ) => {
    let newScheduledCardId = currentCard.cards?.id;

    if (newScheduledCardId) {
      await mutateCardStatsUpdate({
        card: newScheduledCard,
        id: newScheduledCardId,
      });
      await createNewReviewLog(
        newReviewLog,
        newScheduledCardId,
        currentCard.id,
      );
    } else {
      await mutateCardStatsCreation(newScheduledCard);
      newScheduledCardId = mutatedCardStats?.data?.id ?? "";
      if (!newScheduledCardId) throw new Error("No card id returned.");
      await createNewReviewLog(
        newReviewLog,
        newScheduledCardId,
        currentCard.id,
      );
    }

    return newScheduledCardId;
  };

  const updateLeftSessionCards = (
    newScheduledCardId: number,
    newScheduledCard: Card,
  ) => {
    return leftSessionCards
      .map((card, index) =>
        index === currentCardIndex
          ? { ...card, cards: { id: newScheduledCardId, ...newScheduledCard } }
          : card,
      )
      .filter(
        (card) =>
          !card.cards?.due || new Date(card.cards.due) < learningSessionEndTime,
      );
  };

  if (
    isLoading ||
    isPending ||
    isSnippetListLoading ||
    isSnippetListPending ||
    isSessionCardsInitial
  )
    return <LoadingScreen />;

  if (!selectedKnowledgeFragmentList) return null;

  if (isSuccess && isSnippetListSuccess) {
    return (
      <>
        {selectedKnowledgeFragmentList &&
        leftSessionCards &&
        leftSessionCards.length > 0 ? (
          <CurrentCardView
            isSubmitting={isSubmitting}
            allSessionCards={selectedKnowledgeFragmentList ?? []}
            leftSessionCards={leftSessionCards}
            index={currentCardIndex}
            handleReview={handleSchedule}
            reviewLogs={reviewLogs}
            startTime={learningSessionStartTime}
          />
        ) : (
          <CompletedPage setView={setView} />
        )}
      </>
    );
  }
};
