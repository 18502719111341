import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createNote } from "../../api";
import { QUERY_KEY } from "../../constants";
import { Note } from "../../types";

const MUTATION_KEY = "CREATE_NOTE";
export const useNoteCreationMutation = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isError, isSuccess } = useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: (note: Partial<Note>) => createNote(note),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.notes] });
    },
  });
  return { mutate, isPending, isError, isSuccess };
};
