import { useQuery } from "@tanstack/react-query";

import { fetchSnippetsList } from "../../api/fetchSnippetsList";
import { QUERY_KEY } from "../../constants";

export const useSnippetsListQuery = () => {
  const {
    data: snippets,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useQuery({
    queryKey: [QUERY_KEY.snippets],
    queryFn: fetchSnippetsList,
  });

  return {
    snippets,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
