import { supabase } from "@/providers";

export const getArticleTitleNormalizedList = async () => {
  const { data, error } = await supabase.from("sources").select("id, ai_title");

  if (error) {
    console.log(`Error fetching collections: ${error.message}`);
  }

  return { data, error };
};
