import { Container } from "@/shared/components/index.ts";

import { CreateCardWidget } from "./CreateCardWidget";

export const CreateCardPage = () => {
  return (
    <Container>
      <div className="flex flex-col gap-6" style={{ width: "100%" }}>
        <h1>Create your own card</h1>
        <CreateCardWidget />
      </div>
    </Container>
  );
};
