import { motion } from "framer-motion";

import { useUserStore } from "@/entities/sp-user";
import { useAvatarInstanceUrl } from "@/shared/utils";

export const Avatar: React.FC = () => {
  const url = useAvatarInstanceUrl();
  const user = useUserStore((state) => state.session?.user);

  return user && url ? (
    <motion.img src={url} style={avatarStyle} {...avatarAnimationConfig} />
  ) : (
    <motion.div style={avatarPlaceholderStyle} {...avatarAnimationConfig}>
      <div style={avatarPlaceholderTextStyle}>
        {(user?.email && user?.email[0].toUpperCase()) ?? "S"}
      </div>
    </motion.div>
  );
};

export const SmallAvatar: React.FC = () => {
  const url = useAvatarInstanceUrl();
  const user = useUserStore((state) => state.session?.user);

  return url ? (
    <img src={url} style={avatarStyle} {...avatarAnimationConfig} />
  ) : (
    <div style={{ ...avatarPlaceholderStyle, width: 24, height: 24 }}>
      <div style={{ ...avatarPlaceholderTextStyle, fontSize: 12 }}>
        {(user?.email && user?.email[0].toUpperCase()) ?? "S"}
      </div>
    </div>
  );
};

const avatarAnimationConfig = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.75, delay: 0.5 },
};
const avatarStyle: React.CSSProperties = {
  borderRadius: 200,
  width: 24,
  height: 24,
};
const avatarPlaceholderStyle: React.CSSProperties = {
  ...avatarStyle,
  backgroundColor: "lightgray",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const avatarPlaceholderTextStyle: React.CSSProperties = {
  fontSize: 100,
  color: "white",
};
