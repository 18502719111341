import { supabase } from "@/providers";

export const deleteCardById = async (id: number) => {
  const { data, error } = await supabase.from("notes").delete().eq("id", id);
  if (data) {
    alert("Sucessfully deleted. Refresh view to see changes.");
    // TODO JV:
  }
  if (error) {
    alert(`Sorry, this action has failed. Reason: ${error.message}`);
  }
};
