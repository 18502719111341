import { ReactElement } from "react";

type IconWrapperProps = {
  children: ReactElement[] | ReactElement;
};
const IconWrapper: React.FC<IconWrapperProps> = ({ children }) => {
  return (
    <svg
      style={{
        minWidth: 15,
        maxHeight: 24,
      }}
      width="100%"
      height="100%"
      viewBox="0 0 23 23"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};

export const GoogleIcon = () => {
  return (
    <IconWrapper>
      <path
        d="M11.4998 9.40918V13.8628H17.6889C17.4171 15.2951 16.6016 16.5079 15.3784 17.3233L19.1107 20.2192C21.2852 18.212 22.5398 15.2638 22.5398 11.7616C22.5398 10.9461 22.4666 10.162 22.3307 9.4093L11.4998 9.40918Z"
        fill="currentColor"
      />
      <path
        d="M5.0549 13.6885L4.21313 14.3328L1.23354 16.6537C3.1258 20.4069 7.00415 22.9996 11.4996 22.9996C14.6045 22.9996 17.2076 21.9751 19.1104 20.2188L15.3782 17.3228C14.3536 18.0128 13.0468 18.431 11.4996 18.431C8.50959 18.431 5.96921 16.4133 5.0596 13.6951L5.0549 13.6885Z"
        fill="currentColor"
      />
      <path
        d="M1.23355 6.3457C0.449499 7.89291 0 9.63885 0 11.4997C0 13.3606 0.449499 15.1065 1.23355 16.6537C1.23355 16.6641 5.05998 13.6847 5.05998 13.6847C4.82998 12.9947 4.69403 12.2629 4.69403 11.4996C4.69403 10.7363 4.82998 10.0046 5.05998 9.31455L1.23355 6.3457Z"
        fill="currentColor"
      />
      <path
        d="M11.4998 4.57909C13.1935 4.57909 14.6989 5.16453 15.9012 6.29363L19.1943 3.00049C17.1975 1.13962 14.6049 0 11.4998 0C7.00439 0 3.1258 2.58227 1.23354 6.34592L5.05985 9.31501C5.96934 6.5968 8.50982 4.57909 11.4998 4.57909Z"
        fill="currentColor"
      />
    </IconWrapper>
  );
};
export const AppleIcon = () => {
  return (
    <IconWrapper>
      <path
        d="M18.6018 17.924C18.2607 18.7275 17.8569 19.4672 17.3891 20.1473C16.7514 21.0743 16.2292 21.716 15.8268 22.0724C15.2031 22.6573 14.5347 22.9569 13.8191 22.9739C13.3053 22.9739 12.6857 22.8248 11.9645 22.5224C11.2409 22.2214 10.5759 22.0724 9.96783 22.0724C9.33013 22.0724 8.64621 22.2214 7.91467 22.5224C7.18202 22.8248 6.5918 22.9824 6.14054 22.998C5.45425 23.0278 4.77019 22.7198 4.08738 22.0724C3.65158 21.6848 3.10647 21.0204 2.45346 20.0791C1.75283 19.0739 1.17681 17.9084 0.725553 16.5795C0.242269 15.1442 0 13.7543 0 12.4087C0 10.8673 0.326645 9.5379 0.98091 8.42385C1.4951 7.52901 2.17917 6.82313 3.03532 6.30493C3.89148 5.78674 4.81655 5.52268 5.81278 5.50578C6.35789 5.50578 7.07272 5.67771 7.96104 6.0156C8.84685 6.35463 9.41562 6.52655 9.66499 6.52655C9.85143 6.52655 10.4833 6.32552 11.5544 5.92474C12.5673 5.55306 13.4222 5.39916 14.1226 5.45978C16.0204 5.61595 17.4461 6.37876 18.3943 7.75304C16.6971 8.80164 15.8575 10.2703 15.8742 12.1544C15.8895 13.622 16.4116 14.8432 17.4378 15.8129C17.9028 16.2629 18.4222 16.6108 19 16.8578C18.8747 17.2283 18.7424 17.5833 18.6018 17.924V17.924ZM14.2493 0.460131C14.2493 1.61039 13.8372 2.68439 13.0157 3.67847C12.0243 4.86024 10.8252 5.54312 9.52492 5.43536C9.50835 5.29737 9.49875 5.15213 9.49875 4.99951C9.49875 3.89526 9.9702 2.71349 10.8074 1.74724C11.2254 1.25801 11.757 0.851218 12.4017 0.526715C13.0449 0.207053 13.6534 0.0302728 14.2256 0C14.2423 0.153772 14.2493 0.307554 14.2493 0.460116V0.460131Z"
        fill="currentColor"
      />
    </IconWrapper>
  );
};
export const GitHubIcon = () => {
  return (
    <IconWrapper>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5095 0C5.14505 0 0 5.04165 0 11.2788C0 16.2646 3.29661 20.4849 7.86988 21.9786C8.44166 22.0909 8.6511 21.7359 8.6511 21.4373C8.6511 21.1758 8.63225 20.2796 8.63225 19.3457C5.43059 20.0181 4.76387 18.0012 4.76387 18.0012C4.24934 16.694 3.48697 16.3581 3.48697 16.3581C2.43906 15.6671 3.5633 15.6671 3.5633 15.6671C4.7257 15.7419 5.33564 16.8249 5.33564 16.8249C6.36446 18.5427 8.02231 18.0573 8.68927 17.7585C8.78445 17.0302 9.08953 16.5261 9.41347 16.246C6.85991 15.9845 4.17324 15.0136 4.17324 10.6812C4.17324 9.44873 4.63029 8.4404 5.35449 7.6562C5.24023 7.37616 4.83996 6.21818 5.46899 4.66833C5.46899 4.66833 6.4408 4.3695 8.63202 5.82608C9.57016 5.57919 10.5376 5.4536 11.5095 5.45254C12.4813 5.45254 13.472 5.58339 14.3868 5.82608C16.5782 4.3695 17.55 4.66833 17.55 4.66833C18.1791 6.21818 17.7786 7.37616 17.6643 7.6562C18.4076 8.4404 18.8458 9.44873 18.8458 10.6812C18.8458 15.0136 16.1591 15.9657 13.5865 16.246C14.0058 16.6008 14.3677 17.2729 14.3677 18.3374C14.3677 19.8499 14.3488 21.0638 14.3488 21.4371C14.3488 21.7359 14.5585 22.0909 15.1301 21.9788C19.7033 20.4847 22.9999 16.2646 22.9999 11.2788C23.0188 5.04165 17.8549 0 11.5095 0Z"
        fill="currentColor"
      />
    </IconWrapper>
  );
};
export const FacebookIcon = () => {
  return (
    <IconWrapper>
      <path
        d="M11.4998 0C5.14868 0 0 4.94301 0 11.0404C0 16.2179 3.71305 20.5625 8.72189 21.7558V14.4144H6.35063V11.0404H8.72189V9.58661C8.72189 5.82889 10.4933 4.08716 14.3361 4.08716C15.0647 4.08716 16.3219 4.2245 16.8361 4.3614V7.4196C16.5647 7.39221 16.0932 7.37853 15.5077 7.37853C13.6222 7.37853 12.8935 8.06435 12.8935 9.84716V11.0404H16.6498L16.0045 14.4144H12.8935V22C18.5878 21.3398 23 16.6851 23 11.0404C22.9995 4.94301 17.8509 0 11.4998 0Z"
        fill="currentColor"
      />
    </IconWrapper>
  );
};
export const LinkedInIcon = () => {
  return (
    <IconWrapper>
      <path
        d="M21.2975 0H1.69805C0.75918 0 0 0.708984 0 1.58555V20.4102C0 21.2867 0.75918 22 1.69805 22H21.2975C22.2363 22 23 21.2867 23 20.4145V1.58555C23 0.708984 22.2363 0 21.2975 0ZM6.82363 18.7473H3.40957V8.2457H6.82363V18.7473ZM5.1166 6.81484C4.02051 6.81484 3.13555 5.96836 3.13555 4.92422C3.13555 3.88008 4.02051 3.03359 5.1166 3.03359C6.2082 3.03359 7.09316 3.88008 7.09316 4.92422C7.09316 5.96406 6.2082 6.81484 5.1166 6.81484ZM19.5994 18.7473H16.1898V13.6426C16.1898 12.4266 16.1674 10.8582 14.4154 10.8582C12.641 10.8582 12.3715 12.1859 12.3715 13.5566V18.7473H8.96641V8.2457H12.2367V9.68086H12.2816C12.7354 8.85586 13.8494 7.98359 15.507 7.98359C18.9615 7.98359 19.5994 10.1578 19.5994 12.9852V18.7473V18.7473Z"
        fill="currentColor"
      />
    </IconWrapper>
  );
};
