import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Card as FSRSCard } from "ts-fsrs";

import { insertNewCard } from "../../api";
import { QUERY_KEY } from "../../constants";

const MUTATION_KEY = "CREATE_CARD_STATS";
export const useCardStatsCreationMutation = () => {
  const queryClient = useQueryClient();

  const { mutate, data, isPending, isError, isSuccess } = useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: (card: FSRSCard) => insertNewCard(card),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.notes] });
    },
  });
  return { mutate, data, isPending, isError, isSuccess };
};
