import "react-toastify/dist/ReactToastify.css";

import { Button, Card, Chip, Input, Link, Progress } from "@nextui-org/react";
import dayjs from "dayjs";
import { FormEvent, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useUserStore } from "@/entities/sp-user";
import { supabase } from "@/providers";
import { DarkModeContext } from "@/providers/ThemeProvider.tsx";
import { MoonIcon, SunIcon } from "@/shared/components";
import { SarasaCodeSyntaxBlock } from "@/shared/components";
import { Container } from "@/shared/components/index.ts";
import {
  useFeatureFlags,
  usePermissionsFlags,
  useUserProfileUpdateMutation,
  useUserSubscriptions,
} from "@/shared/hooks/index.ts";
import { useCreditsQuery } from "@/shared/hooks/queries/useCreditsQuery";

import { useUserProfileByIdQuery } from "./hooks";

const subscriptionStatusMap = {
  on_trial: "On Trial",
  active: "Active",
  paused: "Paused",
  pause: "Pause",
  past_due: "Past Due",
  unpaid: "Unpaid",
  cancelled: "Cancelled",
  expired: "Expired",
};

export function UserProfilePage() {
  const features = useFeatureFlags({ environment: "beta" });
  const { permissions } = usePermissionsFlags();

  const session = useUserStore((state) => state.session);

  const subscription = useUserSubscriptions();

  const { data: userProfile, error: getUserProfileError } =
    useUserProfileByIdQuery(session?.user?.id ?? null);

  const [loading, setLoading] = useState<boolean>(true);

  const [username, setUsername] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [avatarUrl, setAvatarUrl] = useState<string>("");

  const {
    mutate: mutateProfileUpdate,
    isSuccess,
    isError,
  } = useUserProfileUpdateMutation();

  const { data: credits, isPending } = useCreditsQuery();
  useEffect(() => {
    getUserProfileError && toast.error(getUserProfileError.message);
  }, [getUserProfileError]);

  useEffect(() => {
    if (isSuccess) toast.success("Updated successfully.");
    if (isError) toast.error("Action has failed.");
  }, [isSuccess, isError]);

  useEffect(() => {
    if (userProfile) {
      setUsername(userProfile.username);
      setWebsite(userProfile.website);
      setAvatarUrl(userProfile.avatar_url);

      setLoading(false);
    } else setLoading(false);
  }, [userProfile]);

  async function updateProfile(
    event: FormEvent<HTMLFormElement>,
    avatarUrl: string,
  ) {
    event.preventDefault();

    setLoading(true);

    if (!session) return;

    const { user } = session;

    const updateProfile = {
      id: user.id,
      username,
      website,
      avatar_url: avatarUrl,
      updated_at: new Date().toString(),
    };

    // TODO JV: Allow RLS
    await mutateProfileUpdate({ profile: updateProfile });

    if (isSuccess) {
      setAvatarUrl(avatarUrl);
    }

    setLoading(false);
  }

  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);

  return (
    <Container>
      {/* <pre>{JSON.stringify(credits, null, 2)}</pre> */}

      <div className="flex flex-col gap-5">
        <h1>Profile</h1>
        {!isPending && (
          <>
            <p>Monthly credits</p>
            <div className="flex justify-between">
              <Progress
                aria-label="Loading..."
                value={
                  (credits.used_credits / credits.monthly_credit_limit) * 100
                }
                className="max-w-md"
              />
              <p>
                Used up {credits.used_credits} / {credits.monthly_credit_limit}{" "}
                of credits
              </p>
            </div>
          </>
        )}
        <p>View and manage your profile information</p>
        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
          onSubmit={(event) => updateProfile(event, avatarUrl)}
          className="form-widget"
        >
          {/* <Avatar style={{ width: 200, height: 200 }} src={"url"} /> */}
          {session && (
            <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
              <Input
                label="Email"
                id="email"
                type="text"
                value={session.user.email}
                isDisabled
              />
            </div>
          )}
          <div>
            <Input
              label="Username"
              id="username"
              type="text"
              required
              value={username || ""}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="flex gap-4">
            <Button
              className="button block primary"
              type="submit"
              disabled={loading}
            >
              {loading ? "Loading ..." : "Update"}
            </Button>
          </div>
        </form>
      </div>

      <div className="flex flex-col gap-5  mt-20">
        <h1>Touch and feel</h1>
        <p>Manage dark mode</p>
        {isDarkMode ? (
          <Button key="dark" onClick={toggleDarkMode}>
            <MoonIcon />
          </Button>
        ) : (
          <Button key="light" onClick={toggleDarkMode}>
            <SunIcon />
          </Button>
        )}
      </div>

      {features?.features.subscription.isEnabled && (
        <div className="flex flex-col gap-5  mt-20">
          <h1>Billing</h1>
          <p>View and manage your billing information</p>
          {subscription && subscription?.length > 0 && (
            <div>
              {subscription.map((sub) => (
                <Card key={sub.id} className="p-5 flex flex-col gap-5">
                  <h2 className="text-xl">
                    {sub.attributes.product_name} ({sub.attributes.variant_name}
                    ){" "}
                    <Chip color="primary" size="md">
                      {subscriptionStatusMap[sub.attributes.status]}
                    </Chip>
                  </h2>

                  <ul>
                    {/* <pre>{JSON.stringify(sub.attributes, null, 2)}</pre> */}

                    <li>
                      Trial ends at{" "}
                      {dayjs(sub.attributes.trial_ends_at).toString()}
                    </li>
                    <li>
                      Renews at {dayjs(sub.attributes.renews_at).toString()}
                    </li>
                    {/* <pre>{JSON.stringify(sub.attributes.urls, null, 2)}</pre> */}
                  </ul>
                  {sub.attributes.urls.customer_portal && (
                    <Link
                      showAnchorIcon={true}
                      isExternal={true}
                      href={sub.attributes.urls.customer_portal}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Manage subscription
                    </Link>
                  )}
                  {/* {sub.attributes.urls.customer_portal_update_subscription && (
                  <Link
                    showAnchorIcon={true}
                    isExternal={true}
                    href={
                      sub.attributes.urls.customer_portal_update_subscription
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Update subscription
                  </Link>
                )}
                {sub.attributes.urls.update_payment_method && (
                  <Link
                    showAnchorIcon={true}
                    isExternal={true}
                    href={sub.attributes.urls.update_payment_method}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Update payment method
                  </Link>
                )} */}
                </Card>
              ))}
            </div>
          )}
        </div>
      )}

      {features?.features?.credits?.isEnabled && (
        <>
          <div className="flex flex-col gap-5  mt-20">
            <h1>Allowed features</h1>
            <p>View your allowed features</p>
            <SarasaCodeSyntaxBlock>
              {JSON.stringify(features, null, 2)}
            </SarasaCodeSyntaxBlock>
          </div>

          <div className="flex flex-col gap-5  mt-20">
            <h1>Allowed permissions</h1>
            <p>View your allowed permissions</p>
            <SarasaCodeSyntaxBlock>
              {JSON.stringify(permissions, null, 2)}
            </SarasaCodeSyntaxBlock>
          </div>
        </>
      )}

      <div className="flex flex-col gap-5  mt-20">
        <h1>Sign out</h1>
        <p>View and manage your billing information</p>

        <Button
          className="button block"
          type="button"
          onClick={() => supabase.auth.signOut()}
        >
          Sign Out
        </Button>
      </div>
    </Container>
  );
}
