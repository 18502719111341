import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { Card } from "ts-fsrs";

import { formatDate } from "@/shared/utils/formatDate";
import { formatToInteger } from "@/shared/utils/formatToInteger";

import { KnowledgeFragment } from "../LearnPage";

type CardHistoryProps = {
  card: Card;
  item: KnowledgeFragment;
};
export const CardHistory: React.FC<CardHistoryProps> = ({ item }) => {
  return (
    <div>
      <Table isStriped aria-label="Card history table">
        <TableHeader>
          <TableColumn>Due</TableColumn>
          <TableColumn>Stability</TableColumn>
          <TableColumn>Difficulty</TableColumn>
          <TableColumn>Elapsed</TableColumn>
          <TableColumn>Scheduled</TableColumn>
          <TableColumn>Reps</TableColumn>
          <TableColumn>Lapses</TableColumn>
          <TableColumn>State</TableColumn>
          <TableColumn>Last Review</TableColumn>
        </TableHeader>
        <TableBody>
          <TableRow key="B">
            <TableCell>
              {item?.cards?.due ? formatDate(item?.cards?.due.toString()) : "-"}
            </TableCell>
            <TableCell>
              {item?.cards?.stability &&
                formatToInteger(item?.cards?.stability)}
            </TableCell>
            <TableCell>
              {item?.cards?.difficulty &&
                formatToInteger(item?.cards?.difficulty)}
            </TableCell>
            <TableCell>
              {item?.cards?.elapsed_days &&
                formatToInteger(item?.cards?.elapsed_days)}
            </TableCell>
            <TableCell>
              {item?.cards?.scheduled_days
                ? item?.cards?.scheduled_days.toString()
                : "-"}
            </TableCell>
            <TableCell>{item?.cards?.reps}</TableCell>
            <TableCell>{item?.cards?.lapses}</TableCell>
            <TableCell>{item?.cards?.state}</TableCell>
            <TableCell>
              {item?.cards?.last_review
                ? formatDate(item?.cards?.last_review.toString())
                : "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
