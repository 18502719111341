import { Button, Modal, ModalContent, ModalFooter } from "@nextui-org/react";
import { Dispatch, SetStateAction } from "react";

import { EditArticleWidget } from "@/features/EditArticleWidget";

export default function EditArticleModal({
  id,
  isModalOpen,
  setIsModalOpen,
}: {
  id: string;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContent>
          <EditArticleWidget id={id} />
          <ModalFooter>
            <Button
              color="danger"
              variant="light"
              onPress={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
