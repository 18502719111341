import { createContext } from "react";

import { useDarkMode } from "@/shared/hooks";

export const DarkModeContext = createContext({
  isDarkMode: false,
  toggleDarkMode: () => {},
});

type ThemeProviderProps = {
  children: React.ReactNode;
};
export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [isDarkMode, toggleDarkMode] = useDarkMode();

  const darkThemeStyle = "dark text-foreground bg-background";
  const lightThemeStyle = "light text-foreground bg-background";
  const style = !isDarkMode ? lightThemeStyle : darkThemeStyle;

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      <main className={style}>{children}</main>
    </DarkModeContext.Provider>
  );
};
