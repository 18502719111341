import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createSnippet } from "../../api/createSnippet";
import { QUERY_KEY } from "../../constants";
import { Note } from "../../types";

const MUTATION_KEY = "CREATE_SNIPPET";
export const useSnippetCreationMutation = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isError, isSuccess } = useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: (note: Partial<Note>) => createSnippet(note),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.snippets] });
    },
  });
  return { mutate, isPending, isError, isSuccess };
};
