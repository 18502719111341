import { supabase } from "@/providers";

export const getUserProfileById = async (id: string) => {
  const { data, error } = await supabase
    .from("profiles")
    .select(`*`)
    .eq("id", id)
    .single();

  return { data, error };
};
