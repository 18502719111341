import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateNote } from "../../api";
import { QUERY_KEY } from "../../constants";
import { Note } from "../../types";

const MUTATION_KEY = "UPDATE_NOTE";
export const useNoteUpdateMutation = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isError, isSuccess } = useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: ({ note, id }: { note: Partial<Note>; id: number }) =>
      updateNote(note, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.notes] });
    },
  });
  return { mutate, isPending, isError, isSuccess };
};
