import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Link,
} from "@nextui-org/react";
import { LuCog, LuLogOut } from "react-icons/lu";

// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { useUserStore } from "@/entities/sp-user";

import { useFeatureFlags } from "../../../hooks/index.ts";
import { useAvatarInstanceUrl } from "../../../utils";

export function UserNavigation({
  logoutUser,
  navigate,
  mobile,
}: {
  logoutUser: () => void;
  navigate: (path: string) => void;
  mobile: boolean;
}) {
  const features = useFeatureFlags({ environment: "beta" });

  const url = useAvatarInstanceUrl() ?? "";
  const user = useUserStore((state) => state.session?.user);

  return (
    <div
      key={"user-menu"}
      style={{
        whiteSpace: "nowrap",
        minWidth: 32,
        cursor: "pointer",
        display: "flex",
        gap: 8,
      }}
    >
      {!mobile ? (
        <Dropdown placement="bottom-end">
          <DropdownTrigger>
            <Avatar
              isBordered
              as="button"
              className="transition-transform"
              src={url}
              fallback={null}
            />
          </DropdownTrigger>
          <DropdownMenu aria-label="Profile Actions" variant="flat">
            <DropdownItem
              key="profile"
              className="h-14 gap-2"
              textValue="Signed in"
            >
              <p className="font-semibold">Signed in as</p>
              <p className="font-semibold">{user?.email}</p>
            </DropdownItem>
            <DropdownItem
              key="settings"
              onClick={() => {
                navigate("/profile");
              }}
            >
              My Settings{" "}
              {features?.features.subscription.isEnabled && "and Billing"}
            </DropdownItem>
            <DropdownItem
              key="logout"
              color="danger"
              onClick={logoutUser}
              textValue="Sign out"
            >
              Log Out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <div className="flex flex-col gap-2 mt-5">
          <p className="font-semibold">{user?.email}</p>
          <Link
            key="settings"
            onClick={() => {
              navigate("/profile");
            }}
            className="flex gap-2"
            style={{ color: "inherit" }}
          >
            <LuCog /> My Settings{" "}
            {features?.features.subscription.isEnabled && "and Billing"}
          </Link>
          <Link
            key="logout"
            color="danger"
            onClick={logoutUser}
            className="flex gap-2"
          >
            <LuLogOut /> Log Out
          </Link>
        </div>
      )}
    </div>
  );
}
