import { supabase } from "@/providers";

import { Note } from "../types";

export const fetchNoteListByQuestionIdList = async (
  questionIdList: Note["id"][],
): Promise<Note[]> => {
  const { data, error } = await supabase
    .from("notes")
    .select(`*, cards:card_id(*), logs:card_id(review_logs(*))`)
    .in("id", questionIdList);

  if (error) {
    const unifiedErrorMessage = `Error fetching note by Id. Reason: ${error.message}`;
    console.error(unifiedErrorMessage);
    throw new Error(unifiedErrorMessage);
  }

  return data ?? [];
};
