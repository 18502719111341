import { Chip } from "@nextui-org/react";
import { Grade, State } from "ts-fsrs";

import { ReviewLog } from "@/shared/types/coreDbTypes";
import { isNote, isSnippet } from "@/shared/utils/typeGuards.ts";

import { KnowledgeFragment } from "../LearnPage.tsx";
import { CardItem } from "./CardItem.tsx";
import { SnippetItem } from "./SnippetItem.tsx";

type CurrentCardViewProps = {
  leftSessionCards: KnowledgeFragment[];
  index: number;
  handleReview: ({ grade }: { grade: Grade }) => void;
  reviewLogs: ReviewLog[] | null | undefined;
  startTime: Date;
  allSessionCards: KnowledgeFragment[];
  isSubmitting: boolean;
};
export const CurrentCardView: React.FC<CurrentCardViewProps> = ({
  leftSessionCards,
  index,
  handleReview,
  reviewLogs,
  startTime,
  allSessionCards,
  isSubmitting,
}) => {
  const cardsLeftInSession = leftSessionCards;
  console.log("Starting session: ", startTime);

  const CurrentNoteId = () => {
    return <Chip>Id {cardsLeftInSession[index]["id"]}</Chip>;
  };
  const CurrentNoteState = () => {
    return (
      <Chip>
        {cardsLeftInSession &&
        cardsLeftInSession[index] &&
        cardsLeftInSession[index]?.cards !== null
          ? State[cardsLeftInSession[index]!.cards!.state]
          : "No state"}
      </Chip>
    );
  };

  const CurrentCardTitle = () => {
    return (
      <>
        <h1 className="flex gap-2">Current note</h1>
        <div className="flex gap-1">
          <CurrentNoteId />
          <CurrentNoteState />
        </div>
      </>
    );
  };

  const currentCardReviewLogList =
    reviewLogs?.filter(
      (note) => note.card_id === cardsLeftInSession[index].card_id,
    ) ?? [];

  if (isNote(cardsLeftInSession[index])) {
    return (
      <div>
        <div className="flex flex-col gap-6" style={{ width: "100%" }}>
          <CurrentCardTitle />
          <CardItem
            isSubmitting={isSubmitting}
            item={cardsLeftInSession[index]}
            handleReview={handleReview}
            revlogs={currentCardReviewLogList}
            cardsLeftInSession={cardsLeftInSession}
            allSessionItems={allSessionCards}
          />
        </div>
      </div>
    );
  } else if (isSnippet(cardsLeftInSession[index])) {
    return (
      <div>
        <div className="flex flex-col gap-6" style={{ width: "100%" }}>
          <CurrentCardTitle />
          <SnippetItem
            isSubmitting={isSubmitting}
            item={cardsLeftInSession[index]}
            handleReview={handleReview}
            revlogs={currentCardReviewLogList}
            cardsLeftInSession={cardsLeftInSession}
            allSessionItems={allSessionCards}
          />
        </div>
      </div>
    );
  }
};
