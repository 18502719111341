import { Grade, Rating } from "ts-fsrs";

export type FormatOption = "CARD" | "JSON" | "CSV" | "MD";
export type DisplayPreference = { label: FormatOption }[];

export const displayFormaOptions: DisplayPreference = [
  { label: "CARD" },
  { label: "JSON" },
  { label: "CSV" },
];
export const DEFAULT_DISPLAY = displayFormaOptions[0].label;

export const ratingButtonOptionList = [
  {
    label: "Again",
    rating: Rating.Again as Grade,
  },
  {
    label: "Hard",
    rating: Rating.Hard as Grade,
  },
  {
    label: "Good",
    rating: Rating.Good as Grade,
  },
  {
    label: "Easy",
    rating: Rating.Easy as Grade,
  },
];
