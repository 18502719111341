// import { SvgLogo } from "@/assets/SvgLogo";

// import { useDarkMode } from "../../../hooks/useDarkMode";

type LogoProps = {
  navigate?: (path: string) => void;
};
export const Logo: React.FC<LogoProps> = ({ navigate }) => {
  // const [isDarkMode] = useDarkMode();
  return (
    <div
      style={{ cursor: "pointer" }}
      className="flex align-middle items-center gap-1"
      onClick={
        navigate
          ? () => {
              navigate("/");
            }
          : undefined
      }
    >
      <span className="text-3xl">🪢</span>
      {/* <SvgLogo color={isDarkMode === false ? "black" : "white"} /> */}
      <strong>sarasa</strong>
      {/* <i>beta</i> */}
    </div>
  );
};
